import styles from "./Warehouse.module.css"

const PopUpIndSuppliers = ({ param, indPopUpControls, indSuppliers, indNav, changeIndNav, allInventory, allSuppliers, changeStatus, changingStatus, searchControl }) => {
	return (
		<div className={styles.indsite_container}>
			<div className={styles.indsite_popup}>

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>

					<h1 className={styles.medium_font}>Supplier details</h1>
					<p className={styles.smaller_font}>Please find the specific supplier details for this supplier here.</p>

					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndNav('Details')}><p>Details</p></button>
							<button className={`${styles.sitenav_box} ${indNav == 'Inventory' && styles.sitenav_active}`} onClick={() => changeIndNav('Inventory')}><p>Inventory</p></button>
						</div>
					</div>

					{indNav == "Details" &&
						<div className={`${styles.sites_tablecontainer} ${changingStatus && styles.hidden_container}`}>
							<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Supplier code</label>
									<p>{indSuppliers.supplier_code}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Supplier name</label>
									<p>{indSuppliers.supplier_name}</p>
								</div>

								{/* FIND BETTER SOLUTION WITHOUT INLINE */}
								<div className={styles.popup_formbox} style={{position: 'relative'}}>
									<div style={{position: 'absolute'}}>
										<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
											<label className={styles.smaller_font}>Status</label>
							    			<button onClick={() => changeStatus('toggle')} className={`${styles.site_status} ${(indSuppliers.active === true) && styles.status_active} ${indSuppliers.active !== true && styles.status_inactive}`}>
							    				<p className={styles.smaller_font}>{indSuppliers.active ? "Active" : "Inactive"}</p>
							    			</button>
						    			</div>
					    			</div>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Service</label>
									<p>{indSuppliers.service}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Account reference</label>
									<p>{indSuppliers.account_reference || '-'}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Ordering email</label>
									{indSuppliers.ordering_email
										? <a className={`${styles.clickable_nav} ${styles.break_word}`} href={`mailto:${indSuppliers.ordering_email}`}><p>{indSuppliers.ordering_email}</p></a>
										: '-'
									}
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>{indSuppliers.contact[0].role} Contact</label>

									{indSuppliers.contact[0].name &&
										<div className={styles.site_contact_flex}>
											<i className={`fa-solid fa-user ${styles.contact_icon}`}></i>
											<p>{indSuppliers.contact[0].name}</p>
										</div>
									}
									{indSuppliers.contact[0].email &&
										<div className={styles.site_contact_flex}>
											<i className={`fa-solid fa-envelope ${styles.contact_icon}`}></i>
											<a className={`${styles.clickable_nav} ${styles.break_word}`} href={`mailto:${indSuppliers.contact[0].email}`}><p>{indSuppliers.contact[0].email}</p></a>
										</div>
									}
									{indSuppliers.contact[0].phone &&
										<div className={styles.site_contact_flex}>
											<i className={`fa-solid fa-phone ${styles.contact_icon}`}></i>
											<a className={`${styles.clickable_nav}`} href={`tel:${indSuppliers.contact[0].phone}`}><p>{indSuppliers.contact[0].phone}</p></a>
										</div>
									}
								</div>

								{indSuppliers.contact.length === 2 && (Object.values(indSuppliers.contact[1]).filter(contact => contact && contact !== '' && !(contact.toString().length === 24 && !isNaN(parseInt(contact, 16)) && /^[0-9a-fA-F]{24}$/.test(contact)))).length > 0 &&
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>{indSuppliers.contact[1].role} Contact</label>

										{indSuppliers.contact[1].name &&
											<div className={styles.site_contact_flex}>
												<i className={`fa-solid fa-user ${styles.contact_icon}`}></i>
												<p>{indSuppliers.contact[1].name}</p>
											</div>
										}
										{indSuppliers.contact[1].email &&
											<div className={styles.site_contact_flex}>
												<i className={`fa-solid fa-envelope ${styles.contact_icon}`}></i>
												<a className={`${styles.clickable_nav} ${styles.break_word}`} href={`mailto:${indSuppliers.contact[1].email}`}><p>{indSuppliers.contact[1].email}</p></a>
											</div>
										}
										{indSuppliers.contact[1].phone &&
											<div className={styles.site_contact_flex}>
												<i className={`fa-solid fa-phone ${styles.contact_icon}`}></i>
												<a className={`${styles.clickable_nav}`} href={`tel:${indSuppliers.contact[1].phone}`}><p>{indSuppliers.contact[1].phone}</p></a>
											</div>
										}
									</div>
								}


							</div>
						</div>
					}

	    			{changingStatus &&
	    				<div className={styles.statusOptions}>
	    					<button className={`${styles.closePopup} ${styles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

							<h1 className={styles.medium_font}>Change status</h1>
							<p className={`${styles.smaller_font}`}>Use this module to update the status for {indSuppliers.supplier_code}</p>

							<div className={styles.statusOuter}>
								<div className={styles.statusInner}>
					    			{!indSuppliers.active &&
						    			<button onClick={() => changeStatus('set', 'Suppliers', indSuppliers, true)} className={`${styles.site_status} ${styles.status_active}`}>
						    				<p className={styles.smaller_font}>Active</p>
						    			</button>
						    		}

						    		{indSuppliers.active &&
						    			<button onClick={() => changeStatus('set', 'Suppliers', indSuppliers, false)} className={`${styles.site_status} ${styles.status_inactive}`}>
						    				<p className={styles.smaller_font}>Inactive</p>
						    			</button>
						    		}
					    		</div>
				    		</div>
	    				</div>
	    			}

					{indNav == "Inventory" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th>Item code</th>
								      	<th>Item name</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{allInventory.filter(AI => AI.supplier == indSuppliers._id)
								    		?
								    		<>
								    			{allInventory.filter(AI => AI.supplier == indSuppliers._id).length > 0
								    				? 
								    				<>
									    				{allInventory.filter(AI => AI.supplier == indSuppliers._id).map((AIF) => {
									    					return (
														    	<tr className={styles.sitesind_tr} onClick={() => indPopUpControls('navigate', AIF)}>
														    		<td>{AIF.item_code}</td>
														    		<td>{AIF.item_name}</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This supplier does not have any items yet!</h1>
								    			}
								    		</>
								    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This supplier does not have any items yet!</h1>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default PopUpIndSuppliers;