import styles from "./Dashboard.module.css"

const PopUpEmailChange = ({ param, validationError, popUpControls, newAccountEmail, indPopUpControls }) => {
	return (
		<div className={`${styles.popup_container}`}>

			<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Change email</h1>
			<p className={styles.smaller_font}>Please enter the new email for this account.</p>

			<div className={`${styles.notification} ${styles.warningMessage}`}>
				<p className={styles.smaller_font}>A new email will be sent to the email you enter, prompting them to complete the verification process for their account.</p>
			</div>

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Email</label>
					<input type="text" value={newAccountEmail} onChange={(input) => indPopUpControls('handle_email_change', input)}/>
				</div>
			</div>

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => indPopUpControls('handle_email_submit')}>Submit</button>
	       		</div>
       		</div>


		</div>
	)
}

export default PopUpEmailChange;