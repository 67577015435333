import styles from "./Dashboard.module.css"


const PopUpFlagged = ({ param, searchControl, menuData, flagNav, handleFlagged, flaggedOrders, flaggedPreferences, sortFlaggedOrders, handleShowPrefItems, accountPrefSelected, formatDate, getTotalQuantity, showPrefItems, indPopUpControls, weekActive, boxActive, inventoryData }) => {
	return (
		<div className={`${styles.flag_container_height}`}>
			<button className={styles.closePopup} onClick={() => handleFlagged('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Flagged details</h1>
			<p className={styles.smaller_font}>These orders/preferences include items in them that are no longer part of the seasonal menu.</p>

			<div className={styles.indsite_nav}>
				<div className={styles.sitenav}>
					<button className={`${styles.sitenav_box} ${flagNav === 'Orders' && styles.sitenav_active}`} onClick={() => handleFlagged('nav', 'Orders')}><p>Orders</p></button>
					<button className={`${styles.sitenav_box} ${flagNav === 'Preferences' && styles.sitenav_active}`} onClick={() => handleFlagged('nav', 'Preferences')}><p>Preferences</p></button>
				</div>
			</div>

			{flagNav === 'Orders' &&
				<div className={styles.custom_pref_layout_flag}>
					<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
						<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input
								    type="text"
								    className={styles.smaller_font}
								    placeholder="Search for any of the column headings..."
								    onKeyUp={(event) => searchControl(event, "button_table")}
								    disabled={flaggedOrders.length < 1}
								/>
							</div>
						</div>
					</div>

					<div className={`${styles.sites_tablecontainer_orders} ${styles.custom_height}`}>
						<div className={styles.table_container}>
							<div className={`${styles.sites_table} ${styles.sites_table_orders}`} id="button_table">
								{(flaggedOrders && flaggedOrders.length > 0)
								?
									<>
										{sortFlaggedOrders(flaggedOrders).map((SO) => {
											return (
												<button className={`${styles.fleet_box_order} ${styles.ifo_height}`} onClick={() => handleShowPrefItems('open', SO)}>
													<div className={`${styles.inner_fleet_order} ${styles.ifo_space} ${(accountPrefSelected && SO._id.toString() === accountPrefSelected._id.toString()) && styles.fleet_box_activeorder}`}>
														<p className={styles.fleet_order_font}>{SO.site_name}</p>
														<div>
															<p className={styles.smaller_font}> {SO.order_type} {SO.hasOwnProperty('box_type') && " - " + SO.box_type}</p>
															<p className={styles.smaller_font}>{formatDate(SO.delivery_date, true)}</p>
															<p className={styles.smaller_font}>{getTotalQuantity(SO)} items</p>
														</div>
													</div>
												</button>
											)
										})}	
									</>
								:
									<h1 className={`${styles.medium_font} ${styles.create_first2}`}>There are no orders to be viewed.</h1>
								}
							</div>
						</div>
					</div>

					{showPrefItems &&
						<div className={`${
				            accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0 ?
				                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_combined : styles.indorder_seasonal_hig) :
				                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_hig : styles.indorder_seasonal_hig)
				        }`}>

				        	<div className={styles.box_layout_full}>
								{(accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0) &&
									<div className={`${styles.box_layout} ${styles.box_max_layout}`}>
					    				{accountPrefSelected.items.map((item, index) => {
					    					return (
								    			<button onClick={() => indPopUpControls('handleBox', index)} className={`${styles.site_status} ${boxActive === index ? styles.box_active : styles.status_active}`}>
								    				<p className={styles.smaller_font}>Box {index+1}</p>
								    			</button>
									    	)
									    })}
									</div>
								}

								<div className={styles.view_order}>
					    			<button onClick={() => indPopUpControls('flagNav', accountPrefSelected)} className={`${styles.site_status}`}>
					    				<p className={styles.smaller_font}>Open Order</p>
					    			</button>
								</div>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th>Item name</th>
								      	<th>Quantity</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
						    			<>
							    			{accountPrefSelected.items && accountPrefSelected.items.length > 0
							    				? 
							    				<>
								    				{accountPrefSelected.items[boxActive].map((II) => {
								    					let itemFHT = inventoryData.find((invItem) => invItem._id === II.item)

								    					return (
													    	<tr className={`${styles.sitesind_tr} ${((itemFHT.subcategory !== "Extra Items") && !(menuData[0].items.map(mitem => mitem.product.toString())).includes(itemFHT._id.toString()) && itemFHT._id.toString() !== menuData[0].seasonal_item.toString()) && styles.red_flagged_item}`}>
													    		<td>{itemFHT.item_name}</td>
													    		<td>{II.quantity} items</td>
													    	</tr>
												    	)
												    })}

											    	<tr className={styles.sitesind_tr}>
											    		<td>Total Quantity</td>
											    		<td>{accountPrefSelected.items[boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
											    	</tr>
											    </>
							    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
							    			}
						    			</>
								    </tbody>
								</table>
							</div>
						</div>
					}
				</div>
			}

			{flagNav === 'Preferences' &&
				<div className={styles.custom_pref_layout_flag}>
					<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
						<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input
								    type="text"
								    className={styles.smaller_font}
								    placeholder="Search for any of the column headings..."
								    onKeyUp={(event) => searchControl(event, "button_table")}
								    disabled={flaggedPreferences.length < 1}
								/>
							</div>
						</div>
					</div>

					<div className={`${styles.sites_tablecontainer_orders} ${styles.custom_height}`}>
						<div className={styles.table_container}>
							<div className={`${styles.sites_table} ${styles.sites_table_orders}`} id="button_table">
								{(flaggedPreferences && flaggedPreferences.length > 0)
								?
									<>
										{sortFlaggedOrders(flaggedPreferences).map((SP) => {
											return (
												<button className={`${styles.fleet_box_order} ${styles.ifo_height}`} onClick={() => handleShowPrefItems('open', SP)}>
													<div className={`${styles.inner_fleet_order} ${styles.ifo_space} ${(accountPrefSelected && SP._id.toString() === accountPrefSelected._id.toString()) && styles.fleet_box_activeorder}`}>
														<p className={styles.fleet_order_font}>{SP.site_name}  |  {SP.delivery_day}</p>
														<div>
															<p className={styles.smaller_font}> {SP.preference_type} {SP.hasOwnProperty('box_type') && " - " + SP.box_type}</p>
															<p className={styles.smaller_font}>{getTotalQuantity(SP)} items</p>
														</div>
													</div>
												</button>
											)
										})}	
									</>
								:
									<h1 className={`${styles.medium_font} ${styles.create_first2}`}>There are no preferences to be viewed.</h1>
								}
							</div>
						</div>
					</div>

					{showPrefItems &&
						<div className={`${
				            accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0 ?
				                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_combined : styles.indorder_seasonal_hig) :
				                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_hig : styles.indorder_seasonal_hig)
				        }`}>

				        	<div className={styles.box_layout_full}>
								{(accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0) &&
									<div className={`${styles.box_layout} ${styles.box_max_layout}`}>
					    				{accountPrefSelected.items.map((item, index) => {
					    					return (
								    			<button onClick={() => indPopUpControls('handleBox', index)} className={`${styles.site_status} ${boxActive === index ? styles.box_active : styles.status_active}`}>
								    				<p className={styles.smaller_font}>Box {index+1}</p>
								    			</button>
									    	)
									    })}
									</div>
								}

								<div className={styles.view_order}>
					    			<button onClick={() => indPopUpControls('flagNav', accountPrefSelected)} className={`${styles.site_status}`}>
					    				<p className={styles.smaller_font}>Open Preference</p>
					    			</button>
								</div>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th>Item name</th>
								      	<th>Quantity</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
						    			<>
							    			{accountPrefSelected.items && accountPrefSelected.items.length > 0
							    				? 
							    				<>
								    				{accountPrefSelected.items[boxActive].map((II) => {
								    					let itemFHT = inventoryData.find((invItem) => invItem._id === II.item)

								    					return (
													    	<tr className={`${styles.sitesind_tr} ${((itemFHT.subcategory !== "Extra Items") && !(menuData[0].items.map(mitem => mitem.product.toString())).includes(itemFHT._id.toString()) && itemFHT._id.toString() !== menuData[0].seasonal_item.toString()) && styles.red_flagged_item}`}>
													    		<td>{itemFHT.item_name}</td>
													    		<td>{II.quantity} items</td>
													    	</tr>
												    	)
												    })}

											    	<tr className={styles.sitesind_tr}>
											    		<td>Total Quantity</td>
											    		<td>{accountPrefSelected.items[boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
											    	</tr>
											    </>
							    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
							    			}
						    			</>
								    </tbody>
								</table>
							</div>
						</div>
					}
				</div>
			}
		</div>
	)
}

export default PopUpFlagged;