import styles from "../Dashboard/Dashboard.module.css"

const PopUpIndInteraction = ({ handleRowClick, indInteraction, formatDate, allAccounts, allSites, handleRestore }) => {
	return (
		<div className={`${styles.indsite_container} ${styles.push_to_top}`}>
			<div className={`${styles.indsite_popup}`}>

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => handleRowClick('Interactions', null)}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => handleRestore('Interactions', indInteraction)}><i className="fa-solid fa-arrow-rotate-left"></i></button>

					<h1 className={styles.medium_font}>Interaction details</h1>
					<p className={styles.smaller_font}>Please find the specific details for your interaction here.</p>

					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${styles.sitenav_active}`}><p>Details</p></button>
						</div>
					</div>


					<div className={styles.sites_tablecontainer}>
						<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Account</label>
								<p>{allAccounts.filter(AS => AS._id == indInteraction.account)[0].company}</p>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Site</label>
								<p>{allSites.filter(AS => AS._id == indInteraction.site)[0].site_name}</p>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Interaction contact</label>
								<div className={styles.site_contact_flex}>
									<i className="fa-solid fa-user"></i>
									<p>{(indInteraction.contact.first_name && indInteraction.contact.last_name && indInteraction.contact.first_name != '' && indInteraction.contact.last_name != '') ? `${indInteraction.contact.first_name} ${indInteraction.contact.last_name}` : '-'}</p>
								</div>
								<div className={styles.site_contact_flex}>
									<i className="fa-solid fa-envelope"></i>
									{(indInteraction.contact.email && indInteraction.contact.email != '')
										? <a className={styles.clickable_nav} href={`mailto:${indInteraction.contact.email}`}><p>{indInteraction.contact.email}</p></a>
										: '-'
									}
								</div>
								<div className={styles.site_contact_flex}>
									<i className="fa-solid fa-phone"></i>
									{(indInteraction.contact.phone && indInteraction.contact.phone != '')
										? <a className={styles.clickable_nav} href={`tel:${indInteraction.contact.phone}`}><p>{indInteraction.contact.phone}</p></a>
										: '-'
									}
								</div>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Comment</label>
								<p>{indInteraction.comment}</p>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Date created</label>
								<p>{formatDate(indInteraction.date_created)}</p>
							</div>

							{indInteraction.follow_up_date &&
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Follow up date</label>
									<p>{formatDate(indInteraction.follow_up_date)}</p>
								</div>
							}

							{(indInteraction.tags && indInteraction.tags.length > 0)
								?
									<>
										<div className={styles.popup_formbox} style={{position: 'relative'}}>
											<div style={{position: 'absolute'}}>
												<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
													<label className={styles.smaller_font}>Tags</label>

													<div className={styles.tag_flex}>
														{indInteraction.tags.map((IT) => {
															return (
												    			<div className={`${styles.site_status} ${styles.status_active}`}>
												    				<p className={styles.smaller_font}>{IT}</p>
												    			</div>
															)
														})}
													</div>
								    			</div>
							    			</div>
										</div>

									</>
								:
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Tags</label>
										<p>-</p>
									</div>
							}

						</div>
					</div>

				</div>
			</div>
		</div>
	)
}

export default PopUpIndInteraction;