import styles from "./Invoices.module.css"

const PopUpIndInvoice = ({ param, indPopUpControls, indInvoice, indNav, changeIndNav, internalLoading, formatDate, searchControl }) => {
	return (
		<div className={styles.indsite_container}>
			<div className={styles.indsite_popup}>

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
					<button className={styles.emailPopup} onClick={() => indPopUpControls('email')}><i className="fa-solid fa-paper-plane"></i></button>
					<button className={styles.pdfPopup} onClick={() => indPopUpControls('pdf')}><i className="fa-solid fa-eye"></i></button>


					<h1 className={styles.medium_font}>Invoice details</h1>
					<p className={styles.smaller_font}>Please find the specific invoice details for this invoice here.</p>

					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndNav('Details')}><p>Details</p></button>
							<button className={`${styles.sitenav_box} ${indNav == 'Items' && styles.sitenav_active}`} onClick={() => changeIndNav('Items')}><p>Items</p></button>
						</div>
					</div>

					{internalLoading
						?
						<div className={styles.loadpage_container}>
							<div className={styles.load}>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
						:
						<>
							{indNav == "Details" &&
								<div className={styles.sites_tablecontainer}>
									<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Invoice code</label>
											<p>{indInvoice.Number}</p>
										</div>

										{/* FIND BETTER SOLUTION WITHOUT INLINE */}
										<div className={styles.popup_formbox} style={{position: 'relative'}}>
											<div style={{position: 'absolute'}}>
												<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
													<label className={styles.smaller_font}>Status</label>
									    			<div className={`${styles.site_status} ${indInvoice.Status == "Open" && styles.status_pending} ${indInvoice.Status == "Closed" && styles.status_active} ${indInvoice.Status == "Overdue" && styles.status_inactive}`}>
									    				<p className={styles.smaller_font}>{indInvoice.Status}</p>
									    			</div>
								    			</div>
							    			</div>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Billing address</label>
											<a className={styles.clickable_nav} href={`http://maps.google.com/?q=${indInvoice.ShipToAddress}`} target="_blank"><p>{indInvoice.ShipToAddress}</p></a>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Company</label>
											<p>{indInvoice.Company}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Date issued</label>
											<p>{formatDate(indInvoice.Date)}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Date due</label>
											<p>{formatDate(indInvoice.Terms.DueDate)}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Total amount</label>
											<p>${indInvoice.TotalAmount}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Total tax</label>
											<p>${indInvoice.TotalTax || '0'}</p>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Comment</label>
											<p>{indInvoice.Comment || '-'}</p>
										</div>
									</div>
								</div>
							}

							{indNav == "Items" &&
								<div className={styles.sites_tablecontainer}>
									<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
										<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
											<div className={styles.sites_search}>
												<i className="fa-solid fa-magnifying-glass"></i>
												<input
												    type="text"
												    className={styles.smaller_font}
												    placeholder="Search for any of the column headings..."
												    onKeyUp={(event) => searchControl(event, "ind_table")}
												/>
											</div>
										</div>
									</div>

									<div className={styles.table_container}>
										<table className={styles.sites_table} id="ind_table">
										    <thead className={styles.sitesind_thead}>
										      <tr className={styles.table_rowth}>
										      	<th colspan="2">Item</th>
										      	<th>Qty</th>
										      	<th colspan="1">Amount</th>
										      </tr>
										    </thead>
										    <tbody className={`${styles.indtbody} ${styles.custom_tbody}`}>
										    	{indInvoice.Lines
										    		?
										    		<>
										    			{indInvoice.Lines.length > 0
										    				? 
										    				<>
											    				{indInvoice.Lines.map((IL) => {
											    					return (
																    	<tr className={styles.sitesind_tr}>
																    		<td colspan="2">{IL.Item.Name}</td>
																    		<td>{IL.ShipQuantity}</td>
																    		<td colspan="1">${IL.Total}</td>	
																    	</tr>
															    	)
															    })}
														    </>
										    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This invoice does not have any items!</h1>
										    			}
										    		</>
										    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This invoice does not have any items!</h1>
										    	}
										    </tbody>

											<div className={styles.total_container}>
												<div className={styles.theading_container}>
													<p>Subtotal:</p>
													<p>Tax:</p>
													<p>Total:</p>
												</div>
												<div className={styles.tvalues_container}>
													<p>${indInvoice.Subtotal}</p>
													<p>${indInvoice.TotalTax}</p>
													<p>${indInvoice.TotalAmount}</p>
												</div>
											</div>
										</table>
									</div>
								</div>
							}
						</>
					}
				</div>
			</div>
		</div>
	)
}

export default PopUpIndInvoice;