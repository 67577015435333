import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import cstyles from "./ComingSoonstyles.module.css"

import comingsoon from '../../images/comingsoon.jpg'


const ComingSoon = () => {
	return (
			<div className={cstyles.main_container}>
				<h1 className={cstyles.cs_title}>Coming Soon!</h1>
				<p className={cstyles.cs_subtitle}>This page is currently under development and will be available shortly.</p>
				<img src={comingsoon}></img>
			</div>
	)
};

export default ComingSoon;
