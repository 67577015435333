import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from "./Warehouse.module.css"

import ReactAutocomplete from "react-google-autocomplete";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const PopUpMenu = ({ param, popUpPage, popUpControls, validationError, formatOptions, allInventory, handleMenuItem, menuItemArr, handleMenuArr, onDragEndItems, handleSeasonalItem, menuSeasonalItem, seasonalOptions }) => {
	return (
		<div className={`${styles.fixed_container_height}`}>
			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Menu details</h1>
					<p className={styles.smaller_font}>Please enter the specific menu details to create.</p>

					<div className={`${styles.draggableContainerBulkUpdate}`}>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Seasonal item</label>
							<Select
								closeMenuOnSelect={true}
								options={formatOptions(seasonalOptions)}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb'
									}),
								}}
								onChange={(selected) => handleSeasonalItem(selected.value)}
								value={{label: menuSeasonalItem, value: menuSeasonalItem}}
							/>
						</div>


						<DragDropContext onDragEnd={onDragEndItems}>
							<Droppable droppableId="menuItems">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{menuItemArr.map((MI, index) => (
											<Draggable key={MI.id} draggableId={MI.id.toString()} index={MI.id} id={MI.id}>
												{(provided) => (
													<div ref={provided.innerRef} {...provided.draggableProps} className={`${styles.popup_form_custom}`}>
														<div className={styles.popup_formbox}>
															<label className={styles.smaller_font}>Product</label>
															<Select
																closeMenuOnSelect={true}
																options={formatOptions(seasonalOptions)}
																styles={{
																	control: (baseStyles, state) => ({
																		...baseStyles,
																		boxShadow: 'none !important',
																	   '&:hover': {
																	       borderColor: '#fce7eb !important'
																	    },
																		borderColor: state.isFocused ? '#fce7eb' : '#fce7eb'
																	}),
																}}
																onChange={(selected) => handleMenuArr("product", selected.value, MI.id)}
																value={{label: MI.product, value: MI.product}}
															/>
														</div>

														<div className={styles.popup_formbox}>
															<label className={styles.smaller_font}>Percentage</label>
															<input type="text" placeholder="%" value={MI.percentage} onChange={(selected) => handleMenuArr("percentage", selected.target.value, MI.id)}/>
														</div>

														<div className={styles.popup_formbox}>
															<label className={styles.smaller_font}>Rounds</label>
															<Select
																closeMenuOnSelect={true}
																options={[{label: "Up", value: "Up"}, {label: "Down", value: "Down"}]}
																styles={{
																	control: (baseStyles, state) => ({
																		...baseStyles,
																		boxShadow: 'none !important',
																	   '&:hover': {
																	       borderColor: '#fce7eb !important'
																	    },
																		borderColor: state.isFocused ? '#fce7eb' : '#fce7eb'
																	}),
																}}
																onChange={(selected) => handleMenuArr("round", selected.value, MI.id)}
																value={{label: MI.round, value: MI.round}}
															/>
														</div>

														<div className={styles.popup_formbox}>
															<label className={styles.smaller_font}>Tools</label>
															<div className={styles.form_controls}>
																<i className={`fa-solid fa-up-down ${styles.medium_font} ${styles.dragBtn}`} {...provided.dragHandleProps}></i>
																<button onClick={() => handleMenuItem('delete', MI.id)}><i className={`fa-solid fa-trash-can ${styles.medium_font} ${styles.ml_7}`}></i></button>
															</div>
														</div>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>

						<div className={styles.menuControls}>
							<button type="button" className={styles.addBtn} onClick={() => handleMenuItem('add')}>
								<i className="fa-solid fa-plus"></i>
							</button>
						</div>

					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

					<div className={`${styles.draggableContainer}`}>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Seasonal item</label>
							<input type="text" value={menuSeasonalItem} disabled={true}/>
						</div>

						<div className={styles.formbox_grid}>
							{menuItemArr.map((MI, index) => (
								<div className={styles.popup_form_custom_confirm}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Product</label>
										<input type="text" value={MI.product} disabled={true}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Percentage</label>
										<input type="text" value={MI.percentage} disabled={true}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Rounds</label>
										<input type="text" value={MI.round} disabled={true}/>
									</div>
								</div>
							))}
						</div>
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError} ${styles.customValidation}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={`${styles.popUpControls} ${styles.controls_bottom}`}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 2 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 2 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpMenu;