import { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import NavigationBar from '../Utils/NavigationBar'
import lstyles from "./Loading.module.css"


const Loading = ({ current }) => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};
	
	const param = useParams();
	const [collapseDeg, setCollapseDeg] = useState(0)
	const [collapseFilters, setCollapseFilters] = useState(true)

	return (
		<>
			<div className={collapseFilters ? lstyles.main_container : lstyles.main_container_full}>
				<NavigationBar 
					param={param}
					active={current}
					handleLogout={handleLogout}
					collapseDeg={collapseDeg}
					setCollapseDeg={setCollapseDeg}
					collapseFilters={collapseFilters}
					setCollapseFilters={setCollapseFilters}
				/>
				<div className={lstyles.loadpage_container}>
					<div className={lstyles.load}>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
		</>
	);
};

export default Loading;