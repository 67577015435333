import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import styles from "./Dashboard.module.css"
import ResizableDiv from '../Utils/ResizableDiv';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpIndSite = ({ param, indPopUpControls, allSites, indSite, changeIndSiteNav, indSiteNav, formatDate, allInteractions, allAccounts, allLogs, changeStatus, changingStatus, searchControl, allPreferences, allOrders, inventoryData, handleAllergies, handleCustomPricing, allTiers, handleShowPrefItems, showPrefItems, accountPrefSelected, weekActive, boxActive, sortSIorders, getTotalQuantity, sortSIPrefs, inactiveDatePopup, inactiveDate }) => {
	const findSiteFT = allTiers.filter(tier => (tier.type === "Fruit" && tier.location === "Site") && tier.siteLink.toString() === indSite._id.toString())
	
	return (
		<div className={styles.indsite_container}>
			<ResizableDiv className="indsite_popup" startingWidth="50">
			{/* <div className={`${styles.indsite_popup} ${styles.more_width}`}> */}

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
					<button className={styles.nextPopup} onClick={() => indPopUpControls('clone_site')}><i className="fa-solid fa-clone"></i></button>
					<button className={styles.otherPopup} onClick={() => indPopUpControls('delete', indSite)} disabled={indSite.orders.length > 0 || indSite.preferences.length > 0}><i className="fa-solid fa-trash-can"></i></button>

					<h1 className={styles.medium_font}>Site {indSiteNav.toLowerCase()}</h1>
					<p className={styles.smaller_font}>Please find the specific {indSiteNav.toLowerCase()} for your site here.</p>


					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indSiteNav === 'Details' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Details')}><p>Details</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav === 'Orders' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Orders')}><p>Orders</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav === 'Preferences' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Preferences')}><p>Preferences</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav === 'Logs' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Logs')}><p>Logs</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav === 'Interactions' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Interactions')}><p>Interactions</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav === 'Allergies' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Allergies')}><p>Allergies</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav === 'Pricing' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Pricing')}><p>Pricing</p></button>
						</div>
					</div>

					{indSiteNav === "Details" &&
						<div className={`${styles.sites_tablecontainer} ${changingStatus && styles.hidden_container}`}>
							<div className={`${styles.indinfo_grid_multi} ${styles.indsiteinfo_gap}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Site code</label>
									<p>{indSite.site_code}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Site name</label>
									<p>{indSite.site_name}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Company</label>
									<a onClick={() => indPopUpControls('navigate', indSite)} className={styles.clickable_nav}><p>{indSite.company}</p></a>
								</div>

								{/* FIND BETTER SOLUTION WITHOUT INLINE */}
								<div className={styles.popup_formbox} style={{position: 'relative'}}>
									<div style={{position: 'absolute'}}>
										<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px', gridTemplateColumns: 'max-content'}}>
											<label className={styles.smaller_font}>Status</label>
							    			<button onClick={() => changeStatus('toggle')} className={`${styles.site_status} ${indSite.status == "Pending" && styles.status_pending} ${(indSite.status == "Active" || indSite.status == "Completed") && styles.status_active} ${indSite.status == "Inactive" && styles.status_inactive}`}>
							    				<p className={styles.smaller_font}>{indSite.status}</p>
							    			</button>
						    			</div>

						    			{indSite.hasOwnProperty('inactive_commencement') &&
						    				<p className={`${styles.smaller_font}`}>Becoming inactive: {formatDate(indSite.inactive_commencement)}</p>
						    			}
					    			</div>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Has pass?</label>
									<p>{indSite.site_pass || '-'}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Pass number</label>
									<p>{indSite.pass_number || '-'}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Supplier platform?</label>
									<p>{indSite.supplier_platform ? indSite.supplier_platform : 'No'}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Crate?</label>
									<p>{indSite.crate ? indSite.crate : 'No'}</p>
								</div>


								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Department</label>
									<p>{(indSite.site_location.department && indSite.site_location.department !== '') ? indSite.site_location.department : '-'}</p>
								</div>


								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Address</label>
									<a className={styles.clickable_nav} href={`http://maps.google.com/?q=${indSite.site_location.street}, ${indSite.site_location.city} ${indSite.site_location.state} ${indSite.site_location.postcode}, ${indSite.site_location.country}`} target="_blank"><p>{(indSite.site_location.hasOwnProperty('level') && indSite.site_location.level) && `${/\d/.test(indSite.site_location.level) ? "Level " : ""}${indSite.site_location.level}, `}{indSite.site_location.street}, {indSite.site_location.city} {indSite.site_location.state} {indSite.site_location.postcode}, {indSite.site_location.country}</p></a>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Site contact</label>
									<div className={styles.site_contact_flex}>
										<i className="fa-solid fa-user"></i>
										<p>{(indSite.site_contact.first_name && indSite.site_contact.last_name && indSite.site_contact.first_name !== '' && indSite.site_contact.last_name !== '') ? `${indSite.site_contact.first_name} ${indSite.site_contact.last_name}` : '-'}</p>
									</div>
									<div className={styles.site_contact_flex}>
										<i className="fa-solid fa-envelope"></i>
										{(indSite.site_contact.email && indSite.site_contact.email !== '')
											? <a className={styles.clickable_nav} href={`mailto:${indSite.site_contact.email}`}><p>{indSite.site_contact.email}</p></a>
											: '-'
										}
									</div>
									<div className={styles.site_contact_flex}>
										<i className="fa-solid fa-phone"></i>
										{(indSite.site_contact.phone && indSite.site_contact.phone !== '')
											? <a className={styles.clickable_nav} href={`mailto:${indSite.site_contact.phone}`}><p>{indSite.site_contact.phone}</p></a>
											: '-'
										}
									</div>
								</div>

								{indSite.secondary_site_contact && Object.keys(indSite.secondary_site_contact).length > 0 &&
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Site secondary contact</label>
									<div className={styles.site_contact_flex}>
										<i className="fa-solid fa-user"></i>
										<p>{(indSite.secondary_site_contact.first_name && indSite.secondary_site_contact.last_name && indSite.secondary_site_contact.first_name !== '' && indSite.secondary_site_contact.last_name !== '') ? `${indSite.secondary_site_contact.first_name} ${indSite.secondary_site_contact.last_name}` : '-'}</p>
									</div>
									<div className={styles.site_contact_flex}>
										<i className="fa-solid fa-envelope"></i>
										{(indSite.secondary_site_contact.email && indSite.secondary_site_contact.email !== '')
											? <a className={styles.clickable_nav} href={`mailto:${indSite.secondary_site_contact.email}`}><p>{indSite.secondary_site_contact.email}</p></a>
											: '-'
										}
									</div>
									<div className={styles.site_contact_flex}>
										<i className="fa-solid fa-phone"></i>
										{(indSite.secondary_site_contact.phone && indSite.secondary_site_contact.phone !== '')
											? <a className={styles.clickable_nav} href={`mailto:${indSite.secondary_site_contact.phone}`}><p>{indSite.secondary_site_contact.phone}</p></a>
											: '-'
										}
									</div>
								</div>
								}

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Site instructions</label>
									<p>{(indSite.site_instructions && indSite.site_instructions !== '') ? indSite.site_instructions : '-'}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Custom pricing</label>
									<p>{findSiteFT.length > 0 ? "Yes" : "No"}</p>
								</div>
							</div>
						</div>
					}

	    			{(changingStatus && !inactiveDatePopup) &&
	    				<div className={styles.statusOptions}>
	    					<button className={`${styles.closePopup} ${styles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

							<h1 className={styles.medium_font}>Change status</h1>
							<p className={`${styles.smaller_font}`}>Use this module to update the status for {indSite.site_name}</p>

							<div className={styles.statusOuter}>
								<div className={styles.statusInner}>
			    					{indSite.status !== "Pending" &&
						    			<button onClick={() => changeStatus('set', 'Site', indSite, 'Pending')} className={`${styles.site_status} ${styles.status_pending}`}>
						    				<p className={styles.smaller_font}>Pending</p>
						    			</button>
					    			}

					    			{indSite.status !== "Active" &&
						    			<button onClick={() => changeStatus('set', 'Site', indSite, 'Active')} className={`${styles.site_status} ${styles.status_active}`}>
						    				<p className={styles.smaller_font}>Active</p>
						    			</button>
						    		}

						    		{indSite.status !== "Inactive" &&
						    			<button onClick={() => changeStatus('set', 'Site', indSite, 'Inactive')} className={`${styles.site_status} ${styles.status_inactive}`}>
						    				<p className={styles.smaller_font}>Inactive</p>
						    			</button>
						    		}
					    		</div>
				    		</div>
	    				</div>
	    			}

	    			{inactiveDatePopup &&
	    				<div className={styles.statusOptionsDate}>
	    					<button className={`${styles.closePopup} ${styles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

							<h1 className={styles.medium_font}>Set Inactive</h1>
							<p className={`${styles.smaller_font}`}>Use this module to update the status for {indSite.site_name} to inactive</p>

							<div className={`${styles.popup_form}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Starting date</label>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
									    <DatePicker
									        className={styles.overridebg}
									        localeText={{ start: '', end: '' }}
									        value={inactiveDate && inactiveDate}
									        onChange={(selected) => changeStatus('set_date', selected)}
									        disablePast
									        format="DD/MM/YYYY"
									    />
									</LocalizationProvider>
								</div>
							</div>

							<div className={styles.popUpControls}>
								<div className={styles.popUpControlsContainer}>
									<button type="button" className={styles.submitBtn} onClick={() => changeStatus('set', 'Site', indSite, 'Inactive')}>Submit</button>
								</div>
							</div>

	    				</div>
	    			}

					{indSiteNav === "Orders" &&
						<div className={styles.custom_pref_layout}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "button_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => indPopUpControls('nav_indsite')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add order</p>
								</button>
							</div>

							<div className={`${styles.sites_tablecontainer_orders} ${styles.custom_height}`}>
								<div className={styles.table_container}>
									<div className={`${styles.sites_table} ${styles.sites_table_orders}`} id="button_table">
										{((allSites.filter(site => site._id.toString() === indSite._id.toString()).length > 0) && (allSites.filter(site => site._id.toString() === indSite._id.toString())[0].orders.length > 0))
										?
											<>
												{sortSIorders(allSites.filter(site => site._id.toString() === indSite._id.toString())[0].orders).map((IO) => {
													let indOrderFiltered = allOrders.filter(order => order._id.toString() === IO.order.toString())[0]

													return (
														<button className={`${styles.fleet_box_order} ${styles.ifo_height}`} onClick={() => handleShowPrefItems('open', indOrderFiltered)}>
															<div className={`${styles.inner_fleet_order} ${styles.ifo_space} ${(accountPrefSelected && indOrderFiltered._id.toString() === accountPrefSelected._id.toString()) && styles.fleet_box_activeorder}`}>
																<div className={styles.inner_order_content_top}>
																	<p className={styles.fleet_order_font}>{indOrderFiltered.order_code}</p>
 														    		<td className={`${styles.site_statustd} ${styles.fleet_td}`}>
 														    			<div className={`${styles.site_status} ${styles.marginOffset} ${indOrderFiltered.status === "Pending" && styles.status_pending} ${(indOrderFiltered.status === "Active" || indOrderFiltered.status === "Completed") && styles.status_active} ${indOrderFiltered.status === "Inactive" && styles.status_inactive}`}>
 														    				<p className={styles.smaller_font}>{indOrderFiltered.status}</p>
 														    			</div>
 														    		</td>
 														    	</div>
																<div>
																	<p className={styles.smaller_font}> {indOrderFiltered.order_type} {indOrderFiltered.hasOwnProperty('box_type') && " - " + indOrderFiltered.box_type}</p>
																	<p className={styles.smaller_font}>{formatDate(indOrderFiltered.delivery_date, true)}</p>
																	<p className={styles.smaller_font}>{getTotalQuantity(indOrderFiltered)} items</p>
																</div>
															</div>
														</button>
													)
												})}	
											</>
										:
											<h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any orders yet!</h1>
										}
									</div>
								</div>
							</div>


							{showPrefItems &&
								<div className={`${
						            accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0 ?
						                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_combined : styles.indorder_seasonal_hig) :
						                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_hig : styles.indorder_seasonal_hig)
						        }`}>

						        	<div className={`${
						        		(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) || (accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0)
						        		? styles.box_layout_flexible
						        		: styles.box_layout_full

						        	}`}>
										{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) &&
											<div className={`${styles.box_layout} ${styles.box_max_layout}`}>
												{Object.keys(accountPrefSelected).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1])).map((key, index) => (
									    			<button onClick={() => indPopUpControls('handleWeek', index)}  className={`${styles.site_status} ${weekActive === index ? styles.week_active : styles.status_week}`}>
									    				<p className={styles.smaller_font}>Fortnight {index+1}</p>
									    			</button>
									    		))}
											</div>
										}

										{(accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0) &&
											<div className={`${styles.box_layout} ${styles.box_max_layout}`}>
							    				{accountPrefSelected.items.map((item, index) => {
							    					return (
										    			<button onClick={() => indPopUpControls('handleBox', index)} className={`${styles.site_status} ${boxActive === index ? styles.box_active : styles.status_active}`}>
										    				<p className={styles.smaller_font}>Box {index+1}</p>
										    			</button>
											    	)
											    })}
											</div>
										}

										<div className={styles.view_order}>
							    			<button onClick={() => indPopUpControls('navigate', accountPrefSelected)} className={`${styles.site_status}`}>
							    				<p className={styles.smaller_font}>Open Order</p>
							    			</button>
										</div>
									</div>

									<div className={styles.table_container}>
										<table className={styles.sites_table} id="ind_table">
										    <thead className={styles.sitesind_thead}>
										      <tr className={styles.table_rowth}>
										      	<th>Item name</th>
										      	<th>Quantity</th>
										      </tr>
										    </thead>
										    <tbody className={styles.indtbody}>
										    	{(accountPrefSelected['box_type'] === "Seasonal" || accountPrefSelected['box_type'] === "Flexi")
										    	?
										    		<>
										    			{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0)
										    				?
											    				<>
													    			{accountPrefSelected[`items_${weekActive+1}`] && accountPrefSelected[`items_${weekActive+1}`].length > 0
													    				? 
													    				<>
														    				{accountPrefSelected[`items_${weekActive+1}`][boxActive].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}

																	    	<tr className={styles.sitesind_tr}>
																	    		<td>Total Quantity</td>
																	    		<td>{accountPrefSelected[`items_${weekActive+1}`][boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
																	    	</tr>
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
													    			}
												    			</>
										    				:
											    				<>
													    			{accountPrefSelected.items && accountPrefSelected.items.length > 0
													    				? 
													    				<>
														    				{accountPrefSelected.items[boxActive].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}

																	    	<tr className={styles.sitesind_tr}>
																	    		<td>Total Quantity</td>
																	    		<td>{accountPrefSelected.items[boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
																	    	</tr>
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
													    			}
												    			</>
										    			}
										    		</>
										    	:
										    		<>
										    			{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0)
										    				?
										    					<>
													    			{accountPrefSelected[`items_${weekActive+1}`] && accountPrefSelected[`items_${weekActive+1}`].length > 0
													    				? 
													    				<>
														    				{accountPrefSelected[`items_${weekActive+1}`].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
													    			}
												    			</>
										    				:
										    					<>
													    			{accountPrefSelected.items && accountPrefSelected.items.length > 0
													    				? 
													    				<>
														    				{accountPrefSelected.items.map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
													    			}
												    			</>
										    			}
										    		</>
										    	}
										    </tbody>
										</table>
									</div>
								</div>
							}

						</div>
					}



					{indSiteNav === "Preferences" &&
						<div className={styles.custom_pref_layout}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "button_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => indPopUpControls('nav_indsite')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add preference</p>
								</button>
							</div>

							<div className={`${styles.sites_tablecontainer_orders} ${styles.custom_height}`}>
								<div className={styles.table_container}>
									<div className={`${styles.sites_table} ${styles.sites_table_orders}`} id="button_table">
										{((allSites.filter(site => site._id.toString() === indSite._id.toString()).length > 0) && (allSites.filter(site => site._id.toString() === indSite._id.toString())[0].preferences.length > 0))
										?
											<>
												{sortSIPrefs(allSites.filter(site => site._id.toString() === indSite._id.toString())[0].preferences).map((IPs) => {
													let indPrefFiltered = allPreferences.filter(pref => pref._id.toString() === IPs.preference.toString())[0]

													return (
														<button className={`${styles.fleet_box_order} ${styles.ifo_height}`} onClick={() => handleShowPrefItems('open', indPrefFiltered)}>
															<div className={`${styles.inner_fleet_order} ${styles.ifo_space} ${(accountPrefSelected && indPrefFiltered._id.toString() === accountPrefSelected._id.toString()) && styles.fleet_box_activeorder}`}>
																<div className={styles.inner_order_content_top}>
																	<p className={styles.fleet_order_font}>{indPrefFiltered.preference_code}</p>
 														    		<td className={`${styles.site_statustd} ${styles.fleet_td}`}>
 														    			<div className={`${styles.site_status} ${styles.marginOffset} ${indPrefFiltered.status === "Pending" && styles.status_pending} ${(indPrefFiltered.status === "Active" || indPrefFiltered.status === "Completed") && styles.status_active} ${indPrefFiltered.status === "Inactive" && styles.status_inactive}`}>
 														    				<p className={styles.smaller_font}>{indPrefFiltered.status}</p>
 														    			</div>
 														    		</td>
 														    	</div>
																<div>
																	<p className={styles.smaller_font}> {indPrefFiltered.preference_type} {indPrefFiltered.hasOwnProperty('box_type') && " - " + indPrefFiltered.box_type}</p>
																	<p className={styles.smaller_font}>{indPrefFiltered.delivery_day}</p>
																	<p className={styles.smaller_font}>{getTotalQuantity(indPrefFiltered)} items</p>
																</div>
															</div>
														</button>
													)
												})}	
											</>
										:
											<h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any preferences yet!</h1>
										}
									</div>
								</div>
							</div>


							{showPrefItems &&
								<div className={`${
						            accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0 ?
						                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_combined : styles.indorder_seasonal_hig) :
						                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_hig : styles.indorder_seasonal_hig)
						        }`}>

						        	<div className={`${
								        (accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) && (accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0)
						        		? styles.box_layout_flexible_full
						        		: (accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) || (accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0)
						        		? styles.box_layout_flexible
						        		: styles.box_layout_full

						        	}`}>
										{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) &&
											<div className={`${styles.box_layout} ${styles.box_max_layout}`}>
												{Object.keys(accountPrefSelected).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1])).map((key, index) => (
									    			<button onClick={() => indPopUpControls('handleWeek', index)}  className={`${styles.site_status} ${weekActive === index ? styles.week_active : styles.status_week}`}>
									    				<p className={styles.smaller_font}>Fortnight {index+1}</p>
									    			</button>
									    		))}
											</div>
										}

										{(accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0) &&
											<div className={`${styles.box_layout} ${styles.box_max_layout}`}>
							    				{accountPrefSelected.items.map((item, index) => {
							    					return (
										    			<button onClick={() => indPopUpControls('handleBox', index)} className={`${styles.site_status} ${boxActive === index ? styles.box_active : styles.status_active}`}>
										    				<p className={styles.smaller_font}>Box {index+1}</p>
										    			</button>
											    	)
											    })}
											</div>
										}

										<div className={styles.view_order}>
							    			<button onClick={() => indPopUpControls('navigate', accountPrefSelected)} className={`${styles.site_status}`}>
							    				<p className={styles.smaller_font}>Open Preference</p>
							    			</button>
										</div>
									</div>

									<div className={styles.table_container}>
										<table className={styles.sites_table} id="ind_table">
										    <thead className={styles.sitesind_thead}>
										      <tr className={styles.table_rowth}>
										      	<th>Item name</th>
										      	<th>Quantity</th>
										      </tr>
										    </thead>
										    <tbody className={styles.indtbody}>
										    	{(accountPrefSelected['box_type'] === "Seasonal" || accountPrefSelected['box_type'] === "Flexi")
										    	?
										    		<>
										    			{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0)
										    				?
											    				<>
													    			{accountPrefSelected[`items_${weekActive+1}`] && accountPrefSelected[`items_${weekActive+1}`].length > 0
													    				? 
													    				<>
														    				{accountPrefSelected[`items_${weekActive+1}`][boxActive].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}

																	    	<tr className={styles.sitesind_tr}>
																	    		<td>Total Quantity</td>
																	    		<td>{accountPrefSelected[`items_${weekActive+1}`][boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
																	    	</tr>
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
													    			}
												    			</>
										    				:
											    				<>
													    			{accountPrefSelected.items && accountPrefSelected.items.length > 0
													    				? 
													    				<>
														    				{accountPrefSelected.items[boxActive].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}

																	    	<tr className={styles.sitesind_tr}>
																	    		<td>Total Quantity</td>
																	    		<td>{accountPrefSelected.items[boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
																	    	</tr>
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
													    			}
												    			</>
										    			}
										    		</>
										    	:
										    		<>
										    			{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0)
										    				?
										    					<>
													    			{accountPrefSelected[`items_${weekActive+1}`] && accountPrefSelected[`items_${weekActive+1}`].length > 0
													    				? 
													    				<>
														    				{accountPrefSelected[`items_${weekActive+1}`].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
													    			}
												    			</>
										    				:
										    					<>
													    			{accountPrefSelected.items && accountPrefSelected.items.length > 0
													    				? 
													    				<>
														    				{accountPrefSelected.items.map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
													    			}
												    			</>
										    			}
										    		</>
										    	}
										    </tbody>
										</table>
									</div>
								</div>
							}

						</div>
					}


					{indSiteNav === "Logs" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th colspan="1">Log code</th>
								      	<th colspan="1">Date</th>
								      	<th colspan="3">Message</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{allLogs
								    		?
								    		<>
								    			{allLogs.filter(AL => AL.site && AL.site.toString() === indSite._id.toString()).length > 0
								    				? 
								    				<>
									    				{allLogs.filter(AL => AL.site && AL.site.toString() === indSite._id.toString()).reverse().map((ALF) => {
									    					return (
														    	<tr className={styles.sitesind_tr}>
														    		<td colspan="1">{ALF.log_code}</td>
														    		<td colspan="1">
														    			<p>{formatDate(ALF.date_created, false, true).split(' @ ')[0]}</p>
														    			<p>{formatDate(ALF.date_created, false, true).split(' @ ')[1]}</p>
														    		</td>
														    		<td colspan="3"><span>{ALF.log_title}:</span><br/>{ALF.log_message}</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any logs yet!</h1>
								    			}
								    		</>
								    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any logs yet!</h1>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}

					{indSiteNav === "Interactions" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => indPopUpControls('ind_interaction')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add interaction</p>
								</button>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th>Company</th>
								      	<th>Comment</th>
								      	<th>Tags</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{allInteractions
								    		?
								    		<>
								    			{(allInteractions.filter(AI => AI.site === indSite._id).length > 0)
								    				? 
								    				<>
									    				{(allInteractions.filter(AI => AI.site === indSite._id)).map((II) => {
									    					return (
														    	<tr className={styles.sitesind_tr} onClick={() => indPopUpControls('navigate', II)}>
														    		<td>{allAccounts.filter(IA => IA._id === II.account)[0].company}</td>
														    		<td>{II.comment}</td>
													    			<td className={`${styles.site_statustd} ${styles.tag_flex}`}>
														    			{II.tags.map((TI) => {
														    				return (
																    			<div className={`${styles.site_status} ${styles.status_active}`}>
																    				<p className={styles.smaller_font}>{TI}</p>
																    			</div>
																    		)
															    		})}
														    		</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any interactions yet!</h1>
								    			}
								    		</>
								    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any interactions yet!</h1>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}

					{indSiteNav === "Allergies" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => handleAllergies('open')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Manage allergies</p>
								</button>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th colspan="3">Item name</th>
								      	<th colspan="1">Status</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{indSite['allergies']
								    		?
								    		<>
								    			{indSite.allergies.length > 0
								    				? 
								    				<>
									    				{indSite.allergies.map((IDSI) => {
									    					return (
														    	<tr className={styles.sitesind_tr}>
														    		<td colspan="3">{inventoryData.filter(inv => inv._id.toString() === IDSI.item.toString())[0].item_name}</td>
														    		<td colspan="1">EXCLUDED</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any allergies!</h1>
								    			}
								    		</>
								    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This site does not have any allergies!</h1>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}

					{indSiteNav === "Pricing" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.create_first2} ${styles.cw_pricing}`}>
									<h1 className={`${styles.medium_font}`}>Set custom pricing for items and breakdowns for this site.</h1>

									<button className={styles.indsitebtn} onClick={() => handleCustomPricing('open')}>
										<i className="fa-solid fa-plus"></i>
										<p className={styles.smaller_font}>Manage pricing</p>
									</button>
								</div>
							</div>
						</div>
					}

				</div>
			</ResizableDiv>
		</div>
	)
}

export default PopUpIndSite;