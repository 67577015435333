import { ResponsiveBar } from '@nivo/bar'

const BarChartStacked = ({ barChartStackedData }) => {
    return (
        <ResponsiveBar
            data={barChartStackedData}
            keys={[
                'Fruit',
                'Milk',
                'Bread'
            ]}
            indexBy="customer"

            margin={{ top: 0, right: 0, bottom: 50, left: 0 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            colorBy="value"

            axisTop={null}
            axisRight={null}
            axisLeft={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Preferences Breakdown (category)',
                legendPosition: 'middle',
                legendOffset: 40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'brighter',
                        100
                    ]
                ]
            }}
            role="application"
            ariaLabel="Nivo bar chart demo"

            barAriaLabel={e=>e.id+": "+e.formattedValue+" in customer: "+e.indexValue}
            label={bar => `${bar.id}`}
        />
    )
}

export default BarChartStacked;