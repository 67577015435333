import styles from "./Warehouse.module.css"

const PopUpIndPurchasing = ({ param, indPopUpControls, indPurchasing, indNav, changeIndNav, allPurchasing, allInventory, formatDate, searchControl }) => {
	return (
		<div className={styles.indsite_container}>
			<div className={styles.indsite_popup}>

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
					<button className={styles.deletePopup} onClick={() => indPopUpControls('delete', indPurchasing)}><i className="fa-solid fa-trash-can"></i></button>

					<h1 className={styles.medium_font}>Purchasing details</h1>
					<p className={styles.smaller_font}>Please find the specific purchasing details here.</p>

					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndNav('Details')}><p>Details</p></button>
							<button className={`${styles.sitenav_box} ${indNav == 'Items' && styles.sitenav_active}`} onClick={() => changeIndNav('Items')}><p>Items</p></button>
						</div>
					</div>

					{indNav == "Details" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Delivery date</label>
									<td>{indPurchasing.delivery_date ? formatDate(indPurchasing.delivery_date, true) : `${formatDate(indPurchasing.delivery_date_range[0], true)} - ${formatDate(indPurchasing.delivery_date_range[1], true)}`}</td>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Categories</label>
									<td>{indPurchasing.categories ? indPurchasing.categories.join(', ') : "-"}</td>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Date created</label>
									<p>{formatDate(indPurchasing.date_created)}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>No. items</label>
									<p>{indPurchasing.items.length} items</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Estimated cost</label>
									<p>${indPurchasing.estimated_cost.toFixed(2)}</p>
								</div>

			    				<div className={`${styles.popup_formbox}`}>
				    				<label className={styles.smaller_font}>Export</label>
				    				<div className={styles.table_button}>
										<button onClick={() => indPopUpControls('excel', indPurchasing)}>
											<i className="fa-solid fa-file-export"></i>
											<p className={styles.smaller_font}>Excel spreadsheet</p>
										</button>
									</div>
								</div>
							</div>
						</div>
					}

					{indNav == "Items" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th colspan="2">Item name</th>
								      	<th colspan="1">Quantity</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{indPurchasing.items
								    		?
								    		<>
								    			{indPurchasing.items.length > 0
								    				? 
								    				<>
									    				{indPurchasing.items.map((IP) => {
									    					return (
														    	<tr className={styles.sitesind_tr} onClick={() => indPopUpControls('navigate', IP.item)}>
														    		<td colspan="2">{allInventory.filter(item => item._id === IP.item)[0].item_name}</td>
														    		<td colspan="1">{IP.quantity}</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This purchase list has no available items.</h1>
								    			}
								    		</>
								    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This purchase list has no available items.</h1>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default PopUpIndPurchasing;