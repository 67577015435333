import Select from 'react-select';
import styles from "./Dashboard.module.css"


const PopUpClone = ({ param, handleClone, validationError, newCloneData, formatOptions }) => {
	return (
		<div className={`${styles.popup_container}`}>
			<button className={styles.closePopup} onClick={() => handleClone('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Clone Preference</h1>
			<p className={styles.smaller_font}>Select a new site and or a new day to clone this preference to.</p>

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Delivery site</label>
					<Select
						closeMenuOnSelect={true}
						options={formatOptions(newCloneData['sites'])}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								boxShadow: 'none !important',
							   '&:hover': {
							       borderColor: '#fce7eb !important'
							    },
								borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
							}),
						}}
						onChange={(selected) => handleClone("set", "site_selected", selected)}
						value={{label: newCloneData["site_selected"], value: newCloneData["site_selected"]}}
					/>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Delivery day</label>
					<Select
						closeMenuOnSelect={true}
						options={[{label: 'Monday', value: 'Monday'}, {label: 'Tuesday', value: 'Tuesday'}, {label: 'Wednesday', value: 'Wednesday'}, {label: 'Thursday', value: 'Thursday'}, {label: 'Friday', value: 'Friday'}]}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								boxShadow: 'none !important',
							   '&:hover': {
							       borderColor: '#fce7eb !important'
							    },
								borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
							}),
						}}
						onChange={(selected) => handleClone("set", "delivery_day", selected)}
						value={{label: newCloneData["delivery_day"], value: newCloneData["delivery_day"]}}
					/>
				</div>
			</div>

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handleClone('submit')}>Submit</button>
				</div>
			</div>
		</div>
	)
}

export default PopUpClone;