import React, { useState, useCallback, useRef } from 'react';
import styles from "./Utils.module.css";

function ResizableDiv({ className, startingWidth, children }) {
  const [widthPercentage, setWidthPercentage] = useState(startingWidth);
  const [isDragging, setIsDragging] = useState(false);
  const startX = useRef(null); // Store the initial X position

  const startDragging = useCallback((e) => {
    setIsDragging(true);
    startX.current = e.clientX; // Store the starting X position
    e.preventDefault();
  }, []);

  const stopDragging = useCallback(() => {
    setIsDragging(false);
    startX.current = null; // Reset the starting X position
  }, []);

  const whileDragging = useCallback((e) => {
    if (isDragging && startX.current !== null) {
      const deltaX = e.clientX - startX.current;
      const deltaPercentage = (deltaX / window.innerWidth) * 100;

      // Adjust width, ensuring it's between startingWidth and 100%
      setWidthPercentage((prevWidth) => {
        const newWidth = Math.min(100, Math.max(startingWidth, prevWidth - deltaPercentage));
        return newWidth;
      });

      startX.current = e.clientX; // Update startX for the next move event
    }
  }, [isDragging, startingWidth]);

  React.useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', whileDragging);
      window.addEventListener('mouseup', stopDragging);
      return () => {
        window.removeEventListener('mousemove', whileDragging);
        window.removeEventListener('mouseup', stopDragging);
      };
    }
  }, [isDragging, whileDragging, stopDragging]);

  return (
    <div
      style={{ width: `${widthPercentage}%`, position: 'relative' }}
      className={styles[className]}
    >
      {children}
      <div
        style={{
          position: 'absolute',
          width: '10px',
          height: '20px',
          cursor: 'ew-resize',
          top: '50%',
          left: '3.5px',
          transform: 'translate(-50%, -50%)',
          opacity: '.25',
        }}
        onMouseDown={startDragging}
      ><i className={`${styles.rotateIcon} fa-solid fa-minus`}></i></div>
    </div>
  );
}

export default ResizableDiv;
