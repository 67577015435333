import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import styles from "./NavigationBar.module.css"

const NavigationBar = ({ param, active, handleLogout, collapseDeg, collapseFilters, setCollapseDeg, setCollapseFilters }) => {
    const [textVisible, setTextVisible] = useState(false);

    useEffect(() => {
        if (collapseFilters) {
            setTimeout(() => setTextVisible(false), 600);
        } else {
            setTextVisible(true);
        }
    }, [collapseFilters]);

    const handleCollapse = async (control) => {
    	let intervalId;

        switch (control) {
            case 'enter':
            	if (collapseDeg !== 0) {
            		setCollapseDeg(0)
            	}

            	if (collapseDeg !== 15) {
	                intervalId = setInterval(() => {
	                    setCollapseDeg(prevDeg => {
	                        if (prevDeg >= 15) {
	                            clearInterval(intervalId);
	                            return prevDeg;
	                        }
	                        return prevDeg + 1;
	                    });
	                }, 10);
            	}
                break;
            case 'leave':
                intervalId = setInterval(() => {
                    setCollapseDeg(prevDeg => {
                        if (prevDeg === 0) {
                            clearInterval(intervalId);
                            return prevDeg;
                        }
                        return prevDeg - 1;
                    });
                }, 10);
                break;
             case 'toggle':
             	setCollapseDeg(0)
             	setCollapseFilters(!collapseFilters)
             	break;
        }
    };

	return (
		<div className={styles.flexedNav}>
			<div className={styles.collapsedContainer}>
				<div className={`${styles.collapseMap}`}>
					<div 
						className={`${styles.collapseInner} ${collapseDeg === 0 && styles.collapseOpacitated}`}
						onMouseEnter={() => handleCollapse('enter')}
						onMouseLeave={() => handleCollapse('leave')}
						onClick={() => handleCollapse('toggle')}
					>
		            	<div className={styles.collapseBtn} style={{transform: `translateY(0.15rem) rotate(${collapseFilters ? "-" : ""}${collapseDeg}deg) translateZ(0px)`}}></div>
		            	<div className={styles.collapseBtn} style={{transform: `translateY(-0.15rem) rotate(${(!collapseFilters && collapseDeg !== 0) ? "-" : ""}${collapseDeg}deg) translateZ(0px)`}}></div>
		            </div>
				</div>
			</div>

			<div className={collapseFilters ? styles.sidebar_container : styles.sidebar_container_full}>
				<Link to={`/admin/profile/${param._id}`}>
					<div className={`${styles.sidebar_account} ${collapseFilters ? styles.sidebar_box : styles.sidebar_box_full} ${active === "Profile" && styles.sidebar_active}`}>
						<div className={styles.sidebar_icon_container}>
							<i className={`fa-solid fa-circle-user ${collapseFilters ? styles.sidebar_icon : styles.sidebar_icon_full}`} />
						</div>
                        <p className={`${active === "Profile" ? styles.sidebar_p_active : styles.sidebar_p} ${collapseFilters ? (textVisible ? styles.text_hidden : styles.text_hidden_delay) : styles.text_fade}`}>{textVisible && "Profile"}</p>
					</div>
				</Link>

				<div className={styles.sidebar_centered}>
					<div className={styles.sidebar_grid}>
						{/* account management/inquiries */}
						<Link to={`/admin/dashboard/${param._id}`}>
							<div className={`${collapseFilters ? styles.sidebar_box : styles.sidebar_box_full} ${active === "Dashboard" && styles.sidebar_active}`}>
								<div className={styles.sidebar_icon_container}>
									<i className={`fa-solid fa-magnifying-glass ${collapseFilters ? styles.sidebar_icon : styles.sidebar_icon_full}`} />
								</div>
                                <p className={`${active === "Dashboard" ? styles.sidebar_p_active : styles.sidebar_p} ${collapseFilters ? (textVisible ? styles.text_hidden : styles.text_hidden_delay) : styles.text_fade}`}>{textVisible && "Dashboard"}</p>
							</div>
						</Link>

						<Link to={`/admin/warehouse/${param._id}`}>
							<div className={`${collapseFilters ? styles.sidebar_box : styles.sidebar_box_full} ${active === "Warehouse" && styles.sidebar_active}`}>
								<div className={styles.sidebar_icon_container}>
									<i className={`fa-solid fa-warehouse ${collapseFilters ? styles.sidebar_icon : styles.sidebar_icon_full}`} />
								</div>
                                <p className={`${active === "Warehouse" ? styles.sidebar_p_active : styles.sidebar_p} ${collapseFilters ? (textVisible ? styles.text_hidden : styles.text_hidden_delay) : styles.text_fade}`}>{textVisible && "Warehouse"}</p>
							</div>
						</Link>

						<Link to={`/admin/fleet/${param._id}`}>
							<div className={`${collapseFilters ? styles.sidebar_box : styles.sidebar_box_full} ${active === "Fleet" && styles.sidebar_active}`}>
								<div className={styles.sidebar_icon_container}>
									<i className={`fa-solid fa-truck ${collapseFilters ? styles.sidebar_icon : styles.sidebar_icon_full}`} />
								</div>
                                <p className={`${active === "Fleet" ? styles.sidebar_p_active : styles.sidebar_p} ${collapseFilters ? (textVisible ? styles.text_hidden : styles.text_hidden_delay) : styles.text_fade}`}>{textVisible && "Fleet"}</p>
							</div>
						</Link>

						<Link to={`/admin/invoices/${param._id}`}>
							<div className={`${collapseFilters ? styles.sidebar_box : styles.sidebar_box_full} ${active === "Invoices" && styles.sidebar_active}`}>
								<div className={styles.sidebar_icon_container}>
									<i className={`fa-solid fa-file-invoice-dollar ${collapseFilters ? styles.sidebar_icon : styles.sidebar_icon_full}`} />
								</div>
                                <p className={`${active === "Invoices" ? styles.sidebar_p_active : styles.sidebar_p} ${collapseFilters ? (textVisible ? styles.text_hidden : styles.text_hidden_delay) : styles.text_fade}`}>{textVisible && "Invoices"}</p>
							</div>
						</Link>

						<Link to={`/admin/reports/${param._id}`}>
							<div className={`${collapseFilters ? styles.sidebar_box : styles.sidebar_box_full} ${active === "Reports" && styles.sidebar_active}`}>
								<div className={styles.sidebar_icon_container}>
									<i className={`fa-solid fa-chart-pie ${collapseFilters ? styles.sidebar_icon : styles.sidebar_icon_full}`} />
								</div>
                                <p className={`${active === "Reports" ? styles.sidebar_p_active : styles.sidebar_p} ${collapseFilters ? (textVisible ? styles.text_hidden : styles.text_hidden_delay) : styles.text_fade}`}>{textVisible && "Reports"}</p>
							</div>
						</Link>

						<Link to={`/admin/users/${param._id}`}>
							<div className={`${collapseFilters ? styles.sidebar_box : styles.sidebar_box_full} ${active === "Employees" && styles.sidebar_active}`}>
								<div className={styles.sidebar_icon_container}>
									<i className={`fa-solid fa-user-group ${collapseFilters ? styles.sidebar_icon : styles.sidebar_icon_full}`} />
								</div>
                                <p className={`${active === "Employees" ? styles.sidebar_p_active : styles.sidebar_p} ${collapseFilters ? (textVisible ? styles.text_hidden : styles.text_hidden_delay) : styles.text_fade}`}>{textVisible && "Employees"}</p>
							</div>
						</Link>

					</div>
				</div>

				<Link to={`/admin/bin/${param._id}`}>
					<div className={`${styles.sidebar_trash} ${collapseFilters ? styles.sidebar_box : styles.sidebar_box_full} ${active === "Bin" && styles.sidebar_active}`}>
						<div className={styles.sidebar_icon_container}>
							<i className={`fa-solid fa-trash-can ${collapseFilters ? styles.sidebar_icon : styles.sidebar_icon_full}`} />
						</div>
                        <p className={`${active === "Bin" ? styles.sidebar_p_active : styles.sidebar_p} ${collapseFilters ? (textVisible ? styles.text_hidden : styles.text_hidden_delay) : styles.text_fade}`}>{textVisible && "Bin"}</p>
					</div>
				</Link>
			</div>
		</div>
	)
}

export default NavigationBar;