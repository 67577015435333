import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import styles from "./Dashboard.module.css"

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpPOLimit = ({ param, handlePOLimit, validationError, newPOLimit }) => {
	return (
		<div className={`${styles.popup_container} ${styles.popup_maxwidth}`}>
			<button className={styles.closePopup} onClick={() => handlePOLimit('close')}><i class="fa-solid fa-x"></i></button>
			{newPOLimit.edit && <button className={styles.editPopup} onClick={() => handlePOLimit('delete')}><i className="fa-solid fa-trash-can"></i></button>}

			<h1 className={styles.medium_font}>PO Limit</h1>
			<p className={styles.smaller_font}>Create a new PO Limit for this account using this module.</p>

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Date range</label>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
					    <DateRangePicker
					        className={styles.overridebg}
					        localeText={{ start: '', end: '' }}
					        value={newPOLimit.date_range && newPOLimit.date_range}
					        onChange={(selected) => handlePOLimit('set', 'date_range', selected)}
					        disablePast
					        format="DD/MM/YYYY"
					    />
					</LocalizationProvider>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Amount</label>
					<input
						type="text"
						value={newPOLimit.amount && newPOLimit.amount}
						onChange={(input) => handlePOLimit('set', 'amount', input)}
					/>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Reference</label>
					<input
						type="text"
						value={newPOLimit.reference && newPOLimit.reference}
						onChange={(input) => handlePOLimit('set', 'reference', input)}
					/>
				</div>
			</div>

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handlePOLimit('submit')}>Submit</button>
				</div>
			</div>
		</div>
	)
}

export default PopUpPOLimit;