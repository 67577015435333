import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from "./Invoices.module.css"

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpCreate = ({ param, popUpControls, indNav, changeIndNav, internalLoading, formatOptions, allAccounts, createPopUpControls, createInvoiceData, allInventory, handleCreateInvoiceData, allInvoiceData, handleInvoiceRowData, onDragEndItems, validationError }) => {
	return (
		<div className={styles.indsite_container}>
			<div className={styles.invoice_popup}>

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => popUpControls('close')}><i className="fa-solid fa-x"></i></button>


					<h1 className={styles.medium_font}>Create invoice</h1>
					<p className={styles.smaller_font}>Please fill-out the specific invoice details to create.</p>

					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndNav('Details')}><p>Details</p></button>
							<button className={`${styles.sitenav_box} ${indNav == 'Items' && styles.sitenav_active}`} onClick={() => changeIndNav('Items')}><p>Items</p></button>
						</div>
					</div>

					{internalLoading
						?
						<div className={styles.loadpage_container}>
							<div className={styles.load}>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
						:
						<>
							<div className={styles.inv_tablecontainer}>
								{indNav == "Details" &&
									<div className={`${styles.popup_form_invoice}`}>
										<div className={`${styles.popup_formbox}`}>
											<label className={styles.smaller_font}>Customer</label>
											<CreatableSelect
												closeMenuOnSelect={true}
												options={formatOptions(allAccounts.map(AA => AA.company))}
												styles={{
													control: (baseStyles, state) => ({
														...baseStyles,
														boxShadow: 'none !important',
													   '&:hover': {
													       borderColor: '#fce7eb !important'
													    },
														borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
													}),
												}}
												onChange={(selected) => handleCreateInvoiceData("customer", selected)}
												value={{label: allInvoiceData["customer"], value: allInvoiceData["customer"]}}
											/>
										</div>

										<div className={`${styles.popup_form_flex}`}>
											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Invoice contact name</label>
												<input type="text" defaultValue={allInvoiceData && allInvoiceData.invoice_contact_name} onChange={(input) => handleCreateInvoiceData("invoice_contact_name", input)}/>
											</div>

											<div className={styles.popup_formbox}>
												<label className={styles.smaller_font}>Recipient email</label>
												<input type="text" defaultValue={allInvoiceData && allInvoiceData.recipient_email} onChange={(input) => handleCreateInvoiceData("recipient_email", input)}/>
											</div>
										</div>

										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Billing address</label>
											<input type="text" defaultValue={allInvoiceData && allInvoiceData.billing_address} onChange={(input) => handleCreateInvoiceData("billing_address", input)}/>
										</div>

										<div className={styles.popup_form_customdate_inv}>
											<div className={`${styles.popup_formbox}`}>
												<div className={styles.label_container}>
													<label className={styles.smaller_font}>Issued on</label>
													<label className={styles.smaller_font}>Due date</label>
												</div>
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<DateRangePicker
														className={styles.overridebg}
														localeText={{ start: '', end: '' }}
														value={allInvoiceData.invoice_dates && allInvoiceData.invoice_dates}
														onChange={(selected) => handleCreateInvoiceData("invoice_dates", selected)}
														format="DD/MM/YYYY"
														disablePast
													/>
												</LocalizationProvider>
											</div>
										</div>
									</div>
								}

								{indNav == "Items" &&
									<div className={styles.invoice_generation}>
										<DragDropContext onDragEnd={onDragEndItems}>
											<Droppable droppableId="menuItems">
												{(provided) => (
													<div {...provided.droppableProps} ref={provided.innerRef} className={`${styles.invoice_items_container}`}>
														{createInvoiceData.map((CI, index) => (
															<Draggable key={CI.order} draggableId={CI.order.toString()} index={CI.order} id={CI.order}>
																{(provided) => (
																	<div ref={provided.innerRef} {...provided.draggableProps} className={`${styles.invoice_grid}`}>
																		
																		<div className={`${styles.invoice_formbox}`}>
																			<label className={styles.smaller_font}>Qty</label>
																			<input type="text" name="qty" onChange={(selected) => handleInvoiceRowData("qty", selected, CI.order)} defaultValue={CI.qty}/>
																		</div>

																		<div className={`${styles.invoice_formbox}`}>
																			<label className={styles.smaller_font}>Item</label>
																			<CreatableSelect
																				options={formatOptions(allInventory.map(AI => AI.item_name))}
																				styles={{
																					control: (baseStyles, state) => ({
																						...baseStyles,
																						boxShadow: 'none !important',
																					   '&:hover': {
																					       borderColor: '#fce7eb !important'
																					    },
																						borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
																					}),
																				}}
																				onChange={(selected) => handleInvoiceRowData("item", selected, CI.order)}
																				value={{label: CI.item, value: CI.item}}
																			/>
																		</div>
																		
																		<div className={`${styles.invoice_formbox}`}>
																			<label className={styles.smaller_font}>Description</label>
																			<input type="text" name="description" onChange={(selected) => handleInvoiceRowData("description", selected, CI.order)} defaultValue={CI.description}/>
																		</div>

																		<div className={`${styles.invoice_formbox}`}>
																			<label className={styles.smaller_font}>Discount</label>
																			<input type="text" name="discount" placeholder="%" onChange={(selected) => handleInvoiceRowData("discount", selected, CI.order)} defaultValue={CI.discount}/>
																		</div>

																		<div className={`${styles.invoice_formbox}`}>
																			<label className={styles.smaller_font}>GST</label>
																			<input type="text" name="gst" placeholder="%" onChange={(selected) => handleInvoiceRowData("gst", selected, CI.order)} defaultValue={CI.gst}/>
																		</div>

																		<div className={`${styles.invoice_formbox}`}>
																			<label className={styles.smaller_font}>Amount</label>
																			<input type="text" name="amount" placeholder="$" onChange={(selected) => handleInvoiceRowData("amount", selected, CI.order)} defaultValue={CI.amount}/>
																		</div>

																		<div className={`${styles.invoice_grid_menu}`}>
																			<i className={`fa-solid fa-up-down ${styles.dragBtn}`} {...provided.dragHandleProps}></i>
																			<button onClick={() => createPopUpControls('delete', CI.order)} disabled={CI.order == 0}>
																				<i className={`fa-solid fa-trash-can`}></i>
																			</button>
																		</div>

																	</div>
																)}
															</Draggable>
														))}
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</DragDropContext>

										<button className={styles.add_item} onClick={() => createPopUpControls('add')}>
											<i className="fa-solid fa-plus"></i>
											<p>Add item</p>
										</button>
									</div>
								}

								{validationError &&
									<div className={`${styles.notification} ${styles.validationError}`}>
										<p className={styles.smaller_font}>{validationError}</p>
									</div>
								}

								<div className={styles.popUpControls}>
									<div className={styles.popUpControlsContainer}>
										<button type="button" className={styles.submitBtn} onClick={() => createPopUpControls('submit')}>Create</button>
										<button type="button" className={styles.submitBtn}>Create and send</button>
						       		</div>
					       		</div>
							</div>
						</>
					}
				</div>
			</div>
		</div>
	)
}

export default PopUpCreate;