import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ResizableDiv from '../Utils/ResizableDiv';
import styles from "./Dashboard.module.css"

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpIndAccount = ({ param, indPopUpControls, allAccounts, indAccount, changeIndSiteNav, indSiteNav, changeStatus, changingStatus, searchControl, formatDate, handleSiteGroups, allSites, allOrders, allPreferences, accountPrefSelected, showPrefItems, handleShowPrefItems, weekActive, boxActive, inventoryData, getTotalQuantity, sortSIorders, handlePOLimit, sortSIPrefs, flagPOLimits, isSuperAdmin, handleCredits, sortPrefsIntoDays, showPrefHead, accountPrefHeadSelected, sortSites, getRandomPastelHex, inactiveDatePopup, inactiveDate }) => {
	return (
		<div className={styles.indsite_container} >
			<ResizableDiv className="indsite_popup" startingWidth="50">
				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
					<button className={styles.nextPopup} onClick={() => indPopUpControls('email_change')}><i className="fa-solid fa-envelope-circle-check"></i></button>
					<button className={styles.betweenPopup} onClick={() => indPopUpControls('clone_account')}><i className="fa-solid fa-clone"></i></button>
					{/* <button className={styles.nextPopup} onClick={() => indPopUpControls('delete', indAccount)}><i className="fa-solid fa-trash-can"></i></button> */}

					<h1 className={styles.medium_font}>Account {indSiteNav.toLowerCase()}</h1>
					<p className={styles.smaller_font}>Please find the specific {indSiteNav.toLowerCase()} for your account here.</p>

					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Details')}><p>Details</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Sites' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Sites')}><p>Sites</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Orders' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Orders')}><p>Orders</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Preferences' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Preferences')}><p>Preferences</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Site Pricing' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Site Pricing')}><p>Site Pricing</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'PO Limits' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('PO Limits')}><p>PO Limits</p></button>
							{isSuperAdmin && <button className={`${styles.sitenav_box} ${indSiteNav == 'Credit' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Credit')}><p>Credit</p></button>}
						</div>
					</div>

					{indAccount.po_limits && indAccount.po_limits.length > 0 &&
						<>
							{flagPOLimits(indAccount.po_limits) &&
								<button className={`${styles.flaggedOrdersWarning} ${styles.customFlagError}`}>
									<i className={`fa-solid fa-flag ${styles.smaller_font}`}></i>
									<p className={styles.small_font}>This account has used 90% or more of the current PO limit.</p>
								</button>
							}
						</>
					}

					{indSiteNav == "Details" &&
						<div className={`${styles.sites_tablecontainer} ${changingStatus && styles.hidden_container}`}>
							<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Account code</label>
									<p>{indAccount.account_code}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Company</label>
									<p>{indAccount.company}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Email</label>
									<a className={styles.clickable_nav} href={`mailto:${indAccount.email}`}><p>{indAccount.email}</p></a>
								</div>

								{/* FIND BETTER SOLUTION WITHOUT INLINE */}
								<div className={styles.popup_formbox} style={{position: 'relative'}}>
									<div style={{position: 'absolute'}}>
										<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px', gridTemplateColumns: 'max-content'}}>
											<label className={styles.smaller_font}>Status</label>
							    			<button onClick={() => changeStatus('toggle')} className={`${styles.site_status} ${indAccount.status == "Pending" && styles.status_pending} ${(indAccount.status == "Active" || indAccount.status == "Completed") && styles.status_active} ${indAccount.status == "Inactive" && styles.status_inactive}`}>
							    				<p className={styles.smaller_font}>{indAccount.status}</p>
							    			</button>
						    			</div>

						    			{indAccount.hasOwnProperty('inactive_commencement') &&
						    				<p className={`${styles.smaller_font}`}>Becoming inactive: {formatDate(indAccount.inactive_commencement)}</p>
						    			}
					    			</div>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Invoice cycle</label>
									<p>{indAccount.invoice_cycle || '-'}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Payment terms</label>
									<p>{indAccount.payment_terms || '-'}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Date created</label>
									<p>{formatDate(indAccount.date_created)}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Primary contact</label>
									<div className={styles.site_contact_flex}>
										<p>{(indAccount.contacts[0] && indAccount.contacts[0].first_name && indAccount.contacts[0].first_name != '') && indAccount.contacts[0].first_name} {(indAccount.contacts[0] && indAccount.contacts[0].last_name && indAccount.contacts[0].last_name != '') && indAccount.contacts[0].last_name}</p>
									</div>
									<div className={styles.site_contact_flex}>
										{(indAccount.contacts[0] && indAccount.contacts[0].email && indAccount.contacts[0].email != '')
											? <a className={styles.clickable_nav} href={`mailto:${indAccount.contacts[0].email}`}><p>{indAccount.contacts[0].email}</p></a>
											: '-'
										}
									</div>
									<div className={styles.site_contact_flex}>
										{(indAccount.contacts[0] && indAccount.contacts[0].phone && indAccount.contacts[0].phone != '')
											? <a className={styles.clickable_nav} href={`tel:${indAccount.contacts[0].phone}`}><p>{indAccount.contacts[0].phone}</p></a>
											: '-'
										}
									</div>
								</div>

								{(indAccount.contacts.find(contact => contact.type === "Secondary") && Object.values(indAccount.contacts.find(contact => contact.type === "Secondary")).filter(val => val && val !== '' && !(/^[0-9a-fA-F]{24}$/.test(val))).length > 1) &&
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>{indAccount.contacts.find(contact => contact.type === "Secondary").type} contact</label>
										<div className={styles.site_contact_flex}>
											<p>
												{(indAccount.contacts.find(contact => contact.type === "Secondary").first_name && indAccount.contacts.find(contact => contact.type === "Secondary").first_name !== '') ? `${indAccount.contacts.find(contact => contact.type === "Secondary").first_name} ` : '- '}
												{(indAccount.contacts.find(contact => contact.type === "Secondary").last_name && indAccount.contacts.find(contact => contact.type === "Secondary").last_name !== '') ? indAccount.contacts.find(contact => contact.type === "Secondary").last_name : '-'}
											</p>
										</div>
										<div className={styles.site_contact_flex}>
											{(indAccount.contacts.find(contact => contact.type === "Secondary").email && indAccount.contacts.find(contact => contact.type === "Secondary").email !== '') &&
												<a className={styles.clickable_nav} href={`mailto:${indAccount.contacts.find(contact => contact.type === "Secondary").email}`}><p>{indAccount.contacts.find(contact => contact.type === "Secondary").email}</p></a>
											}
										</div>
										<div className={styles.site_contact_flex}>
											{(indAccount.contacts.find(contact => contact.type === "Secondary").phone && indAccount.contacts.find(contact => contact.type === "Secondary").phone !== '') &&
												<a className={styles.clickable_nav} href={`tel:${indAccount.contacts.find(contact => contact.type === "Secondary").phone}`}><p>{indAccount.contacts.find(contact => contact.type === "Secondary").phone}</p></a>
											}
										</div>
									</div>
								}

								{(indAccount.contacts.find(contact => contact.type === "Accountant") && Object.values(indAccount.contacts.find(contact => contact.type === "Accountant")).filter(val => val && val !== '' && !(/^[0-9a-fA-F]{24}$/.test(val))).length > 1) &&
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>{indAccount.contacts.find(contact => contact.type === "Accountant").type} contact</label>
										<div className={styles.site_contact_flex}>
											<p>
												{(indAccount.contacts.find(contact => contact.type === "Accountant").first_name && indAccount.contacts.find(contact => contact.type === "Accountant").first_name !== '') ? `${indAccount.contacts.find(contact => contact.type === "Accountant").first_name} ` : '- '}
												{(indAccount.contacts.find(contact => contact.type === "Accountant").last_name && indAccount.contacts.find(contact => contact.type === "Accountant").last_name !== '') ? indAccount.contacts.find(contact => contact.type === "Accountant").last_name : '-'}
											</p>
										</div>
										<div className={styles.site_contact_flex}>
											{(indAccount.contacts.find(contact => contact.type === "Accountant").email && indAccount.contacts.find(contact => contact.type === "Accountant").email !== '') &&
												<a className={styles.clickable_nav} href={`mailto:${indAccount.contacts.find(contact => contact.type === "Accountant").email}`}><p>{indAccount.contacts.find(contact => contact.type === "Accountant").email}</p></a>
											}
										</div>
										<div className={styles.site_contact_flex}>
											{(indAccount.contacts.find(contact => contact.type === "Accountant").phone && indAccount.contacts.find(contact => contact.type === "Accountant").phone !== '') &&
												<a className={styles.clickable_nav} href={`tel:${indAccount.contacts.find(contact => contact.type === "Accountant").phone}`}><p>{indAccount.contacts.find(contact => contact.type === "Accountant").phone}</p></a>
											}
										</div>
									</div>
								}
							</div>
						</div>
					}

	    			{(changingStatus && !inactiveDatePopup) &&
	    				<div className={styles.statusOptions}>
	    					<button className={`${styles.closePopup} ${styles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

							<h1 className={styles.medium_font}>Change status</h1>
							<p className={`${styles.smaller_font}`}>Use this module to update the status for {indAccount.company}</p>

							<div className={styles.statusOuter}>
								<div className={styles.statusInner}>
			    					{indAccount.status !== "Pending" &&
						    			<button onClick={() => changeStatus('set', 'Account', indAccount, 'Pending')} className={`${styles.site_status} ${styles.status_pending}`}>
						    				<p className={styles.smaller_font}>Pending</p>
						    			</button>
					    			}

					    			{indAccount.status !== "Active" &&
						    			<button onClick={() => changeStatus('set', 'Account', indAccount, 'Active')} className={`${styles.site_status} ${styles.status_active}`}>
						    				<p className={styles.smaller_font}>Active</p>
						    			</button>
						    		}

						    		{indAccount.status !== "Inactive" &&
						    			<button onClick={() => changeStatus('set', 'Account', indAccount, 'Inactive')} className={`${styles.site_status} ${styles.status_inactive}`}>
						    				<p className={styles.smaller_font}>Inactive</p>
						    			</button>
						    		}
					    		</div>
				    		</div>
	    				</div>
	    			}

	    			{inactiveDatePopup &&
	    				<div className={styles.statusOptionsDate}>
	    					<button className={`${styles.closePopup} ${styles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

							<h1 className={styles.medium_font}>Set Inactive</h1>
							<p className={`${styles.smaller_font}`}>Use this module to update the status for {indAccount.company} to inactive</p>

							<div className={`${styles.popup_form}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Starting date</label>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
									    <DatePicker
									        className={styles.overridebg}
									        localeText={{ start: '', end: '' }}
									        value={inactiveDate && inactiveDate}
									        onChange={(selected) => changeStatus('set_date', selected)}
									        disablePast
									        format="DD/MM/YYYY"
									    />
									</LocalizationProvider>
								</div>
							</div>

							<div className={styles.popUpControls}>
								<div className={styles.popUpControlsContainer}>
									<button type="button" className={styles.submitBtn} onClick={() => changeStatus('set', 'Account', indAccount, 'Inactive')}>Submit</button>
								</div>
							</div>

	    				</div>
	    			}


					{indSiteNav == "Sites" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => indPopUpControls('nav_indaccount')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add site</p>
								</button>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th colspan="2">Site name</th>
								      	<th colspan="1">Status</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
							            {(() => {
							                let groupColours = [];

							                if (indAccount.sites_pushed.length > 0 && (indAccount.site_groups && indAccount.site_groups.length > 0)) {
							                    for (let aGroup of indAccount.site_groups) {
							                        groupColours.push(getRandomPastelHex());
							                    }
							                }

							                return (
							                    <>
							                        {indAccount.sites_pushed ? (
							                            <>
							                                {indAccount.sites_pushed.length > 0 ? (
							                                    <>
							                                        {sortSites(indAccount.sites_pushed).map((IS) => {
							                                            let colourToUse;
							                                            for (let s=0; s < indAccount.site_groups.length; s++) {
							                                            	if (indAccount.site_groups[s].includes(IS.site_id.toString())) {
							                                            		colourToUse = groupColours[s]
							                                            	}
							                                            }

							                                            return (
							                                                <tr
							                                                    key={IS.site_code}
							                                                    className={styles.sitesind_tr}
							                                                    onClick={() => indPopUpControls('navigate', IS)}
							                                                    style={{backgroundColor: colourToUse}}
							                                                >
							                                                    <td colSpan="2">{IS.site_name}</td>
							                                                    <td colSpan="1" className={styles.site_statustd}>
							                                                        <div
							                                                            className={`${styles.site_status} ${
							                                                                IS.status === 'Pending' && styles.status_pending
							                                                            } ${
							                                                                (IS.status === 'Active' || IS.status === 'Completed') &&
							                                                                styles.status_active
							                                                            } ${IS.status === 'Inactive' && styles.status_inactive}`}
							                                                        >
							                                                            <p className={styles.smaller_font}>{IS.status}</p>
							                                                        </div>
							                                                    </td>
							                                                </tr>
							                                            );
							                                        })}
							                                    </>
							                                ) : (
							                                    <h1 className={`${styles.medium_font} ${styles.create_first2}`}>
							                                        This account does not have any sites yet!
							                                    </h1>
							                                )}
							                            </>
							                        ) : (
							                            <h1 className={`${styles.medium_font} ${styles.create_first2}`}>
							                                This account does not have any sites yet!
							                            </h1>
							                        )}
							                    </>
							                );
							            })()}		
								    </tbody>
								</table>
							</div>
						</div>
					}

					{indSiteNav == "Orders" &&
						<div className={styles.custom_pref_layout}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "button_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => indPopUpControls('nav_indaccount')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add order</p>
								</button>
							</div>

							<div className={`${styles.sites_tablecontainer_orders} ${styles.custom_height}`}>
								<div className={styles.table_container}>
									<div className={`${styles.sites_table} ${styles.sites_table_orders}`} id="button_table">
										{(indAccount.sites_pushed && indAccount.sites_pushed.length > 0)
										?
											<>
												{indAccount.sites_pushed.map((SI) => {
													let filteredOrdersPush = sortSIorders(SI.orders)

													return (
														<>
															{filteredOrdersPush.length > 0
																?
																	<>
																		{filteredOrdersPush.map((SO) => {
																			let siteOrder = allOrders.filter(order => order._id.toString() === SO.order.toString())[0]

																			return (
																				<button className={`${styles.fleet_box_order} ${styles.ifo_height}`} onClick={() => handleShowPrefItems('open', siteOrder)}>
																					<div className={`${styles.inner_fleet_order} ${styles.ifo_space} ${(accountPrefSelected && siteOrder._id.toString() === accountPrefSelected._id.toString()) && styles.fleet_box_activeorder}`}>
																						<p className={styles.fleet_order_font}>{SI.site_name}</p>
																						<div>
																							<p className={styles.smaller_font}> {siteOrder.order_type} {siteOrder.hasOwnProperty('box_type') && " - " + siteOrder.box_type}</p>
																							<p className={styles.smaller_font}>{formatDate(siteOrder.delivery_date, true)}</p>
																							<p className={styles.smaller_font}>{getTotalQuantity(siteOrder)} items</p>
																						</div>
																					</div>
																				</button>

																			)
																		})}
																	</>

																: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This account does not have any orders yet!</h1>
															}
														</>
													)
												})}	
											</>
										:
											<h1 className={`${styles.medium_font} ${styles.create_first2}`}>This account does not have any orders yet!</h1>
										}
									</div>
								</div>
							</div>

							{showPrefItems &&
								<div className={`${
						            accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0 ?
						                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_combined : styles.indorder_seasonal_hig) :
						                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_hig : styles.indorder_seasonal_hig)
						        }`}>

						        	<div className={`${
						        		(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) || (accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0)
						        		? styles.box_layout_flexible
						        		: styles.box_layout_full

						        	}`}>
										{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) &&
											<div className={`${styles.box_layout} ${styles.box_max_layout}`}>
												{Object.keys(accountPrefSelected).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1])).map((key, index) => (
									    			<button onClick={() => indPopUpControls('handleWeek', index)}  className={`${styles.site_status} ${weekActive === index ? styles.week_active : styles.status_week}`}>
									    				<p className={styles.smaller_font}>Fortnight {index+1}</p>
									    			</button>
									    		))}
											</div>
										}

										{(accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0) &&
											<div className={`${styles.box_layout} ${styles.box_max_layout}`}>
							    				{accountPrefSelected.items.map((item, index) => {
							    					return (
										    			<button onClick={() => indPopUpControls('handleBox', index)} className={`${styles.site_status} ${boxActive === index ? styles.box_active : styles.status_active}`}>
										    				<p className={styles.smaller_font}>Box {index+1}</p>
										    			</button>
											    	)
											    })}
											</div>
										}

										<div className={styles.view_order}>
							    			<button onClick={() => indPopUpControls('navigate', accountPrefSelected)} className={`${styles.site_status}`}>
							    				<p className={styles.smaller_font}>Open Order</p>
							    			</button>
										</div>
									</div>

									<div className={styles.table_container}>
										<table className={styles.sites_table} id="ind_table">
										    <thead className={styles.sitesind_thead}>
										      <tr className={styles.table_rowth}>
										      	<th>Item name</th>
										      	<th>Quantity</th>
										      </tr>
										    </thead>
										    <tbody className={styles.indtbody}>
										    	{(accountPrefSelected['box_type'] === "Seasonal" || accountPrefSelected['box_type'] === "Flexi")
										    	?
										    		<>
										    			{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0)
										    				?
											    				<>
													    			{accountPrefSelected[`items_${weekActive+1}`] && accountPrefSelected[`items_${weekActive+1}`].length > 0
													    				? 
													    				<>
														    				{accountPrefSelected[`items_${weekActive+1}`][boxActive].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}

																	    	<tr className={styles.sitesind_tr}>
																	    		<td>Total Quantity</td>
																	    		<td>{accountPrefSelected[`items_${weekActive+1}`][boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
																	    	</tr>
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
													    			}
												    			</>
										    				:
											    				<>
													    			{accountPrefSelected.items && accountPrefSelected.items.length > 0
													    				? 
													    				<>
														    				{accountPrefSelected.items[boxActive].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}

																	    	<tr className={styles.sitesind_tr}>
																	    		<td>Total Quantity</td>
																	    		<td>{accountPrefSelected.items[boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
																	    	</tr>
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
													    			}
												    			</>
										    			}
										    		</>
										    	:
										    		<>
										    			{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0)
										    				?
										    					<>
													    			{accountPrefSelected[`items_${weekActive+1}`] && accountPrefSelected[`items_${weekActive+1}`].length > 0
													    				? 
													    				<>
														    				{accountPrefSelected[`items_${weekActive+1}`].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
													    			}
												    			</>
										    				:
										    					<>
													    			{accountPrefSelected.items && accountPrefSelected.items.length > 0
													    				? 
													    				<>
														    				{accountPrefSelected.items.map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
													    			}
												    			</>
										    			}
										    		</>
										    	}
										    </tbody>
										</table>
									</div>
								</div>
							}
						</div>
					}

					{indSiteNav == "Preferences" &&
						<div className={styles.custom_pref_more_layout}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "button_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => indPopUpControls('nav_indaccount')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add preference</p>
								</button>
							</div>

							<div className={`${styles.sites_tablecontainer_orders} ${styles.custom_height}`}>
								<div className={styles.table_container}>
									<div className={`${styles.sites_table} ${styles.sites_table_orders}`} id="button_table">
										{(indAccount.sites_pushed && indAccount.sites_pushed.length > 0)
										?
											<>
												{indAccount.sites_pushed.map((SI) => {
													return (
														<>
															{SI.preferences.length > 0
																?
																	<>
																		{Object.keys(sortPrefsIntoDays(SI.preferences)).map((SO) => {
																			let sitePrefInitial = sortPrefsIntoDays(SI.preferences)[SO]
																			let sitePrefHead = allPreferences.filter(pref => pref._id.toString() === sitePrefInitial[0].preference.toString())[0]

																			return (
																				<button className={`${styles.fleet_box_order} ${styles.ifo_height}`} onClick={() => handleShowPrefItems('initial_open', sitePrefInitial)}>
																					<div className={`${styles.inner_fleet_order} ${styles.ifo_space} ${(accountPrefHeadSelected && (accountPrefHeadSelected[0].preference.toString() === sitePrefInitial[0].preference.toString())) && styles.fleet_box_activeorder}`}>
																						<p className={styles.fleet_order_font}>{sitePrefHead.site_name} - {sitePrefInitial[0].delivery_day}</p>
																					</div>
																				</button>
																			)
																		})}
																	</>

																: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This account does not have any preferences yet!</h1>
															}
														</>
													)
												})}	
											</>
										:
											<h1 className={`${styles.medium_font} ${styles.create_first2}`}>This account does not have any preferences yet!</h1>
										}
									</div>
								</div>
							</div>

							{(showPrefHead && (accountPrefHeadSelected && accountPrefHeadSelected.length > 0)) &&
								<div className={`${styles.sites_tablecontainer_orders} ${styles.custom_height}`}>
									<div className={styles.table_container}>
										<div className={`${styles.sites_table} ${styles.sites_table_orders}`}>
											{accountPrefHeadSelected.map((SO) => {
												let sitePref = allPreferences.filter(pref => pref._id.toString() === SO.preference.toString())[0]

												if (sitePref) {
													return (
														<button className={`${styles.fleet_box_order} ${styles.ifo_height} ${styles.fleet_box_custom}`} onClick={() => handleShowPrefItems('open', sitePref)}>
															<div className={`${styles.inner_fleet_order} ${styles.ifo_space} ${(accountPrefSelected && sitePref._id.toString() === accountPrefSelected._id.toString()) && styles.fleet_box_activeorder}`}>
																<p className={styles.fleet_order_font}>{sitePref.preference_code}</p>
																<div>
																	<p className={styles.smaller_font}>{sitePref.preference_type} {(sitePref.hasOwnProperty('box_type')) && `- ${sitePref.box_type}`}</p>
																	<p className={styles.smaller_font}>{getTotalQuantity(sitePref)} items</p>
																</div>
															</div>
														</button>
													)
												}
											})}	
										</div>
									</div>
								</div>
							}
							

							{showPrefItems &&
								<div className={`${
						            accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0 ?
						                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_combined : styles.indorder_seasonal_hig) :
						                ((accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) ? styles.indorder_seasonal_hig : styles.indorder_seasonal_hig)
						        }`}>

						        	<div className={`${
								        (accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) && (accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0)
						        		? styles.box_layout_flexible_full
						        		: (accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) || (accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0)
						        		? styles.box_layout_flexible
						        		: styles.box_layout_full

						        	}`}>
										{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0) &&
											<div className={`${styles.box_layout} ${styles.box_max_layout}`}>
												{Object.keys(accountPrefSelected).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1])).map((key, index) => (
									    			<button onClick={() => indPopUpControls('handleWeek', index)}  className={`${styles.site_status} ${weekActive === index ? styles.week_active : styles.status_week}`}>
									    				<p className={styles.smaller_font}>Fortnight {index+1}</p>
									    			</button>
									    		))}
											</div>
										}

										{(accountPrefSelected && accountPrefSelected.items.reduce((count, item) => count + Array.isArray(item), 0) > 0) &&
											<div className={`${styles.box_layout} ${styles.box_max_layout}`}>
							    				{accountPrefSelected.items.map((item, index) => {
							    					return (
										    			<button onClick={() => indPopUpControls('handleBox', index)} className={`${styles.site_status} ${boxActive === index ? styles.box_active : styles.status_active}`}>
										    				<p className={styles.smaller_font}>Box {index+1}</p>
										    			</button>
											    	)
											    })}
											</div>
										}

										<div className={styles.view_order}>
							    			<button onClick={() => indPopUpControls('navigate', accountPrefSelected)} className={`${styles.site_status}`}>
							    				<p className={styles.smaller_font}>Open Preference</p>
							    			</button>
										</div>
									</div>

									<div className={styles.table_container}>
										<table className={styles.sites_table} id="ind_table">
										    <thead className={styles.sitesind_thead}>
										      <tr className={styles.table_rowth}>
										      	<th>Item name</th>
										      	<th>Quantity</th>
										      </tr>
										    </thead>
										    <tbody className={styles.indtbody}>
										    	{(accountPrefSelected['box_type'] === "Seasonal" || accountPrefSelected['box_type'] === "Flexi")
										    	?
										    		<>
										    			{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0)
										    				?
											    				<>
													    			{accountPrefSelected[`items_${weekActive+1}`] && accountPrefSelected[`items_${weekActive+1}`].length > 0
													    				? 
													    				<>
														    				{accountPrefSelected[`items_${weekActive+1}`][boxActive].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}

																	    	<tr className={styles.sitesind_tr}>
																	    		<td>Total Quantity</td>
																	    		<td>{accountPrefSelected[`items_${weekActive+1}`][boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
																	    	</tr>
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
													    			}
												    			</>
										    				:
											    				<>
													    			{accountPrefSelected.items && accountPrefSelected.items.length > 0
													    				? 
													    				<>
														    				{accountPrefSelected.items[boxActive].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}

																	    	<tr className={styles.sitesind_tr}>
																	    		<td>Total Quantity</td>
																	    		<td>{accountPrefSelected.items[boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
																	    	</tr>
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
													    			}
												    			</>
										    			}
										    		</>
										    	:
										    		<>
										    			{(accountPrefSelected && accountPrefSelected.hasOwnProperty('freqDetails') && Object.keys(accountPrefSelected.freqDetails).length > 0)
										    				?
										    					<>
													    			{accountPrefSelected[`items_${weekActive+1}`] && accountPrefSelected[`items_${weekActive+1}`].length > 0
													    				? 
													    				<>
														    				{accountPrefSelected[`items_${weekActive+1}`].map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
													    			}
												    			</>
										    				:
										    					<>
													    			{accountPrefSelected.items && accountPrefSelected.items.length > 0
													    				? 
													    				<>
														    				{accountPrefSelected.items.map((II) => {
														    					return (
																			    	<tr className={styles.sitesind_tr}>
																			    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																			    		<td>{II.quantity} items</td>
																			    	</tr>
																		    	)
																		    })}
																	    </>
													    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
													    			}
												    			</>
										    			}
										    		</>
										    	}
										    </tbody>
										</table>
									</div>
								</div>
							}
						</div>
					}

					{indSiteNav == "Site Pricing" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "button_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => handleSiteGroups('open')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add group</p>
								</button>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table}>
									{(indAccount.site_groups && indAccount.site_groups.length > 0)
									?
										<tbody className={styles.fleet_tbody}>
											<tr className={`${styles.fleet_container} ${styles.nohover}`} id="button_table">
												{indAccount.site_groups.map((IF, key) => {
													return (
														<td className={styles.fleet_td}>
															<div className={styles.fleet_box}>
																<div className={styles.inner_fleet}>
																	<div className={styles.fleet_headcontainer}>
																		<div className={styles.fleet_text}>
																			<h1 className={styles.medium_font}>Group {key+1}</h1>
																			<div className={styles.siteControlsGroup}>
																				<button className={styles.groupControlPopup} onClick={() => handleSiteGroups('edit', IF, key+1)}><i className="fa-solid fa-pen"></i></button>
																				<button className={styles.groupControlPopup} onClick={() => handleSiteGroups('delete', IF, key+1)}><i className="fa-solid fa-trash-can"></i></button>
																			</div>
																		</div>
																	</div>
																	<div className={styles.siteGroups}>
																		{indAccount.site_groups[key].map((IS, inx) => {
																			return (
																				<div className={styles.innerIS}>
																					<p className={styles.small_font}>{inx+1}.</p>
																					<p className={styles.small_font}> {allSites.filter(site => site._id.toString() === IS.toString())[0].site_name}</p>
																				</div>
																			)
																		})}
																	</div>
																</div>
															</div>
														</td>
								 					)
								 				})}
											</tr>
										</tbody>		
								 	:
								 		<h1 className={`${styles.medium_font} ${styles.create_first2}`}>No site groups available!</h1>
								 	}
								</table>
							</div>

						</div>
					}


					{indSiteNav == "PO Limits" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => handlePOLimit('open')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add PO Limit</p>
								</button>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th colspan="2">Dates</th>
								      	<th colspan="1">Amount</th>
								      	<th colspan="1">Remaining</th>
								      	<th colspan="1">Reference</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{indAccount.po_limits
								    		?
								    		<>
								    			{indAccount.po_limits.length > 0
								    				? 
								    				<>
									    				{indAccount.po_limits.reverse().map((PO, po_index) => {
									    					let og_po_index = indAccount.po_limits.length - 1 - po_index;

									    					return (
														    	<tr className={styles.sitesind_tr} onClick={() => handlePOLimit('edit', PO, og_po_index)}>
														    		<td colspan="2">{formatDate(PO.date_range[0])} - {(PO.date_range.length > 1 && PO.date_range[1]) && formatDate(PO.date_range[1])}</td>
														    		<td colspan="1">${PO.amount}</td>
														    		<td colspan="1">{(PO.remaining / PO.amount * 100).toFixed(2)}%</td>
														    		<td colspan="1">{PO.reference ? PO.reference : '-'}</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This account does not have any PO limits yet!</h1>
								    			}
								    		</>
								    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This account does not have any PO limits yet!</h1>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}


					{(isSuperAdmin && indSiteNav == "Credit") &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => handleCredits('open')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add credit</p>
								</button>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th colspan="1">Date issued</th>
								      	<th colspan="1">Amount</th>
								      	<th colspan="1">Remaining</th>
								      	<th colspan="1">Orders</th>
								      	<th colspan="2">Note</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{indAccount.credit
								    		?
								    		<>
								    			{indAccount.credit.length > 0
								    				? 
								    				<>
									    				{indAccount.credit.map((CR, credit_index) => {
									    					let og_credit_index = indAccount.credit.length - 1 - credit_index;

									    					return (
														    	<tr className={styles.sitesind_tr} onClick={() => handleCredits('edit', CR, og_credit_index)}>
														    		<td colspan="1">{formatDate(CR.date_issued)}</td>
														    		<td colspan="1">${CR.amount}</td>
														    		<td colspan="1">{(CR.remaining / CR.amount * 100).toFixed(2)}%</td>
														    		<th colspan="1">
														    			{(CR.credit_usage && CR.credit_usage.length > 0)
														    				?
																    			<>
																    				{CR.credit_usage.map((CU) => {
																    					return (
																    						<>
																	    						<a
																	    							onClick={(e) => {
																							            e.stopPropagation();
																							            indPopUpControls('navigate', allOrders.filter(order => order._id.toString() === CU.toString())[0]);
																							        }} 
																	    							className={styles.clickable_nav}
																	    						>
																	    							{allOrders.filter(order => order._id.toString() === CU.toString())[0].order_code}
																	    						</a>
																    							<br/>
																    						</>
																    					)
																    				})}
														    					</>
														    				: "-"
														    			}
														    		</th>

														    		<td colspan="2">{CR.note}</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This account does not have any credit yet!</h1>
								    			}
								    		</>
								    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This account does not have any credit yet!</h1>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}
				</div>
			</ResizableDiv>
		</div>
	)
}

export default PopUpIndAccount;