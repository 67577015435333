import Select from 'react-select';
import ReactAutocomplete from "react-google-autocomplete";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import whole_week from '../../../images/whole_week.jpg'
import ind_date from '../../../images/ind_date.jpg'
import styles from "./Warehouse.module.css"

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpPurchasing = ({ param, popUpPage, popUpControls, validationError, formatOptions, handlePurchasing, purchasingData, formatDate }) => {
	return (
		<div className={`${styles.popup_container}`}>

			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Purchase list</h1>
					<p className={styles.smaller_font}>Use this module to automatically generate purchase lists.</p>

					<div className={`${styles.popup_form}`}>

						<button className={`${styles.order_typebox} ${purchasingData.type && purchasingData.type == "date" ? styles.order_typeboxactive : ""}`} onClick={() => handlePurchasing('set', 'type', {value: 'date'})}>
							<div className={styles.order_imgbox}>
								<img src={ind_date}/>
							</div>

							<div className={styles.order_contentbox}>
								<h1 className={styles.smaller_font}>Purchasing for specific delivery date</h1>
								<p className={styles.smaller_font}>Generate purchase lists for orders on a specific date.</p>
							</div>
						</button>

						<button className={`${styles.order_typebox} ${purchasingData.type && purchasingData.type == "date_range" ? styles.order_typeboxactive : ""}`} onClick={() => handlePurchasing('set', 'type', {value: 'date_range'})}>
							<div className={styles.order_imgbox}>
								<img src={whole_week}/>
							</div>

							<div className={styles.order_contentbox}>
								<h1 className={styles.smaller_font}>Purchasing for specific week</h1>
								<p className={styles.smaller_font}>Generate purchase lists for all orders within a week.</p>
							</div>
						</button>

					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Date selection</h1>
					<p className={styles.smaller_font}>Please select the date/date-range you would like to apply.</p>

					<div className={`${styles.notification} ${styles.warningMessage} ${styles.custom_notification_margin}`}>
						{purchasingData.type === "date"
							? <p className={styles.smaller_font}>Please select a specific date to pull orders from.</p>
							: <p className={styles.smaller_font}>Please select a specific week to pull orders from.</p>
						}
					</div>

					<div className={styles.popup_formorder}>
						{purchasingData.type === "date"
							?
								<div className={styles.popup_form_customdate_inv}>
									<div className={`${styles.popup_formbox}`}>
										<label className={styles.smaller_font}>Delivery date</label>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												className={styles.overridebg}
												value={purchasingData && purchasingData['delivery_date'] ? dayjs(purchasingData.delivery_date) : null}
												onChange={(selected) => handlePurchasing("set", "delivery_date", {value: selected})}
												disablePast
												shouldDisableDate={(date) => new Date(date).getDay() === 0 || new Date(date).getDay() === 6}
												format="DD/MM/YYYY"
											/>
										</LocalizationProvider>
									</div>
								</div>
							: 
								<div className={styles.popup_form_customdate_inv}>
									<div className={`${styles.popup_formbox}`}>
										<div className={styles.label_container}>
											<label className={styles.smaller_font}>From</label>
											<label className={styles.smaller_font}>To</label>
										</div>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DateRangePicker
												className={styles.overridebg}
												localeText={{ start: '', end: '' }}
												value={purchasingData.delivery_date_range && purchasingData.delivery_date_range}
												onChange={(selected) => handlePurchasing("set", "delivery_date_range", {value: selected})}
												disablePast
												shouldDisableDate={(date) => new Date(date).getDay() === 0 || new Date(date).getDay() === 6}
												format="DD/MM/YYYY"
											/>
										</LocalizationProvider>
									</div>
								</div>
						}
					</div>
				</>
			}

			{popUpPage == 3 &&
				<>
					<h1 className={styles.medium_font}>Category selection</h1>
					<p className={styles.smaller_font}>Please select the category you would like to apply.</p>

					<div className={`${styles.notification} ${styles.warningMessage} ${styles.custom_notification_margin}`}>
						<p className={styles.smaller_font}>If you would to filter the results according to categories, please select below.</p>
					</div>

					<div className={styles.popup_formorder}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Categories</label>
							<Select
								isMulti={true}
								closeMenuOnSelect={true}
								options={(!purchasingData['categories'] || purchasingData['categories'].length === 0) ? formatOptions(["Add all", "Fruit", "Milk", "Bread", "Coffee", "Cakes", "Catering"]) : formatOptions(["Fruit", "Milk", "Bread", "Coffee", "Cakes", "Catering"])}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handlePurchasing("set", "categories", selected)}
								value={purchasingData["categories"] && formatOptions(purchasingData["categories"])}
							/>
						</div>
					</div>
				</>
			}


			{popUpPage == 4 &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have selected.</p>

					<div className={`${styles.notification} ${styles.warningMessage} ${styles.custom_notification_margin}`}>
						{purchasingData.type === "date"
							? <p className={styles.smaller_font}>All orders that are on {formatDate(purchasingData['delivery_date'])} will be pulled.</p>
							: <p className={styles.smaller_font}>All orders that are between {formatDate(purchasingData['delivery_date_range'][0])} and {formatDate(purchasingData['delivery_date_range'][1])} will be pulled.</p>
						}
					</div>

					<div className={styles.popup_formorder}>
						{purchasingData.type === "date"
							?
								<div className={`${styles.popup_formbox}`}>
									<label className={styles.smaller_font}>Delivery date</label>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											disabled={true}
											className={styles.overridebg}
											value={purchasingData && purchasingData['delivery_date'] ? dayjs(purchasingData.delivery_date) : null}
											format="DD/MM/YYYY"
										/>
									</LocalizationProvider>
								</div>
							: 
								<div className={styles.popup_form_customdate_inv}>
									<div className={`${styles.popup_formbox}`}>
										<div className={styles.label_container}>
											<label className={styles.smaller_font}>From</label>
											<label className={styles.smaller_font}>To</label>
										</div>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DateRangePicker
												disabled={true}
												className={styles.overridebg}
												localeText={{ start: '', end: '' }}
												format="DD/MM/YYYY"
												value={purchasingData.delivery_date_range && purchasingData.delivery_date_range}
											/>
										</LocalizationProvider>
									</div>
								</div>
						}

						<div className={`${styles.popup_formbox} ${styles.remTop}`}>
							<label className={styles.smaller_font}>Categories</label>
							<Select
								isMulti={true}
								closeMenuOnSelect={true}
								isDisabled={true}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								value={purchasingData["categories"] && formatOptions(purchasingData["categories"])}
							/>
						</div>
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<>
			      			{(!purchasingData['edit'] || popUpPage === 3) &&
			      				<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
			      			}
		      			</>
		      		}

		      		{popUpPage != 4 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 4 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')} disabled={validationError !== null}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpPurchasing;