import ReactAutocomplete from "react-google-autocomplete";
import ReactToPrint from "react-to-print";
import React, { useRef } from "react";
import Select from 'react-select';

import styles from "./Fleet.module.css"


const PopUpDamage = ({ param, indPopUpControls, indVehicle, validationError, popUpPage, van_damage, truck_damage, trackClick, damageClicks, setDamageMenu, damageMenu, deleteClick, damageTicker, mouseMoved }) => {
	const componentRef = useRef();

	return (
		<div className={styles.popup_container}>
			<button className={styles.closePopup} onClick={() => indPopUpControls('damage_close')}><i class="fa-solid fa-x"></i></button>
			<button className={styles.editPopup} onClick={() => indPopUpControls('damage_clear')}><i class="fa-solid fa-broom"></i></button>

	        {/* button to trigger printing of target component */}
	        <ReactToPrint
	          trigger={() => <button className={styles.printPopup}><i className="fa-solid fa-print"></i></button>}
	          content={() => componentRef.current}
	        />


			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Damage details</h1>
					<p className={styles.smaller_font}>Please click on the areas where this vehicle is damaged.</p>

					<div className={styles.popup_form}>
						<div className={styles.damage_grid} ref={componentRef}>

							<h1>Damage Report: {indVehicle.vehicle_rego}</h1>

							<div className={styles.damageimg} onMouseDown={(e) => trackClick(e)} onMouseOver={(e) => (damageTicker != null) && mouseMoved(e)}>
							
								<img src={indVehicle.type === 'Van' ? van_damage : truck_damage} />
								{damageClicks.length > 0 && damageClicks.map((DC, index) => (
									<div
										key={index}
										style={{ position: 'absolute', left: `${(DC[0] / 500) * 100}%`, top: `${(DC[1] / 350) * 100}%` }}
										onMouseEnter={() => setDamageMenu(DC)}
										onMouseLeave={() => setDamageMenu(null)}
									>
										{damageMenu && damageMenu[0] === DC[0] && damageMenu[1] === DC[1] && damageTicker == null ? (
											<button
												onClick={() => deleteClick([DC[0], DC[1]])}
												className={styles.removeIcon}
											>
												<i className="fa-solid fa-trash-can"></i>
											</button>
											) : (
												<button
													className={styles.removeIcon}
												>
													<i className={`fa-solid fa-x`}></i>
												</button>
											)}
									</div>
								))}
							</div>
						</div>
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage == 1 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => indPopUpControls('damage_submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpDamage;