import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from "./Warehouse.module.css"

import ReactAutocomplete from "react-google-autocomplete";


const PopUpSuppliers = ({ param, popUpPage, popUpControls, newSuppliersData, validationError, allSuppliers, handleNewSuppliersData, formatOptions, newSuppliersContact, handleNewSuppliersContact, handleNewSecondarySuppliersContact, newSuppliersSecondaryContact }) => {
	return (
		<div className={styles.popup_container}>

			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Supplier details</h1>
					<p className={styles.smaller_font}>Please enter the specific supplier details to create.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Supplier name</label>
							<input type="text" value={newSuppliersData.supplier_name} placeholder="Procal" onChange={(input) => handleNewSuppliersData("supplier_name", input)}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Service</label>
							<input type="text" value={newSuppliersData.service} placeholder="Milk" onChange={(input) => handleNewSuppliersData("service", input)}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Ordering email</label>
							<input type="text" value={newSuppliersData.ordering_email} placeholder="john@procal.com.au" onChange={(input) => handleNewSuppliersData("ordering_email", input)}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Account reference</label>
							<input type="text" value={newSuppliersData.account_reference} placeholder="32489134" onChange={(input) => handleNewSuppliersData("account_reference", input)}/>
						</div>
					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Supplier details</h1>
					<p className={styles.smaller_font}>Please enter the specific supplier details to create.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Name</label>
								<input type="text" value={newSuppliersContact.name} placeholder="Sarah Power" onChange={(input) => handleNewSuppliersContact("name", input)}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Role</label>
								<input type="text" value={newSuppliersContact.role} placeholder="Account manager" onChange={(input) => handleNewSuppliersContact("role", input)}/>
							</div>
						</div>

						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" value={newSuppliersContact.email} placeholder="sarah@procal.com.au" onChange={(input) => handleNewSuppliersContact("email", input)}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" value={newSuppliersContact.phone} placeholder="1300 222 333" onChange={(input) => handleNewSuppliersContact("phone", input)}/>
							</div>
						</div>
					</div>
				</>
			}

			{popUpPage == 3 &&
				<>
					<h1 className={styles.medium_font}>Secondary contact</h1>
					<p className={styles.smaller_font}>Please enter the specific secondary contact details to create.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Name</label>
								<input type="text" value={newSuppliersSecondaryContact.name} placeholder="Sarah Power" onChange={(input) => handleNewSecondarySuppliersContact("name", input)}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Role</label>
								<input type="text" value={newSuppliersSecondaryContact.role} placeholder="Account manager" onChange={(input) => handleNewSecondarySuppliersContact("role", input)}/>
							</div>
						</div>

						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" value={newSuppliersSecondaryContact.email} placeholder="sarah@procal.com.au" onChange={(input) => handleNewSecondarySuppliersContact("email", input)}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" value={newSuppliersSecondaryContact.phone} placeholder="1300 222 333" onChange={(input) => handleNewSecondarySuppliersContact("phone", input)}/>
							</div>
						</div>
					</div>
				</>
			}

			{popUpPage == 4 &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

					<div className={styles.popup_scrollable}>
						<div className={styles.popup_form}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Supplier name</label>
								<input type="text" value={newSuppliersData.supplier_name} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Service</label>
								<input type="text" value={newSuppliersData.service} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Ordering email</label>
								<input type="text" value={newSuppliersData.ordering_email} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Account reference</label>
								<input type="text" value={newSuppliersData.account_reference || '-'} disabled={true}/>
							</div>
						</div>

						<div className={styles.popup_form}>
							<div className={styles.popup_form_flex}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Name</label>
									<input type="text" value={newSuppliersContact.name} disabled={true}/>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Role</label>
									<input type="text" value={newSuppliersContact.role} disabled={true}/>
								</div>
							</div>

							<div className={styles.popup_form_flex}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Email</label>
									<input type="text" value={newSuppliersContact.email} disabled={true}/>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Phone</label>
									<input type="text" value={newSuppliersContact.phone} disabled={true}/>
								</div>
							</div>
						</div>

						{Object.keys(newSuppliersSecondaryContact).length > 0 &&
							<div className={styles.popup_form}>
								<div className={styles.popup_form_flex}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Name</label>
										<input type="text" value={newSuppliersSecondaryContact.name} disabled={true}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Role</label>
										<input type="text" value={newSuppliersSecondaryContact.role} disabled={true}/>
									</div>
								</div>

								<div className={styles.popup_form_flex}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Email</label>
										<input type="text" value={newSuppliersSecondaryContact.email} disabled={true}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Phone</label>
										<input type="text" value={newSuppliersSecondaryContact.phone} disabled={true}/>
									</div>
								</div>
							</div>
						}
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 4 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 4 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpSuppliers;