import styles from "./Dashboard.module.css"


const PopUpFlaggedPO = ({ param, handleFlagged, flaggedPOAccounts, searchControl, indPopUpControls }) => {
	return (
		<div className={`${styles.flag_container_height}`}>
			<button className={styles.closePopup} onClick={() => handleFlagged('close_po')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Flagged PO details</h1>
			<p className={styles.smaller_font}>These accounts have PO limits that have reached 90% or more.</p>


			<div className={styles.sites_tablecontainer}>
				<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
					<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
						<div className={styles.sites_search}>
							<i className="fa-solid fa-magnifying-glass"></i>
							<input
							    type="text"
							    className={styles.smaller_font}
							    placeholder="Search for any of the column headings..."
							    onKeyUp={(event) => searchControl(event, "ind_table")}
							/>
						</div>
					</div>
				</div>

				<div className={styles.table_container}>
					<table className={styles.sites_table} id="ind_table">
					    <thead className={styles.sitesind_thead}>
					      <tr className={styles.table_rowth}>
					      	<th>Company</th>
					      </tr>
					    </thead>
					    <tbody className={styles.indtbody}>

			    			{flaggedPOAccounts.length > 0
			    				? 
			    				<>
				    				{flaggedPOAccounts.map((FL) => {
				    					return (
									    	<tr className={styles.sitesind_tr} onClick={() => indPopUpControls('navigate', FL)}>
									    		<td>{FL.company}</td>
									    	</tr>
								    	)
								    })}
							    </>
			    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This account does not have any sites yet!</h1>
			    			}
					    </tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default PopUpFlaggedPO;