import { useEffect, useState, useCallback, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Offline, Online, Detector } from "react-detect-offline";
import axios from "axios";
import 'animate.css';

import Forbidden from '../Forbidden';
import Loading from '../Loading';
import ofglogo from '../../../images/logo.png'
import styles from "./Fleet.module.css"

import van_img from '../../../images/van/van.svg'
import van_damage from '../../../images/van/van_damage.svg'
import truck_img from '../../../images/truck/truck.svg'
import truck_damage from '../../../images/truck/truck_damage.svg'

import NavigationBar from '../Utils/NavigationBar'
import PopUpVehicle from './PopUpVehicle';
import PopUpIndVehicle from './PopUpIndVehicle';
import PopUpDamage from './PopUpDamage';
import PopUpLogs from './PopUpLogs';

import initializeMapWithAccessToken from '../../../utils/mapUtils';



const FleetPage = ({ param, changeNavType, navType, popUpControls, allFleet, van_img, truck_img, vehiclePopup, indPopUpControls, indVehiclePopup, damagePopup, logsPopup, searchControl }) => {
	return (
		<div className={`${styles.page_container} ${(vehiclePopup || indVehiclePopup || damagePopup || logsPopup) && styles.hidden_container}`}>
			<div className={`${styles.sites_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType}</h1>
						{navType == 'Fleet' &&
							<button onClick={() => popUpControls('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Add vehicle</p>
							</button>
						}
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'Fleet' && styles.sitenav_active}`} onClick={() => changeNavType('Fleet')}><p>Fleet</p></button>
					</div>
				</div>

				<div className={`${styles.sites_tablecontainer} ${styles.custom_height}`}>
					<div className={styles.sites_controls}>
						<div className={styles.admin_searchcontainer}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input
								    type="text"
								    placeholder="Search for any of the column headings..."
								    onKeyUp={(event) => searchControl(event, "button_table")}
								/>
							</div>
						</div>
					</div>


					<div className={styles.table_container}>
						<table className={styles.sites_table}>
							{(allFleet && allFleet.length > 0)
							?
								<tbody className={styles.fleet_tbody}>
									<tr className={`${styles.fleet_container} ${styles.nohover}`} id="button_table">
										{allFleet.map((IF, key) => {
											return (
												<td className={styles.fleet_td} key={key}>
													<button className={styles.fleet_box} onClick={() => indPopUpControls('open', IF)}>
														<div className={styles.inner_fleet}>
															<div className={styles.fleet_headcontainer}>
																<div className={styles.fleet_text}>
																	<h1 className={styles.medium_font}>{IF.vehicle_rego}</h1>
																	<p className={styles.smaller_font}>{IF.make} {IF.model} {IF.year}</p>
																</div>
																{(IF.damage && IF.damage.length > 0) && <div className={styles.fleet_status} className={`${styles.fleet_status} ${(IF.damage && IF.damage.length > 0) && styles.fleet_red}`}><i className="fa-solid fa-flag"></i></div>}
															</div>
															<div className={styles.fleet_img}>
																<img src={IF.type == "Van" ? van_img : truck_img} className={IF.type == "Van" ? styles.van : styles.truck}/>
															</div>
														</div>
													</button>
												</td>
											)
										})}
									</tr>
								</tbody>		
							:
								<h1 className={styles.create_first}>No vehicles available!</h1>
							}
						</table>
					</div>

				</div>
			</div>
		</div>
	)
}


const Fleet = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const [isLoading, setIsLoading] = useState(true);
	const [internalLoading, setInternalLoading] = useState(false);
	const [accountData, setAccountData] = useState({});
	const [allFleet, setAllFleet] = useState(null);
	const [collapseDeg, setCollapseDeg] = useState(0)
	const [collapseFilters, setCollapseFilters] = useState(true)

	const [isAuthenticated, setIsAuthenticated] = useState(false);
 	const isSuperAdmin = accountData && accountData.role && accountData.role.name === 'SuperAdmin';
 	const isAdmin = accountData && accountData.role && accountData.role.name === 'Admin';
 	const isAccount = accountData && accountData.role && accountData.role.name === 'Account';

 	const [navType, setNavType] = useState('Fleet');
 	const [indNav, setIndNav] = useState("Details");
 	const [popUpPage, setPopUpPage] = useState(1);
 	const [validationError, setValidationError] = useState(null);

 	// vehicles
 	const [vehiclePopup, setVehiclePopup] = useState(false);
 	const [newVehicleData, setNewVehicleData] = useState({});

 	// ind vehicles
 	const [indVehiclePopup, setIndVehiclePopup] = useState(false);
 	const [indVehicle, setIndVehicle] = useState(null);

 	// damage
 	const [damagePopup, setDamagePopup] = useState(false);
 	const [damageClicks, setDamageClicks] = useState([])
 	const [damageMenu, setDamageMenu] = useState(null);
 	const [damageTicker, setDamageTicker] = useState(null);

 	// logs
 	const [logsPopup, setLogsPopup] = useState(false);
 	const [newLogData, setNewLogData] = useState({});
 	
 	

	const param = useParams();
	const url = `/api/admin/fleet/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					setAccountData(data.data);
					setAllFleet(data.data.allFleet)

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}

				setIsLoading(false)
			} catch (error) {
				setAccountData(error);
			}
		};
		initLoad();
	}, [param, url]);


	const searchControl = (event, tableId) => {
	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = tableId === "button_table" ? document.getElementById(tableId).children : document.getElementById(tableId).children[1].children;

	    if (tableId === "button_table") {
		    for (let i = 0; i < target_div.length; i++) {
		        let mainValue = target_div[i].getElementsByTagName("h1")[0].textContent || target_div[i].getElementsByTagName("h1")[0].innerText
		        let subValue = target_div[i].getElementsByTagName("p")[0].textContent || target_div[i].getElementsByTagName("p")[0].innerText

		        let showItem = false;
		        [mainValue.toUpperCase(), subValue.toUpperCase()].forEach((value) => {
		            if (value.indexOf(filter) > -1) {
		                showItem = true;
		            }
		        });

		        if (showItem) {
		            target_div[i].style.display = "flex";
		        } else {
		            target_div[i].style.display = "none";
		        }
		    }
	    } else {
		    for (let i = 0; i < target_div.length; i++) {
		        let txtValues = [];
		        for (let j = 0; j < target_div[i].children.length; j++) {
	                if (target_div[i].children[j].className.includes('status')) {
	                    // handle status
	                    let txtValue = target_div[i].children[j].getElementsByTagName("p")[0].textContent || target_div[i].children[j].getElementsByTagName("p")[0].innerText
	                    txtValues.push(txtValue.toUpperCase());
	                } else {
		            	let txtValue = target_div[i].children[j].textContent || target_div[i].children[j].innerText;
		            	txtValues.push(txtValue.toUpperCase());
		            }
		        }

		        let showItem = false;
		        txtValues.forEach((value) => {
		            if (value.indexOf(filter) > -1) {
		                showItem = true;
		            }
		        });

		        if (showItem) {
		            target_div[i].style.display = "table";
		        } else {
		            target_div[i].style.display = "none";
		        }
		    }
		}
	};

	const formatDate = (dateString, showDay) => {
	    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	    const dateObj = new Date(dateString);

	    dateObj.setHours(dateObj.getHours() + 10);

	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);

	    const currentDayNumber = dateObj.getDay();

	    if (showDay) {
	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year}`;
	    } else {
	        return `${day}/${month}/${year}`;
	    }
	};

	const changeNavType = (type) => {
		setNavType(type)
	}

	const changeIndNav = async (type) => {
		setIndNav(type)

	  	if (type == "Details") {
	    	await initializeMapWithAccessToken(param, 14)
	  	}
	}

	const popUpControls = async (control) => {
		switch(control) {
			case 'open':
				switch (navType) {
					case 'Fleet':
						setVehiclePopup(true)
						break;
				}
				break;
			case 'prev':
				setPopUpPage(popUpPage-1);
				break;
			case 'next':
				switch (navType) {
					case 'Fleet':
						if (vehiclePopup) {
							if (popUpPage === 1) {
								if (!(newVehicleData.hasOwnProperty('type')) || newVehicleData['type'] == '') {
									setValidationError(`Please select the type of vehicle.`)
									return false;
								}

								if (!(newVehicleData.hasOwnProperty('vehicle_rego')) || newVehicleData['vehicle_rego'] == '') {
									setValidationError(`Please enter the vehicle registration.`)
									return false;
								}

								if (!(newVehicleData.hasOwnProperty('make')) || newVehicleData['make'] == '') {
									setValidationError(`Please enter the make of the vehicle.`)
									return false;
								}

								if (!(newVehicleData.hasOwnProperty('model')) || newVehicleData['model'] == '') {
									setValidationError(`Please enter the model of the vehicle.`)
									return false;
								}

								if (!(newVehicleData.hasOwnProperty('year')) || newVehicleData['year'] == '') {
									setValidationError(`Please enter the year of the vehicle.`)
									return false;
								}

								if (!(newVehicleData.hasOwnProperty('mileage')) || newVehicleData['mileage'] == '') {
									setValidationError(`Please enter the current mileage of the vehicle.`)
									return false;
								}

								if (!(newVehicleData.hasOwnProperty('location')) || newVehicleData['location'] == '') {
									setValidationError(`Please select the location of vehicle.`)
									return false;
								}
							}
						} else if (logsPopup) {
							if (popUpPage === 1) {
								if (!(newLogData.hasOwnProperty('type')) || newLogData['type'] == '') {
									setValidationError(`Please select the log type.`)
									return false;
								}
							}
						}

						setValidationError(null)
						setPopUpPage(popUpPage+1)
						break;
					}
				break
			case 'close':
				switch (navType) {
					case 'Fleet':
						if (Object.keys(newVehicleData).length > 0) {
							const confirm_close = window.confirm(`Are you sure you would like to discard this new vehicle?`)
							if (confirm_close) {
								setVehiclePopup(false);

								setNewVehicleData({})
								setPopUpPage(1)
								setValidationError(null)
							}
						} else {
							setVehiclePopup(false);
							setValidationError(null)
						}
						break;
				}
				break;
			case 'submit':
				setIsLoading(true)
				switch (navType) {
					case 'Fleet':
						await axios.post(`/api/admin/fleet/${param._id}`, {
							vehicleData: newVehicleData
						});
						break;
				}
				window.location.reload(false);
				break;
		}
	}

	const handleNewVehicleData = (name, selected) => {
		let newVehicleDataTemp;
		if (name === "type" || name === "location") {
			newVehicleDataTemp = { ...newVehicleData, [name]: selected.value }
		} else {
			newVehicleDataTemp = { ...newVehicleData, [name]: selected.target.value }
		}
		
		setNewVehicleData(newVehicleDataTemp)
	};


	const indPopUpControls = async (control, ind) => {
		switch(control) {
			case 'open':
				switch (navType) {
					case 'Fleet':
						setIndVehiclePopup(true)
						setIndVehicle(ind)
						await initializeMapWithAccessToken(param, 14)
						if (ind.damage) {
							setDamageClicks(ind.damage)
						}
						break;
				}
				break
			case 'edit':
				switch (navType) {
					case 'Fleet':
						setNewVehicleData({
							vehicle_rego: indVehicle.vehicle_rego,
							make: indVehicle.make,
							model: indVehicle.model,
							type: indVehicle.type,
							year: indVehicle.year,
							mileage: indVehicle.mileage,
							logs: indVehicle.logs,
							location: indVehicle.location,

							edit: true,
							vehicleID: indVehicle._id
						})

						setIndVehiclePopup(false)
						setIndVehicle(null)
						setVehiclePopup(true)
						break;
				}
				break
			case 'close':
				switch (navType) {
					case 'Fleet':
						setIndVehiclePopup(false)
						setIndNav("Details")
						setIndVehicle(null)
						break;
				}
				break;
			case 'logs_open':
				switch (navType) {
					case 'Fleet':
						setIndVehiclePopup(false)
						setLogsPopup(true)
						break;
				}
				break
			case 'logs_close':
				setLogsPopup(false)
				setNewLogData({})
				setPopUpPage(1)
				setValidationError(null)
				break
			case 'logs_submit':

				if (newLogData['type'] === "Drive") {
					if (!(newLogData.hasOwnProperty('driver')) || newLogData['driver'] == '') {
						setValidationError(`Please enter the driver's name.`)
						return false;
					}

					if (!(newLogData.hasOwnProperty('start_mileage')) || newLogData['start_mileage'] == '') {
						setValidationError(`Please enter the mileage before the drive.`)
						return false;
					}

					if (!(newLogData.hasOwnProperty('end_mileage')) || newLogData['end_mileage'] == '') {
						setValidationError(`Please enter the mileage after the drive.`)
						return false;
					}

					if (!(newLogData.hasOwnProperty('date_drove')) || newLogData['date_drove'] == '') {
						setValidationError(`Please select the date the drive occured.`)
						return false;
					}
				} else if (newLogData['type'] === "Refuel") {
					if (!(newLogData.hasOwnProperty('driver')) || newLogData['driver'] == '') {
						setValidationError(`Please enter the driver's name.`)
						return false;
					}

					if (!(newLogData.hasOwnProperty('amount_refueled')) || newLogData['amount_refueled'] == '') {
						setValidationError(`Please enter the amount refueled in liters.`)
						return false;
					}

					if (!(newLogData.hasOwnProperty('price')) || newLogData['price'] == '') {
						setValidationError(`Please enter the total price of the refuel.`)
						return false;
					}

					if (!(newLogData.hasOwnProperty('date_refueled')) || newLogData['date_refueled'] == '') {
						setValidationError(`Please select the date the refuel occured.`)
						return false;
					}
				}

				setIsLoading(true)
				switch (navType) {
					case 'Fleet':
						await axios.post(`/api/admin/fleet/logs/${param._id}`, {
							indVehicle: indVehicle,
							newLogData: newLogData
						});
						break;
				}
				window.location.reload(false);
				break;
			case 'damage_open':
				switch (navType) {
					case 'Fleet':
						setIndVehiclePopup(false)
						setDamagePopup(true)
						break;
				}
				break
			case 'damage_close':
				switch (navType) {
					case 'Fleet':
						setDamagePopup(false)
						setDamageClicks([])
						break;
				}
				break
			case 'damage_clear':
				switch (navType) {
					case 'Fleet':
						setDamageClicks([])
						break
				}
				break;
			case 'damage_submit':
				setIsLoading(true)
				switch (navType) {
					case 'Fleet':
						await axios.post(`/api/admin/fleet/damage/${param._id}`, {
							indVehicle: indVehicle,
							damageClicks: damageClicks
						});
						break;
				}
				window.location.reload(false);
				break;
			case 'delete':
				const confirm_status = window.confirm(`Are you sure you would like to delete this?`)
				if (confirm_status) {
					await axios.post(`/api/admin/dashboard/delete/${param._id}`, {
						type: navType,
						ind: ind
					});
					window.location.reload(false)
				}
		}
	}


	const trackClick = (e) => {
		if (e.target.nodeName === 'IMG') {
			const damageImg = document.querySelector(`.${styles.damageimg}`);
			if (damageImg) {
				const rect = damageImg.getBoundingClientRect();
				const relativeX = e.clientX - rect.left;
				const relativeY = e.clientY - rect.top;
				setDamageClicks([...damageClicks, [relativeX-5, relativeY-5]]);

				setDamageTicker([e.clientX-5, e.clientY-5])
			}
		}
	};

	const deleteClick = (clickArr) => {
		const damageClicksFiltered = damageClicks.filter(
			(arr) => !(arr[0] === clickArr[0] && arr[1] === clickArr[1])
		);
		setDamageClicks(damageClicksFiltered);
	};

	const mouseMoved = (e) => {
		if ((e.clientX-5 - damageTicker[0] > 0.1) || (e.clientY-5 - damageTicker[1] > 0.1)) {
			setDamageTicker(null)
		}
	}

	const handleNewLogData = (name, selected) => {
		let newLogDataTemp;
		if (name == "date_drove" || name == "date_refueled") {
			newLogDataTemp = { ...newLogData, [name]: selected.value }
		} else if (name == "type") {
			newLogDataTemp = { [name]: selected.value }
		} else {
			newLogDataTemp = { ...newLogData, [name]: selected.target.value }
		}
		
		setNewLogData(newLogDataTemp)
	};

	return (
		<>
			<Detector
				polling={{ timeout: 7000 }}
				render={({ online }) => (
					<>
						{!online && (
							<div className="offline_container">
								<div className="offline_inner animate__animated animate__slideInDown">
									<i className="fa-solid fa-heart-crack"></i>
									<div className="offline_content">
										<p className="offline_title">You are offline</p>
										<p className="offline_subtitle">Please check your internet connect.</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			/>

			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={collapseFilters ? styles.main_container : styles.main_container_full}>
								<NavigationBar 
									param={param}
									active={"Fleet"}
									handleLogout={handleLogout}
									collapseDeg={collapseDeg}
									setCollapseDeg={setCollapseDeg}
									collapseFilters={collapseFilters}
									setCollapseFilters={setCollapseFilters}
								/>
								<FleetPage {...{ param, changeNavType, navType, popUpControls, allFleet, van_img, truck_img, vehiclePopup, indPopUpControls, indVehiclePopup, damagePopup, logsPopup, searchControl }} />

								{vehiclePopup && <PopUpVehicle {...{ param, popUpPage, popUpControls, validationError, handleNewVehicleData, newVehicleData }} />}
								{indVehiclePopup && <PopUpIndVehicle {...{ param, indPopUpControls, indVehicle, indNav, changeIndNav, formatDate, searchControl }} />}
								{damagePopup && <PopUpDamage {...{ param, indPopUpControls, indVehicle, validationError, popUpPage, van_damage, truck_damage, trackClick, damageClicks, setDamageMenu, damageMenu, deleteClick, damageTicker, mouseMoved }} />}
								{logsPopup && <PopUpLogs {...{ param, popUpPage, popUpControls, indPopUpControls, validationError, handleNewLogData, newLogData, formatDate }} />}
							</div>
							: <Forbidden />
						}
					</>
				:
					<Loading current={"Fleet"} />
			}
		</>
	);
};


export default Fleet;