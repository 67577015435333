import styles from "./Warehouse.module.css"
import Select from 'react-select';

const PopUpRunSheet = ({ param, handleRunSheet, validationError, runDetails, allFleet, formatOptions }) => {
	return (
		<div className={styles.popup_container}>

			<button className={styles.closePopup} onClick={() => handleRunSheet('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Run details</h1>
			<p className={styles.smaller_font}>Please enter the specific run details to create.</p>

			<div className={styles.popup_form}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Driver name</label>
					<input type="text" name="driver_name" value={runDetails && runDetails.driver_name} placeholder="John" onChange={(input) => handleRunSheet("set", input)}/>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Vehicle registration</label>
					<Select
						options={formatOptions(allFleet.map(fleet => fleet.vehicle_rego))}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								boxShadow: 'none !important',
							   '&:hover': {
							       borderColor: '#fce7eb !important'
							    },
								borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
							}),
						}}
						onChange={(input) => handleRunSheet("set", input, "vehicle_rego")}
						value={runDetails && {label: runDetails["vehicle_rego"], value: runDetails["vehicle_rego"]}}
					/>
				</div>
			</div>



			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handleRunSheet('submit')}>Submit</button>	
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpRunSheet;