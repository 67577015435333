import styles from "./Fleet.module.css"

const PopUpIndVehicle = ({ param, indPopUpControls, indVehicle, indNav, changeIndNav, formatDate, searchControl }) => {
	return (
		<div className={styles.indsite_container}>
			<div className={`${styles.indsite_popup} ${styles.popup_moreheight}`}>

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
					<button className={styles.emailPopup} onClick={() => indPopUpControls('damage_open')}><i className="fa-solid fa-screwdriver-wrench"></i></button>
					<button className={styles.pdfPopup} onClick={() => indPopUpControls('delete', indVehicle)}><i className="fa-solid fa-trash-can"></i></button>

					<h1 className={styles.medium_font}>Vehicle details</h1>
					<p className={styles.smaller_font}>Please find the specific details for this vehicle here.</p>

					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndNav('Details')}><p>Details</p></button>
							<button className={`${styles.sitenav_box} ${indNav == 'Refuel logs' && styles.sitenav_active}`} onClick={() => changeIndNav('Refuel logs')}><p>Refuel logs</p></button>
							<button className={`${styles.sitenav_box} ${indNav == 'Drive logs' && styles.sitenav_active}`} onClick={() => changeIndNav('Drive logs')}><p>Drive logs</p></button>
						</div>
					</div>

					{indNav == "Details" &&
						<div className={styles.fleet_tablecontainer}>
							<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap} ${styles.indfleetgrid}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Vehicle rego</label>
									<p>{indVehicle.vehicle_rego}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Make & Model</label>
									<p>{indVehicle.make} {indVehicle.model}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Location</label>
									<p>{indVehicle.location}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Type</label>
									<p>{indVehicle.type}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Year</label>
									<p>{indVehicle.year}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Mileage</label>
									<p>{indVehicle.mileage}km</p>
								</div>
							</div>

							<div className={styles.map_outer}>
								<div id="map"></div>
							</div>
						</div>
					}



					{indNav == "Refuel logs" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => indPopUpControls('logs_open')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add log</p>
								</button>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th colspan="1">Driver</th>
								      	<th colspan="2">Amount refueled</th>
								      	<th colspan="2">Date of refuel</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
						    			{indVehicle.logs && indVehicle.logs.length > 0
						    				? 
						    				<>
							    				{indVehicle.logs.filter(IL => IL.type == "Refuel").map((VL) => {
							    					return (
												    	<tr className={styles.sitesind_tr}>
												    		<td colspan="1">{VL.driver}</td>
												    		<td colspan="2">{VL.amount_refueled}km (${VL.price})</td>
												    		<td colspan="2">{formatDate(VL.date_refueled, false)}</td>
												    	</tr>
											    	)
											    })}
										    </>
						    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This vehicle does not have any logs yet!</h1>
						    			}

								    </tbody>
								</table>
							</div>
						</div>
					}

					{indNav == "Drive logs" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => indPopUpControls('logs_open')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add log</p>
								</button>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th>Driver</th>
								      	<th>Amount driven</th>
								      	<th>Date of drive</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
						    			{indVehicle.logs && indVehicle.logs.length > 0
						    				? 
						    				<>
							    				{indVehicle.logs.filter(IL => IL.type == "Drive").map((VL) => {
							    					return (
												    	<tr className={styles.sitesind_tr}>
												    		<td>{VL.driver}</td>
												    		<td>{VL.end_mileage - VL.start_mileage}km</td>
												    		<td>{formatDate(VL.date_drove, false)}</td>
												    	</tr>
											    	)
											    })}
										    </>
						    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This vehicle does not have any logs yet!</h1>
						    			}

								    </tbody>
								</table>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default PopUpIndVehicle;