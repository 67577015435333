import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import ofglogo from '../../images/logo.png'
import citysvg from '../../images/city.svg'
import styles from "./ForgotPassword.module.css"


const ForgotPassword = () => {
	const [data, setData] = useState({ email: "" });
	const [validationError, setValidationError] = useState(null);

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (data['email'] === "") {
			setValidationError("Please enter an email.")
			return false;
		} else {
			if (!(isValidEmail(data['email']))) {
				setValidationError("Please enter a valid email address.")
				return false;
			}
		}

		await axios.post('/api/auth/forgot-password', {data: data})
		setValidationError(null)

		window.location = "/login";

	};

	return (

		<div className={styles.bg}>
			<div className={styles.container}>
			  <div className={styles.outerSquare}>
			    <a href="/login"><img className={styles.logo} src={ofglogo} /></a>
			    <div className={styles.leftSide}>
			      <h1>Forgot your password?</h1>
			      <p>Enter your email and if your account exists, we will send you an email with steps to follow!</p>
			      <form onSubmit={handleSubmit}>

			      	<div className={styles.formWrapper}>
				        <label htmlFor="email">
				          Email Address
				        </label>
				        <input
				          type="text"
				          name="email"
				          placeholder="johndoe@gmail.com"
				          onChange={handleChange}
				          value={data.email}
				        />
			        </div>

			        <div className={styles.controls}>
				        <button
				          className={styles.loginBtn}
				          type="submit"
				          style={{ cursor: "pointer" }}
				        >
				          Submit
				        </button>
			        </div>
 			      </form>
			      <div className={styles.followus}>
			        <p>follow us</p>
			        <div className={styles.socials}>
			          <a href="https://officefruit.com.au" target="_blank">
			            <p>website</p>
			          </a>
			          <a href="https://www.instagram.com/officefruitgroup" target="_blank">
			            <p>instagram</p>
			          </a>
			          <a href="https://www.facebook.com/officefruitgroup" target="_blank">
			            <p>facebook</p>
			          </a>
			        </div>
			      </div>

			      {validationError &&
			        <div className={`${styles.notification} ${styles.error}`}>
			          <p>{validationError}</p>
			        </div>
			      }

			    </div>
			    <div className={styles.rightSide}>
			      <img className={styles.citySvg} src={citysvg} />
			    </div>
			  </div>

		  </div>
		</div>


	);
};

export default ForgotPassword;