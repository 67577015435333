import ReactAutocomplete from "react-google-autocomplete";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import gmail1 from '../../../images/gmail1.jpg'
import gmail2 from '../../../images/gmail2.jpg'
import outlook1 from '../../../images/outlook1.jpg'
import outlook2 from '../../../images/outlook2.jpg'
import styles from "./Dashboard.module.css"


const PopUpInteractionHelp = ({ param, handleInteractionHelp, interactionNav }) => {
	return (
		<div className={`${styles.popup_container} ${styles.popup_maxheight} ${styles.popUpExtendedWidth}`}>

			<button className={styles.closePopup} onClick={() => handleInteractionHelp("toggle")}><i class="fa-solid fa-x"></i></button>
			<h1 className={styles.medium_font}>Email download</h1>
			<p className={`${styles.smaller_font} ${styles.holdLeft}`}>Follow this guide to learn how to download emails which can be uploaded and stored with this record.</p>

			<div className={`${styles.innersitenav}`}>
				<button className={`${styles.sitenav_box} ${interactionNav === "Gmail" && styles.sitenav_active}`} onClick={() => handleInteractionHelp("nav", "Gmail")}><p>Gmail</p></button>
				<button className={`${styles.sitenav_box} ${interactionNav === "Outlook" && styles.sitenav_active}`} onClick={() => handleInteractionHelp("nav", "Outlook")}><p>Outlook</p></button>
			</div>

			<div className={`${styles.notification} ${styles.greenWarningMessage}`}>
				<p className={styles.smaller_font}>After you have successfully downloaded, go back to upload the .eml file into the upload box. <span>You will be able to view these email records under the interaction record you are creating/editing.</span></p>
			</div>

			<div className={`${styles.popup_form} ${styles.extraRowGap}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Step 1</label>
					<div className={styles.helpImgContainer}>
						<img src={interactionNav === "Gmail" ? gmail1 : outlook1}/>
					</div>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Step 2</label>
					<div className={styles.helpImgContainer}>
						<img src={interactionNav === "Gmail" ? gmail2 : outlook2}/>
					</div>
				</div>
			</div>

		</div>
	)
}

export default PopUpInteractionHelp;