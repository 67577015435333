import Select from 'react-select';
import styles from "./Users.module.css"

import ReactAutocomplete from "react-google-autocomplete";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne');


const PopUpUser = ({ param, popUpPage, popUpControls, validationError, formatOptions, handleNewUserData, newUserData, formatDate }) => {
	return (
		<div className={styles.popup_container}>

			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>User details</h1>
					<p className={styles.smaller_font}>Please enter the specific user details to create.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>First name</label>
							<input type="text" placeholder="Jane" onChange={(selected) => handleNewUserData('first_name', selected)} value={newUserData && newUserData['first_name']}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Last name</label>
							<input type="text" placeholder="Doe" onChange={(selected) => handleNewUserData('last_name', selected)} value={newUserData && newUserData['last_name']}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Email</label>
							<input type="text" placeholder="jdoe@officefruit.com.au" onChange={(selected) => handleNewUserData('email', selected)} value={newUserData && newUserData['email']}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Date of birth</label>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									className={styles.overridebg}
									value={newUserData && newUserData.dob ? dayjs(newUserData.dob) : null}
									onChange={(selected) => handleNewUserData("dob", {value: selected})}
									format="DD/MM/YYYY"
								/>
							</LocalizationProvider>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Work anniversary</label>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									className={styles.overridebg}
									value={newUserData && newUserData.work_anniversary ? dayjs(newUserData.work_anniversary) : null}
									onChange={(selected) => handleNewUserData("work_anniversary", {value: selected})}
									format="DD/MM/YYYY"
								/>
							</LocalizationProvider>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Permissions</label>
							<Select
								isMulti={true}
								closeMenuOnSelect={true}
								options={[{label: "Customer Service", value: "Customer Service"}, {label: "Warehouse", value: "Warehouse"}, {label: "Fleet", value: "Fleet"}, {label: "Invoices", value: "Invoices"}, {label: "Reports", value: "Reports"}]}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewUserData('permissions', selected)}
								value={newUserData["permissions"]}
							/>
						</div>
					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

					{!newUserData['edit'] &&
						<div className={`${styles.notification} ${styles.warningMessage}`}>
							<p className={styles.smaller_font}>An email will be sent to {newUserData.email}, prompting them to complete the sign-up process.</p>
						</div>
					}

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>First name</label>
							<input type="text" value={newUserData.first_name} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Last name</label>
							<input type="text" value={newUserData.last_name} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Email</label>
							<input type="text" value={newUserData.email} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Date of birth</label>
							<input type="text" value={formatDate(newUserData.dob)} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Work anniversary</label>
							<input type="text" value={formatDate(newUserData.work_anniversary)} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Permissions</label>
							<input type="text" value={newUserData.permissions.map(IP => IP.value).join(', ')} disabled={true}/>
						</div>

					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 2 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 2 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpUser;