import React, { Component } from "react";
import styles from "./Dashboard.module.css"

export default class ClockModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date()
    };
  }

  componentDidMount() {
    this.timerId = setInterval(() => {
      this.setState({
        time: new Date()
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  render() {
    const hours = this.state.time.getHours();
    const minutes = this.state.time.getMinutes();
    const seconds = this.state.time.getSeconds();

    return (
      <div className={styles.clock}>
        <div
          className={styles.hour_hand}
          style={{
            transform: `rotateZ(${(hours % 12) * 30 + minutes / 2}deg)`
          }}
        />
        <div
          className={styles.min_hand}
          style={{
            transform: `rotateZ(${minutes * 6}deg)`
          }}
        />
        <div
          className={styles.sec_hand}
          style={{
            transform: `rotateZ(${seconds * 6}deg)`
          }}
        />
        <span className={styles.twelve}>12</span>
        <span className={styles.one}>1</span>
        <span className={styles.two}>2</span>
        <span className={styles.three}>3</span>
        <span className={styles.four}>4</span>
        <span className={styles.five}>5</span>
        <span className={styles.six}>6</span>
        <span className={styles.seven}>7</span>
        <span className={styles.eight}>8</span>
        <span className={styles.nine}>9</span>
        <span className={styles.ten}>10</span>
        <span className={styles.eleven}>11</span>
      </div>
    );
  }
}
