import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import styles from "./Dashboard.module.css"

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpTempCancel = ({ param, handleTempCancel, validationError, tempCancelData }) => {
	return (
		<div className={`${styles.popup_container} ${styles.popup_maxwidth}`}>
			<button className={styles.closePopup} onClick={() => handleTempCancel('toggle')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Temporary Cancel</h1>
			<p className={styles.smaller_font}>Select a date range to temporarily cancel a preference.</p>

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Date range</label>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
					    <DateRangePicker
					        className={styles.overridebg}
					        localeText={{ start: '', end: '' }}
					        value={tempCancelData.dates && tempCancelData.dates}
					        onChange={(selected) => handleTempCancel('set', selected)}
					        disablePast
					        format="DD/MM/YYYY"
					    />
					</LocalizationProvider>
				</div>
			</div>

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handleTempCancel('submit')}>Submit</button>
				</div>
			</div>
		</div>
	)
}

export default PopUpTempCancel;