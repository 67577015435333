import { ResponsivePie } from '@nivo/pie'

const PieChart = ({ pieChartData }) => {
    return (
        <ResponsivePie
            data={pieChartData}
            margin={{ top: 30, right: -40, bottom: 30, left: 120 }}
            innerRadius={0.5}
            padAngle={2}
            cornerRadius={2}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'brighter',
                        0.2
                    ]
                ]
            }}
            arcLabel={null}
            enableArcLinkLabels={false}
            legends={[
                {
                    anchor: 'left',
                    direction: 'column',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ],
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemWidth: 100,
                    symbolSize: 18,
                    toggleSerie: true,
                    translateX: -110,
                    itemsSpacing: 5
                }
            ]}

        />
    )
}

export default PieChart;