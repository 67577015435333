import Select from 'react-select';
import styles from "./Users.module.css"

import ReactAutocomplete from "react-google-autocomplete";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne');


const PopUpDrivers = ({ param, popUpPage, popUpControls, validationError, formatOptions, formatDate, handleNewDriverData, newDriverData }) => {
	return (
		<div className={styles.popup_container}>

			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Driver details</h1>
					<p className={styles.smaller_font}>Please enter the specific driver details to create.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>First name</label>
							<input type="text" placeholder="Jane" onChange={(selected) => handleNewDriverData('first_name', selected)} value={newDriverData && newDriverData['first_name']}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Last name</label>
							<input type="text" placeholder="Doe" onChange={(selected) => handleNewDriverData('last_name', selected)} value={newDriverData && newDriverData['last_name']}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Phone number</label>
							<input type="text" placeholder="0449 328 987" onChange={(selected) => handleNewDriverData('phone_number', selected)} value={newDriverData && newDriverData['phone_number']}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Date of birth</label>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									className={styles.overridebg}
									value={newDriverData && newDriverData.dob ? dayjs(newDriverData.dob) : null}
									onChange={(selected) => handleNewDriverData("dob", {value: selected})}
									format="DD/MM/YYYY"
								/>
							</LocalizationProvider>
						</div>
					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Driver details</h1>
					<p className={styles.smaller_font}>Please enter the specific driver details to create.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>License number</label>
							<input type="text" placeholder="031813259" onChange={(selected) => handleNewDriverData('license_number', selected)} value={newDriverData && newDriverData['license_number']}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>License expiration</label>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									className={styles.overridebg}
									value={newDriverData && newDriverData.license_expiration ? dayjs(newDriverData.license_expiration) : null}
									onChange={(selected) => handleNewDriverData("license_expiration", {value: selected})}
									format="DD/MM/YYYY"
								/>
							</LocalizationProvider>
						</div>
					</div>
				</>
			}

			{popUpPage == 3 &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>First name</label>
							<input type="text" value={newDriverData.first_name} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Last name</label>
							<input type="text" value={newDriverData.last_name} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Phone number</label>
							<input type="text" value={newDriverData.phone_number} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Date of birth</label>
							<input type="text" value={formatDate(newDriverData.dob)} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>License number</label>
							<input type="text" value={newDriverData.license_number} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>License expiration</label>
							<input type="text" value={formatDate(newDriverData.license_expiration)} disabled={true}/>
						</div>

					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 3 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 3 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpDrivers;