import Select from 'react-select';
import styles from "./Dashboard.module.css"


const PopUpCustomPricing = ({ param, handleCustomPricing, tierNav, siteFruitTiers, siteProcalTiers, inventoryData, formatOptions, validationError, tierControls }) => {
	return (
		<div className={`${styles.fixed_container_height}`}>
			<button className={styles.closePopup} onClick={() => handleCustomPricing('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Pricing details</h1>
			<p className={styles.smaller_font}>Please enter the specific custom pricing details to create.</p>

			<div className={styles.indsite_nav}>
				<div className={styles.sitenav}>
					<button className={`${styles.sitenav_box} ${tierNav === 'Fruit' && styles.sitenav_active}`} onClick={() => handleCustomPricing('nav', 'Fruit')}><p>Fruit</p></button>
					<button className={`${styles.sitenav_box} ${tierNav === 'Milk' && styles.sitenav_active}`} onClick={() => handleCustomPricing('nav', 'Milk')}><p>Milk</p></button>
				</div>
			</div>

			{tierNav === 'Fruit' &&
				<div className={styles.draggableContainerBulk}>
					{siteFruitTiers.map((FT, index) => (
						<div className={`${styles.popup_form_fruitcustom}`}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Fruit price</label>
								<input type="text" value={FT.breakdown} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Per/pc</label>
								<input
									type="text"
									value={FT.pc_price}
									onChange={(input) => tierControls('fruit', 'set', index, 'pc_price', input)}
								/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Flexi</label>
								<input
									type="text"
									value={FT.flexi}
									onChange={(input) => tierControls('fruit', 'set', index, 'flexi', input)}
								/>
							</div>
						</div>
					))}
				</div>
			}

			{tierNav === 'Milk' &&
				<div className={styles.draggableContainerBulk}>
					{siteProcalTiers.map((PT, index) => (
						<div className={`${styles.popup_form_custom}`}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Product</label>
								<Select
									closeMenuOnSelect={true}
									options={formatOptions(inventoryData.map(AI => AI.item_name).filter(item => !(siteProcalTiers.map(procal => procal.item).includes(item))))}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											boxShadow: 'none !important',
										   '&:hover': {
										       borderColor: '#fce7eb !important'
										    },
											borderColor: state.isFocused ? '#fce7eb' : '#fce7eb'
										}),
									}}
									onChange={(selected) => tierControls('milk', 'set', index, 'item', selected)}
									value={{label: PT.item, value: PT.item}}
								/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>9-20L</label>
								<input
									type="text"
									value={PT.tier1}
									onChange={(input) => tierControls('milk', 'set', index, 'tier1', input)}
								/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>21-30L</label>
								<input
									type="text"
									value={PT.tier2}
									onChange={(input) => tierControls('milk', 'set', index, 'tier2', input)}
								/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>31-60L</label>
								<input
									type="text"
									value={PT.tier3}
									onChange={(input) => tierControls('milk', 'set', index, 'tier3', input)}
								/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>61-100L</label>
								<input
									type="text"
									value={PT.tier4}
									onChange={(input) => tierControls('milk', 'set', index, 'tier4', input)}
								/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>100L+</label>
								<input
									type="text"
									value={PT.tier5}
									onChange={(input) => tierControls('milk', 'set', index, 'tier5', input)}
								/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Tools</label>
								<div className={styles.form_controls}>
									<button onClick={() => tierControls('milk', 'delete', index)}><i className={`fa-solid fa-trash-can ${styles.medium_font} ${styles.ml_7}`}></i></button>
								</div>
							</div>
						</div>
					))}

					<div className={styles.menuControls}>
						<button type="button" className={styles.addBtn} onClick={() => tierControls('milk', 'add')}>
							<i className="fa-solid fa-plus"></i>
						</button>
					</div>
				</div>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		<button type="button" className={styles.submitBtn} onClick={() => tierControls(tierNav === 'Milk' ? 'milk' : 'fruit', 'submit')}>Submit</button>
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpCustomPricing;