import ReactAutocomplete from "react-google-autocomplete";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import styles from "./Dashboard.module.css"

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne');


const PopUpInteraction = ({ param, popUpPage, popUpControls, validationError, formatOptions, handleNewInteractionData, newInteractionData, allAccounts, sitesAvailable, formatDate, emailFile, handleInteractionHelp }) => {
	return (
		<div className={`${styles.popup_container} ${styles.popup_maxheight}`}>

			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Interaction details</h1>
					<p className={styles.smaller_font}>Please enter the specific interaction details to create.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Account</label>
							<Select
								closeMenuOnSelect={true}
								options={formatOptions(allAccounts.filter(acc => acc.role == "Account" && acc.status == "Active").map(c => c.company))}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewInteractionData("account", selected)}
								value={{label: newInteractionData["account"], value: newInteractionData["account"]}}
							/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site</label>
							<Select
								closeMenuOnSelect={true}
								options={formatOptions(sitesAvailable.map(site => site.site_name))}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewInteractionData("site", selected)}
								value={{label: newInteractionData["site"], value: newInteractionData["site"]}}
							/>
						</div>


						<div className={styles.popup_form_flex_rows}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>First name</label>
								<input type="text" placeholder="Jane" onChange={(input) => handleNewInteractionData('first_name', input)} value={newInteractionData['first_name']}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Last name</label>
								<input type="text" placeholder="Doe" onChange={(input) => handleNewInteractionData('last_name', input)} value={newInteractionData['last_name']}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" placeholder="jdoe@seek.com.au" onChange={(input) => handleNewInteractionData('email', input)} value={newInteractionData['email']}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" placeholder="+61 442 323 432" onChange={(input) => handleNewInteractionData('phone', input)} value={newInteractionData['phone']}/>
							</div>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Comment</label>
							<textarea placeholder="Interaction comment..." onChange={(input) => handleNewInteractionData('comment', input)} value={newInteractionData['comment']}></textarea>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Follow up date</label>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									className={styles.overridebg}
									value={newInteractionData && newInteractionData.follow_up_date ? dayjs(newInteractionData.follow_up_date) : null}
									onChange={(selected) => handleNewInteractionData("follow_up_date", {value: selected})}
									format="DD/MM/YYYY"
								/>
							</LocalizationProvider>
						</div>

						<div className={styles.popup_formbox}>
							<div className={styles.dualLabels}>
								<label className={styles.smaller_font}>Upload Email</label>
								<button className={styles.help_icon} onClick={() => handleInteractionHelp("toggle")}><p className={`${styles.smaller_font}`}>What is this?</p></button>
							</div>

							<input
								id="fileInputEmail"
								className={styles.fileInputEmail}
								type="file"
								accept=".eml"
								onChange={(file) => handleNewInteractionData("upload_email", {value: file})}
							/>
							<label for="fileInputEmail" className={styles.fileInputTag}>
								<i class="fa-solid fa-upload"></i>
								<p>{emailFile ? "Uploaded!" : "Click to upload"}</p>
							</label>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Tags</label>
							<Select
								isMulti={true}
								options={["Quality", "Packing", "Arrival Time", "Variety", "Praise", "Cancellation", "New Customer", "Increase Order", "One Off Order", "Decrease Order", "Driver Error", "Admin Error", "Christmas Cherries Orders", "Christmas Closure", "Other"].map(item => { return { label: item, value: item } })}
								closeMenuOnSelect={true}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewInteractionData("tags", selected)}
								value={newInteractionData["tags"]}
							/>
						</div>

					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Account</label>
							<input type="text" value={newInteractionData['account']} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site</label>
							<input type="text" value={newInteractionData['site']} disabled={true}/>
						</div>

						<div className={styles.popup_form_flex_rows}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>First name</label>
								<input type="text" value={newInteractionData['first_name']} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Last name</label>
								<input type="text" value={newInteractionData['last_name']} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" value={newInteractionData['email']} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" value={newInteractionData['phone']} disabled={true}/>
							</div>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Comment</label>
							<textarea value={newInteractionData['comment']} disabled={true}></textarea>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Follow up date</label>
							<input type="text" value={formatDate(newInteractionData['follow_up_date'])} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Upload Email</label>
							<input
								type="text"
								disabled={true}
								value={emailFile ? "Uploaded" : "No"}
							/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Tags</label>
							<CreatableSelect
								isMulti={true}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								value={newInteractionData["tags"]}
								isDisabled={true}
							/>
						</div>
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 2 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 2 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpInteraction;