import Select from 'react-select';
import styles from "./Invoices.module.css"

import invoice_all from '../../../images/invoice_all.jpg'
import invoice_selected from '../../../images/invoice_selected.jpg'
import recurring_img from '../../../images/recurring.jpg'


const PopUpTiers = ({ param, popUpPage, popUpControls, validationError, handleNewInvoiceData, newInvoiceData, formatOptions, allSites, invoiceOrders, calculateTotalAmount, popupNav, changePopupNav, procalTiers, allInventory, tierControls, fruitTiers, handleMainTier, generalTierData }) => {
	return (
		<div className={`${popUpPage === 1 ? styles.popup_container : styles.fixed_container_height}`}>
			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage === 1 &&
				<>
					<h1 className={styles.medium_font}>Tier details</h1>
					<p className={styles.smaller_font}>Please select the type of tier you would like to edit.</p>

					<div className={`${styles.popup_form}`}>
						<button className={`${styles.order_typebox} ${generalTierData.condition && generalTierData.condition == "general" ? styles.order_typeboxactive : ""}`} onClick={() => handleMainTier('set', 'condition', 'general')}>
							<div className={styles.order_imgbox}>
								<img src={invoice_all}/>
							</div>

							<div className={styles.order_contentbox}>
								<h1 className={styles.smaller_font}>General tiers</h1>
								<p className={styles.smaller_font}>Manage the tiered pricing for all customers.</p>
							</div>
						</button>

						<button className={`${styles.order_typebox} ${generalTierData.condition && generalTierData.condition == "yordar" ? styles.order_typeboxactive : ""}`} onClick={() => handleMainTier('set', 'condition', 'yordar')}>
							<div className={styles.order_imgbox}>
								<img src={invoice_selected}/>
							</div>

							<div className={styles.order_contentbox}>
								<h1 className={styles.smaller_font}>Yordar tiers</h1>
								<p className={styles.smaller_font}>Manage the tiered pricing for all Yordar orders.</p>
							</div>
						</button>

						<button className={`${styles.order_typebox} ${generalTierData.condition && generalTierData.condition == "eatfirst" ? styles.order_typeboxactive : ""}`} onClick={() => handleMainTier('set', 'condition', 'eatfirst')}>
							<div className={styles.order_imgbox}>
								<img src={recurring_img}/>
							</div>

							<div className={styles.order_contentbox}>
								<h1 className={styles.smaller_font}>EatFirst tiers</h1>
								<p className={styles.smaller_font}>Manage the tiered pricing for all EatFirst orders.</p>
							</div>
						</button>
					</div>
				</>
			}

			{popUpPage === 2 &&
				<>
					<h1 className={styles.medium_font}>Tier details</h1>
					<p className={styles.smaller_font}>Please enter the specific tier details to edit.</p>

					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${popupNav === 'Fruit' && styles.sitenav_active}`} onClick={() => changePopupNav('Fruit')}><p>Fruit</p></button>
							<button className={`${styles.sitenav_box} ${popupNav === 'Milk' && styles.sitenav_active}`} onClick={() => changePopupNav('Milk')}><p>Milk</p></button>
						</div>
					</div>

					{popupNav === 'Fruit' &&
						<div className={styles.draggableContainerBulk}>
							{fruitTiers.map((FT, index) => (
								<div className={`${styles.popup_form_fruitcustom}`}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Fruit price</label>
										<input type="text" value={FT.breakdown} disabled={true}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Per/pc</label>
										<input type="text" value={FT.pc_price} onChange={(input) => tierControls('fruit', 'set', index, 'pc_price', input)}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Flexi</label>
										<input type="text" value={FT.flexi} onChange={(input) => tierControls('fruit', 'set', index, 'flexi', input)}/>
									</div>
								</div>
							))}
						</div>
					}

					{popupNav === 'Milk' &&
						<div className={styles.draggableContainerBulk}>
							{procalTiers.map((PT, index) => (
								<div className={`${styles.popup_form_custom}`}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Product</label>
										<Select
											closeMenuOnSelect={true}
											options={formatOptions(allInventory.map(AI => AI.item_name).filter(item => !(procalTiers.map(procal => procal.item).includes(item))))}
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													boxShadow: 'none !important',
												   '&:hover': {
												       borderColor: '#fce7eb !important'
												    },
													borderColor: state.isFocused ? '#fce7eb' : '#fce7eb'
												}),
											}}
											onChange={(selected) => tierControls('milk', 'set', index, 'item', selected)}
											value={{label: PT.item, value: PT.item}}
										/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>9-20L</label>
										<input type="text" value={PT.tier1} onChange={(input) => tierControls('milk', 'set', index, 'tier1', input)}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>21-30L</label>
										<input type="text" value={PT.tier2} onChange={(input) => tierControls('milk', 'set', index, 'tier2', input)}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>31-60L</label>
										<input type="text" value={PT.tier3} onChange={(input) => tierControls('milk', 'set', index, 'tier3', input)}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>61-100L</label>
										<input type="text" value={PT.tier4} onChange={(input) => tierControls('milk', 'set', index, 'tier4', input)}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>100L+</label>
										<input type="text" value={PT.tier5} onChange={(input) => tierControls('milk', 'set', index, 'tier5', input)}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Tools</label>
										<div className={styles.form_controls}>
											<button onClick={() => tierControls('milk', 'delete', index)}><i className={`fa-solid fa-trash-can ${styles.medium_font} ${styles.ml_7}`}></i></button>
										</div>
									</div>
								</div>
							))}
						

							<div className={styles.menuControls}>
								<button type="button" className={styles.addBtn} onClick={() => tierControls('milk', 'add')}>
									<i className="fa-solid fa-plus"></i>
								</button>
							</div>
						</div>
					}
				</>
			}


			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => handleMainTier('prev')}>Back</button>
		      		}

		      		{popUpPage != 2 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => handleMainTier('next')}>Next</button>
		      		}

		      		{popUpPage == 2 &&
					    <button type="button" className={styles.submitBtn} onClick={() => tierControls(popupNav === 'Milk' ? 'milk' : 'fruit', 'submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpTiers;