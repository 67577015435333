import Select from 'react-select';
import styles from "./Fleet.module.css"

import ReactAutocomplete from "react-google-autocomplete";

const PopUpVehicle = ({ param, popUpPage, popUpControls, validationError, handleNewVehicleData, newVehicleData }) => {
	return (
		<div className={styles.popup_container}>

			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Vehicle details</h1>
					<p className={styles.smaller_font}>Please enter the specific vehicle details to create.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Type</label>
							<Select
								closeMenuOnSelect={true}
								options={[{label: "Van", value: "Van"}, {label: "Truck", value: "Truck"}]}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewVehicleData('type', selected)}
								value={{label: newVehicleData["type"], value: newVehicleData["type"]}}
							/>
						</div>

						
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Vehicle registration</label>
							<input type="text" placeholder="XLA 321" onChange={(selected) => handleNewVehicleData('vehicle_rego', selected)} value={newVehicleData["vehicle_rego"]}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Make</label>
							<input type="text" placeholder="Toyota" onChange={(selected) => handleNewVehicleData('make', selected)} value={newVehicleData["make"]}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Model</label>
							<input type="text" placeholder="Hiace" onChange={(selected) => handleNewVehicleData('model', selected)} value={newVehicleData["model"]}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Year</label>
							<input type="text" placeholder="2023" onChange={(selected) => handleNewVehicleData('year', selected)} value={newVehicleData["year"]}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Mileage (km)</label>
							<input type="text" placeholder="10000" onChange={(selected) => handleNewVehicleData('mileage', selected)} value={newVehicleData["mileage"]}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Location</label>
							<Select
								closeMenuOnSelect={true}
								options={[{label: "VIC", value: "VIC"}, {label: "NSW", value: "NSW"}]}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewVehicleData('location', selected)}
								value={{label: newVehicleData["location"], value: newVehicleData["location"]}}
							/>
						</div>
					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Type</label>
							<input type="text" value={newVehicleData["type"] && newVehicleData["type"]} disabled={true}/>
						</div>


						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Vehicle registration</label>
							<input type="text" value={newVehicleData["vehicle_rego"] && newVehicleData["vehicle_rego"]} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Make</label>
							<input type="text" value={newVehicleData["make"] && newVehicleData["make"]} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Model</label>
							<input type="text" value={newVehicleData["model"] && newVehicleData["model"]} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Year</label>
							<input type="text" value={newVehicleData["year"] && newVehicleData["year"]} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Mileage (km)</label>
							<input type="text" value={newVehicleData["mileage"] && newVehicleData["mileage"]} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Location</label>
							<input type="text" value={newVehicleData["location"] && newVehicleData["location"]} disabled={true}/>
						</div>
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 2 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 2 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpVehicle;