import styles from "./Dashboard.module.css"
import ResizableDiv from '../Utils/ResizableDiv';


const PopUpIndOrder = ({ param, indPopUpControls, allOrders, indOrder, convertToDay, formatDate, inventoryData, changeIndSiteNav, indSiteNav, changeStatus, changingStatus, searchControl, boxActive, boxLoops, adjustToPreviousDayNoonAEST }) => {
	return (
		<div className={styles.indsite_container}>
			<ResizableDiv className="indsite_popup" startingWidth="40">

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => indPopUpControls('edit')} disabled={adjustToPreviousDayNoonAEST(indOrder.delivery_date)}><i className="fa-solid fa-pen"></i></button>
					<button className={styles.nextPopup} onClick={() => indPopUpControls('delete', indOrder)} disabled={adjustToPreviousDayNoonAEST(indOrder.delivery_date)}><i className="fa-solid fa-trash-can"></i></button>

					<h1 className={styles.medium_font}>Order {indSiteNav.toLowerCase()}</h1>
					<p className={styles.smaller_font}>Please find the specific {indSiteNav.toLowerCase()} for your order here.</p>


					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Details')}><p>Details</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Items' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Items')}><p>Items</p></button>
						</div>
					</div>

					{indSiteNav == "Details" &&
						<div className={`${styles.sites_tablecontainer} ${changingStatus && styles.hidden_container}`}>
							<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Order code</label>
									<p>{indOrder.order_code}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Order type</label>
									{indOrder.recurring_temp
										? <a onClick={() => indPopUpControls('PrefLink', indOrder.preference_link)} className={styles.clickable_nav}><p>Temporary</p></a>
										: indOrder.recurring
											? <p>Ongoing</p>
											: <p>One-time</p>
									}
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Company</label>
									<a onClick={() => indPopUpControls('navigate', 'Account')} className={styles.clickable_nav}><p>{indOrder.company}</p></a>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Site name</label>
									<a onClick={() => indPopUpControls('navigate', 'Site')} className={styles.clickable_nav}><p>{indOrder.site_name}</p></a>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Address</label>
									<a className={styles.clickable_nav} href={`http://maps.google.com/?q=${indOrder.site_location.hasOwnProperty('level') && `Level ${indOrder.site_location.level}, `}, ${indOrder.site_location.street}, ${indOrder.site_location.city} ${indOrder.site_location.state} ${indOrder.site_location.postcode}, ${indOrder.site_location.country}`} target="_blank"><p>{indOrder.site_location.hasOwnProperty('level') && `Level ${indOrder.site_location.level}, `}{indOrder.site_location.street}, {indOrder.site_location.city} {indOrder.site_location.state} {indOrder.site_location.postcode}, {indOrder.site_location.country}</p></a>
								</div>

								{/* FIND BETTER SOLUTION WITHOUT INLINE */}
								<div className={styles.popup_formbox} style={{position: 'relative'}}>
									<div style={{position: 'absolute'}}>
										<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
											<label className={styles.smaller_font}>Status</label>
							    			<button onClick={() => changeStatus('toggle')} className={`${styles.site_status} ${indOrder.status == "Pending" && styles.status_pending} ${(indOrder.status == "Active" || indOrder.status == "Completed") && styles.status_active} ${indOrder.status == "Inactive" && styles.status_inactive}`}>
							    				<p className={styles.smaller_font}>{indOrder.status}</p>
							    			</button>
						    			</div>
					    			</div>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Delivery day</label>
									<p>{convertToDay(indOrder.delivery_date)}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Delivery date</label>
									<p>{formatDate(indOrder.delivery_date, false)}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Product</label>
									<p>{indOrder.order_type}</p>
								</div>

								{indOrder['box_type'] &&
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Box type</label>
										<p>{indOrder.box_type}</p>
									</div>
								}

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Total quantity</label>
									<p>{indOrder.total_qty} items</p>
								</div>

								{indOrder.hasOwnProperty('price') &&
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Price</label>
										<p>${indOrder.price} {indOrder.credit_used && "(Credit Used)"}</p>
									</div>
								}
							</div>
						</div>
					}

	    			{changingStatus &&
	    				<div className={styles.statusOptions}>
	    					<button className={`${styles.closePopup} ${styles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

							<h1 className={styles.medium_font}>Change status</h1>
							<p className={`${styles.smaller_font}`}>Use this module to update the status for {indOrder.order_code}</p>

							<div className={styles.statusOuter}>
								<div className={styles.statusInner}>
			    					{indOrder.status !== "Pending" &&
						    			<button onClick={() => changeStatus('set', 'Order', indOrder, 'Pending')} className={`${styles.site_status} ${styles.status_pending}`}>
						    				<p className={styles.smaller_font}>Pending</p>
						    			</button>
					    			}

					    			{indOrder.status !== "Active" &&
						    			<button onClick={() => changeStatus('set', 'Order', indOrder, 'Active')} className={`${styles.site_status} ${styles.status_active}`}>
						    				<p className={styles.smaller_font}>Active</p>
						    			</button>
						    		}
					    		</div>
				    		</div>
	    				</div>
	    			}

					{indSiteNav == "Items" &&
						<div className={`${styles.sites_tablecontainer} ${(indOrder && indOrder.items.reduce((count, item) => count + Array.isArray(item), 0) > 0) && styles.indorder_seasonal}`}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>
							</div>

							{(indOrder && indOrder.items.reduce((count, item) => count + Array.isArray(item), 0) > 0) &&
								<div className={styles.box_layout}>
				    				{indOrder.items.map((item, index) => {
				    					return (
							    			<button onClick={() => indPopUpControls('handleBox', index)} className={`${styles.site_status} ${boxActive === index ? styles.box_active : styles.status_active}`}>
							    				<p className={styles.smaller_font}>Box {index+1}</p>
							    			</button>
								    	)
								    })}
								</div>
							}

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th>Item name</th>
								      	<th>Quantity</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{(indOrder['box_type'] === "Seasonal" || indOrder['box_type'] === "Flexi")
								    	?
								    		<>
								    			{indOrder.items && indOrder.items.length > 0
								    				? 
								    				<>
									    				{indOrder.items[boxActive].map((II) => {
									    					return (
														    	<tr className={styles.sitesind_tr}>
														    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
														    		<td>{II.quantity} items</td>
														    	</tr>
													    	)
													    })}

												    	<tr className={styles.sitesind_tr}>
												    		<td>Total Quantity</td>
												    		<td>{indOrder.items[boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
												    	</tr>
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
								    			}
								    		</>
								    	:
								    		<>
								    			{indOrder.items && indOrder.items.length > 0
								    				? 
								    				<>
									    				{indOrder.items.map((II) => {
									    					return (
														    	<tr className={styles.sitesind_tr}>
														    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
														    		<td>{II.quantity} items</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This order does not have any items.</h1>
								    			}
								    		</>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}

				</div>
			</ResizableDiv>
		</div>
	)
}

export default PopUpIndOrder;