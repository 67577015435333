import { useEffect, useState, useCallback, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import Clock from 'react-live-clock';
import Select from 'react-select';
import axios from "axios";
import 'animate.css';
import Webcam from 'react-webcam';

import ClockModule from "./Clock";
import Forbidden from '../Forbidden';
import Loading from '../../Admin/Loading';
import ofglogo from '../../../images/logo.png'
import styles from "./Dashboard.module.css"


const DashboardPage = ({ param, driverSelected, handleClock, allDrivers, formatOptions, reloadApp, isSelectedBirthday, formatDate, showKeypad, submittingCode }) => {
	const { width, height } = useWindowSize()

	return (
		<div className={`${styles.page_container} ${showKeypad && styles.hidden_container}`}>
			{isSelectedBirthday &&
			    <Confetti
			      width={width}
			      height={height}
			    />
		    }

			<button className={styles.logoClock} onClick={() => reloadApp()} disabled={submittingCode}>
				<img src={ofglogo}/>
			</button>

			<div className={`${styles.inner_container}`}>
				<div className={styles.clockWidget}>
					<ClockModule/>
					<div className={styles.timeWidget}>
						<Clock format={'Do MMMM, YYYY'} className={styles.dateFormat}/>
						<Clock format={'hh:mm:ss a'} ticking={true} className={styles.timeFormat}/>
					</div>
				</div>

				<div className={styles.clockControls}>
					<div className={`${styles.popup_form_clock} ${styles.clockSelect}`}>
						<div className={`${styles.popup_formbox}`}>
							<label className={styles.smaller_font}>Employee name</label>
							<Select
								closeMenuOnSelect={true}
								options={formatOptions(allDrivers)}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
									option: (provided, state) => ({
										...provided,
										padding: '15px 10px',
									}),
								}}
								onChange={(selected) => handleClock("set", selected)}
								value={driverSelected && {label: driverSelected.label, value: driverSelected.label}}
								isSearchable={false}
							/>
						</div>
					</div>

					{driverSelected
						? 
							<>
								{allDrivers.filter(driver => driver._id.toString() === driverSelected.value.toString())[0].hasOpenShift
									? <button className={styles.clockButton} onClick={() => handleClock('init_submit')}><p>Clock Off</p></button>
									: <button className={styles.clockButton} onClick={() => handleClock('init_submit')}><p>Clock On</p></button>
								}
							</>
						: <button disabled={true} className={styles.clockButton} onClick={() => handleClock('init_submit')}><p>Clock On</p></button>
					}
				</div>
			</div>
		</div>
	)
}

const KeypadPage = ({ handleKeypad, keypadEntry, submittingCode, codeError, webcamRef, allDrivers, driverSelected }) => {
	return (
		<div className={`${styles.keypad_container} ${codeError && styles.invalid_password}`}>
			<div className={styles.cameraModule}>
				<Webcam
					audio={false}
					ref={webcamRef}
					screenshotFormat="image/jpeg"
					videoConstraints={{ facingMode: 'user' }}
					mirrored={true}
					style={{ width: '100%', height: '100%', position: 'absolute' }}
				/>
			</div>

			<div className={styles.keypad_content}>
				<p>{`${allDrivers.filter(driver => driver._id.toString() === driverSelected.value.toString())[0].hasOpenShift ? "Goodbye, " : "Welcome, "} ${allDrivers.filter(driver => driver._id.toString() === driverSelected.value.toString())[0].first_name} ${allDrivers.filter(driver => driver._id.toString() === driverSelected.value.toString())[0].last_name}`}</p>

				<button className={styles.keypad_close} disabled={submittingCode} onClick={() => handleKeypad('close')}><i class="fa-solid fa-x"></i></button>
			</div>

			<div className={styles.keypad_entry_container}>
				<div className={styles.keypad_entry}><p className={styles.keypad_code}>{keypadEntry.length > 0 && keypadEntry[0]}</p></div>
				<div className={styles.keypad_entry}><p className={styles.keypad_code}>{keypadEntry.length > 1 && keypadEntry[1]}</p></div>
				<div className={styles.keypad_entry}><p className={styles.keypad_code}>{keypadEntry.length > 2 && keypadEntry[2]}</p></div>
				<div className={styles.keypad_entry}><p className={styles.keypad_code}>{keypadEntry.length > 3 && keypadEntry[3]}</p></div>
			</div>

			<div className={styles.keypad_grid}>
				<button className={styles.keypad_num} disabled={submittingCode} onClick={() => handleKeypad("set", 1)}>1</button>
				<button className={styles.keypad_num} disabled={submittingCode} onClick={() => handleKeypad("set", 2)}>2</button>
				<button className={styles.keypad_num} disabled={submittingCode} onClick={() => handleKeypad("set", 3)}>3</button>
				<button className={styles.keypad_num} disabled={submittingCode} onClick={() => handleKeypad("set", 4)}>4</button>
				<button className={styles.keypad_num} disabled={submittingCode} onClick={() => handleKeypad("set", 5)}>5</button>
				<button className={styles.keypad_num} disabled={submittingCode} onClick={() => handleKeypad("set", 6)}>6</button>
				<button className={styles.keypad_num} disabled={submittingCode} onClick={() => handleKeypad("set", 7)}>7</button>
				<button className={styles.keypad_num} disabled={submittingCode} onClick={() => handleKeypad("set", 8)}>8</button>
				<button className={styles.keypad_num} disabled={submittingCode} onClick={() => handleKeypad("set", 9)}>9</button>
				<button className={`${styles.keypad_num} ${styles.keypad_0}`} disabled={submittingCode} onClick={() => handleKeypad("set", 0)}>0</button>
				<button className={`${styles.keypad_num} ${styles.keypad_back}`} disabled={submittingCode || keypadEntry.length === 0} onClick={() => handleKeypad("back")}><i className="fa-solid fa-delete-left"></i></button>
			</div>
		</div>
	)
}


const Dashboard = () => {
	const [token, setToken] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [allDrivers, setAllDrivers] = useState([])
	const [driverSelected, setDriverSelected] = useState(null)
	const [isSelectedBirthday, setIsSelectedBirthday] = useState(false)
	const [showKeypad, setShowKeypad] = useState(false)
	const [keypadEntry, setKeypadEntry] = useState([])
	const [submittingCode, setSubmittingCode] = useState(false)
	const [codeError, setCodeError] = useState(false);
	const webcamRef = useRef(null);

	const param = useParams();
	const url = `/api/clockon`;


	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					await fetchTokenAndData()
					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}

				setIsLoading(false)
			} catch (error) {
				console.log(error)
			}
		};
		initLoad();
	}, [param]);


    const fetchTokenAndData = async () => {
        const response = await axios.post(`${url}/generate-token`);
        setToken(response.data.token);

        const driver_response = await axios.get(`${url}/pull-drivers`, {
            headers: { 'Authorization': response.data.token },
        })

        setAllDrivers(driver_response.data.allDrivers)
    };

	const formatDate = (dateString, showDay, showTime) => {
	    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	    const dateObj = new Date(dateString);

	    if (!showTime) {
	    	dateObj.setHours(0, 0, 0, 0);
	    }

	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);

	    const currentDayNumber = dateObj.getDay();

	    if (showDay && showTime) {
	        let shours = ('0' + dateObj.getHours()).slice(-2);
	        let sminutes = ('0' + dateObj.getMinutes()).slice(-2);
	        let sseconds = ('0' + dateObj.getSeconds()).slice(-2);

	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year} @ ${shours}:${sminutes}:${sseconds}`;

	    } else if (showDay) {
	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year}`;
	    } else if (showTime) {
	        let hours = ('0' + dateObj.getHours()).slice(-2);
	        let minutes = ('0' + dateObj.getMinutes()).slice(-2);
	        let seconds = ('0' + dateObj.getSeconds()).slice(-2);

	        return `${day}/${month}/${year} @ ${hours}:${minutes}:${seconds}`;
	    } else {
	        return `${day}/${month}/${year}`;
	    }
	};

	const formatOptions = (options) => {
		let options_arr = []
		for (let o=0; o < options.length; o++) {
			options_arr.push({label: `${options[o].first_name} ${options[o].last_name}`, value: options[o]._id})
		}

		return options_arr
	}

	function isUserBirthday(dob) {
		const currentDate = new Date();
		const userBirthday = new Date(dob);
		return (
			currentDate.getDate() === userBirthday.getDate() &&
			currentDate.getMonth() === userBirthday.getMonth()
		);
	}

	const reloadApp = () => {
		window.location.reload()
	}

	const handleClock = async (control, selected) => {
		switch (control) {
			case 'set':
				setDriverSelected(selected)

				let driverFound = allDrivers.filter(driver => driver._id.toString() === selected.value.toString())[0]
				setIsSelectedBirthday(isUserBirthday(driverFound.dob))
				break;
			case 'init_submit':
				setShowKeypad(true)
				break;
		}
	}

	const capture = async (shiftData) => {
		const imageSrc = webcamRef.current.getScreenshot();
		const byteString = atob(imageSrc.split(',')[1]);
		const mimeString = imageSrc.split(',')[0].split(':')[1].split(';')[0];
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([ab], { type: mimeString });

        const formData = new FormData();
        formData.append('driver_id', shiftData.driver_id);
        formData.append('clocking_off', shiftData.clocking_off);
        formData.append('img_src', blob);

        await axios.post(`${url}/update-shift-img`, formData, {
            headers: {
            	'Content-Type': 'multipart/form-data' 
        	}
        });
	};

	const handleKeypad = async (control, ind) => {
		switch(control) {
			case 'set':
				setCodeError(false)

				let keypadEntryDup = [...keypadEntry, ind]
				setKeypadEntry(keypadEntryDup)

				if (keypadEntryDup.length === 4) {
					setSubmittingCode(true)
					let driverFound = allDrivers.find(driver => driver._id.toString() === driverSelected.value.toString())
					let driverYear = driverFound.dob.split("-")[0]

					if (driverYear === keypadEntryDup.join('')) {
						// submit shift data
						let shiftData = {
							driver_id: driverSelected.value,
							clocking_off: driverFound.hasOpenShift
						}

			            await axios.post(`${url}/update-shift`, shiftData);
			            await capture(shiftData)

			            window.location.reload()
					} else {
						setCodeError(true)
						setSubmittingCode(false)
						setKeypadEntry([])
					}
				}
				break;
			case 'back':
				let keypadEntryPop = [...keypadEntry]
				keypadEntryPop.pop()

				setKeypadEntry(keypadEntryPop)
				break;
			case 'close':
				setCodeError(false)
				setSubmittingCode(false)
				setKeypadEntry([])
				setShowKeypad(false)

				setDriverSelected(null)
				setIsSelectedBirthday(false)
				break;
		}
	}

	return (
		<>
			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={styles.main_clock_container}>
								<DashboardPage {...{ param, driverSelected, handleClock, allDrivers, formatOptions, reloadApp, isSelectedBirthday, formatDate, showKeypad, submittingCode }} />
								{showKeypad && <KeypadPage {...{ handleKeypad, keypadEntry, submittingCode, codeError, webcamRef, allDrivers, driverSelected }}/>}
							</div>
							: <Forbidden {...{ reloadApp }}/>
						}
					</>
				:
					<div className={styles.loadpage_set_container}>
						<div className={styles.load}>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
			}
		</>
	);
};


export default Dashboard;