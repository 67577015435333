import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import Forbidden from '../Forbidden';
import Loading from '../Loading';
import ofglogo from '../../images/logo.png'
import styles from "./Sites.module.css"
import ComingSoon from '../ComingSoon';

import ReactAutocomplete from "react-google-autocomplete";

const NavigationBar = ({ param, handleLogout }) => {
	return (
		<div className={styles.sidebar_container}>
			<div className={styles.sidebar_account}>
				<i className={`fa-solid fa-circle-user ${styles.sidebar_icon}`} />
			</div>

			<div className={styles.sidebar_centered}>
				<div className={styles.sidebar_grid}>
					<Link to={`/dashboard/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-gauge-high ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/sites/${param._id}`}>
						<div className={`${styles.sidebar_box} ${styles.sidebar_active}`}>
							<i className={`fa-solid fa-location-dot ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/orders/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-box-open ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/dashboard/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-file-invoice-dollar ${styles.sidebar_icon}`} />
						</div>
					</Link>
				</div>
			</div>
		</div>
	)
}


const SitesPage = ({ param, addSite, addSitePopup, sitesData, deleteSite, search_control, toggleInd, indSitePopup }) => {
	return (
		<div className={`${styles.page_container} ${(addSitePopup || indSitePopup) && styles.hidden_container}`}>
			<div className={styles.sites_container}>

				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>Sites</h1>
						<button onClick={() => addSite()}>
							<i className="fa-solid fa-plus"></i>
							<p className={styles.smaller_font}>Add site</p>
						</button>
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${styles.sitenav_active}`}><p>Overview</p></button>
					</div>
				</div>

				<div className={styles.sites_stats}>
					<div className={styles.statbox}>
						<p className={styles.smaller_font}>Total random</p>
						<h1 className={styles.bigger_font}>11</h1>
					</div>
					<div className={styles.statbox}>
						<p className={styles.smaller_font}>Total random</p>
						<h1 className={styles.bigger_font}>943</h1>
					</div>
					<div className={styles.statbox}>
						<p className={styles.smaller_font}>Total random</p>
						<h1 className={styles.bigger_font}>4323</h1>
					</div>
				</div>

				<div className={styles.sites_tablecontainer}>
					<div className={styles.sites_controls}>
						<div className={styles.sites_filters}>
							<div className={styles.filter_active}>
								<p className={styles.smaller_font}>All time</p>
								<i className="fa-solid fa-x"></i>
							</div>

							<div className={styles.filter_add}>
								<i class="fa-solid fa-filter"></i>
								<p className={styles.smaller_font}>More filters</p>
							</div>
						</div>
						<div className={styles.sites_searchcontainer}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input className={styles.smaller_font} type="text" placeholder="Search site code, name or address..." id="searchbar" onKeyUp={event => search_control(event)} />
							</div>
						</div>
					</div>

					<div className={styles.table_container}>
						<table className={styles.sites_table} id="sites_table">
						    <thead>
						      <tr className={styles.table_rowth}>
								<th>Site code</th>
								<th>Site name</th>
								<th>Address</th>
								<th>Site contact</th>
								<th>Next delivery</th>
								<th>Status</th>
						      </tr>
						    </thead>
						    <tbody className={styles.indtbody}>
						    	{(sitesData && sitesData.length > 0)
						    	?
						    	<>
						    	{sitesData.map((SD) => {
						    		return (
							    		<tr onClick={() => toggleInd(SD)}>
								    		<td>{SD.site_code}</td>
								    		<td>{SD.site_name}</td>
								    		<td>{SD.site_location.street}</td>
								    		<td>{SD.site_contact.first_name} {SD.site_contact.last_name}</td>
								    		<td>
								    			<div className={`${styles.progressbar} ${styles.temp_progressbar1}`}></div>
								    		</td>
								    		<td className={styles.site_statustd}>
								    			<div className={`${styles.site_status} ${SD.status == "Pending" && styles.status_pending} ${(SD.status == "Active" || SD.status == "Completed") && styles.status_active} ${SD.status == "Inactive" && styles.status_inactive}`}>
								    				<p className={styles.smaller_font}>{SD.status}</p>
								    			</div>
								    		</td>
								    	</tr>
							    	)
						    	})}
						    	</>
						    	: <h1 className={`${styles.bigger_font} ${styles.create_first2}`}>Create your first site through the "Add Site" button!</h1>
						    	}
						    </tbody>
						</table>
					</div>

				</div>
			</div>
		</div>
	)
}


const AddPopUp = ({ param, popUpPage, popUpControls, newSiteData, handleNewSiteData, handleNewSiteAddress, newSiteAddress, validationError }) => {
	return (
		<div className={styles.popup_container}>

			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Site details</h1>
					<p className={styles.smaller_font}>Please enter the name of your site, and the address.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site name</label>
							<input type="text" name="site_name" value={newSiteData.site_name} placeholder="Seek - Laverton" onChange={handleNewSiteData}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site level</label>
							<input type="text" name="site_level" value={newSiteData.site_level} placeholder="12" onChange={handleNewSiteData}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site address</label>
							<ReactAutocomplete
							  apiKey="AIzaSyAPGR-rJOJE8h4YwVPlgJQjnUEzJcOQqb4"
							  onPlaceSelected={(place) => handleNewSiteAddress(place)}
							  options={{
							    types: ["address"],
							    componentRestrictions: { country: "au" },
							  }}
							  placeholder="Start typing your address..."
							  defaultValue={newSiteAddress && newSiteAddress.formatted_address}
							/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Delivery instructions</label>
							<input type="text" name="site_instructions" value={newSiteData.site_instructions} placeholder="Leave all orders outside..." onChange={handleNewSiteData}/>
						</div>
					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Site contact</h1>
					<p className={styles.smaller_font}>Please enter the primary contact details for this site.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>First name</label>
								<input type="text" name="site_contact_fname" value={newSiteData.site_contact_fname} placeholder="John" onChange={handleNewSiteData}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Last name</label>
								<input type="text" name="site_contact_lname" value={newSiteData.site_contact_lname} placeholder="Doe" onChange={handleNewSiteData}/>
							</div>
						</div>

						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" name="site_contact_email" value={newSiteData.site_contact_email} placeholder="johndoe@gmail.com" onChange={handleNewSiteData}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" name="site_contact_phone" value={newSiteData.site_contact_phone} placeholder="1300 231 323" onChange={handleNewSiteData}/>
							</div>
						</div>
					</div>
				</>
			}

			{popUpPage == 3 &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site name</label>
							<input type="text" value={newSiteData.site_name} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site level</label>
							<input type="text" value={newSiteData.site_level} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site address</label>
							<input type="text" value={newSiteAddress.formatted_address} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Delivery instructions</label>
							<input type="text" value={newSiteData.site_instructions} disabled={true}/>
						</div>
					</div>

					<div className={styles.popup_form}>
						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>First name</label>
								<input type="text" value={newSiteData.site_contact_fname} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Last name</label>
								<input type="text" value={newSiteData.site_contact_lname} disabled={true}/>
							</div>
						</div>

						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" value={newSiteData.site_contact_email} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" value={newSiteData.site_contact_phone} disabled={true}/>
							</div>
						</div>
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 3 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 3 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}


const IndPopUp = ({ param, indPopUpControls, sitesData, indSite }) => {
	return (
		<div className={styles.indsite_container}>
			<div className={styles.indsite_popup}>

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					{indSite.status != "Inactive" &&
						<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
					}

					<h1 className={styles.medium_font}>Site details</h1>
					<p className={styles.smaller_font}>Please find the specific details for your site here.</p>


					<div className={styles.ind_grid}>
						<div className={styles.indinfo_grid}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Site code</label>
								<p>{indSite.site_code}</p>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Site name</label>
								<p>{indSite.site_name}</p>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Address</label>
								<p>{indSite.site_location.street}, {indSite.site_location.city} {indSite.site_location.state} {indSite.site_location.postcode}, {indSite.site_location.country}</p>
							</div>

							{/* FIND BETTER SOLUTION WITHOUT INLINE */}
							<div className={styles.popup_formbox} style={{position: 'relative'}}>
								<div style={{position: 'absolute'}}>
									<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
										<label className={styles.smaller_font}>Status</label>
						    			<div className={`${styles.site_status} ${indSite.status == "Pending" && styles.status_pending} ${(indSite.status == "Active" || indSite.status == "Completed") && styles.status_active} ${indSite.status == "Inactive" && styles.status_inactive}`}>
						    				<p className={styles.smaller_font}>{indSite.status}</p>
						    			</div>
					    			</div>
				    			</div>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Total orders</label>
								<p>{(indSite.orders && indSite.orders.length > 0) ? indSite.orders.length : '-'}</p>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Ongoing order</label>
								<p>{(indSite.preferences && indSite.preferences.length > 0) ? 'Active' : '-'}</p>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Site contact</label>
								<div className={styles.site_contact_flex}>
									<i className="fa-solid fa-user"></i>
									<p>{(indSite.site_contact.first_name && indSite.site_contact.last_name && indSite.site_contact.first_name != '' && indSite.site_contact.last_name != '') ? `${indSite.site_contact.first_name} ${indSite.site_contact.last_name}` : '-'}</p>
								</div>
								<div className={styles.site_contact_flex}>
									<i className="fa-solid fa-envelope"></i>
									<p>johndoe@gmail.com</p>
								</div>
								<div className={styles.site_contact_flex}>
									<i className="fa-solid fa-phone"></i>
									<p>1300 323 323</p>
								</div>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Site instructions</label>
								<p>{(indSite.site_instructions && indSite.site_instructions != '') ? indSite.site_instructions : '-'}</p>
							</div>

						</div>

						{/* orders per ind site */}
						<div className={styles.sites_tablecontainer}>
							<div className={styles.sites_controls}>
								<div className={styles.sites_searchcontainer}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input className={styles.smaller_font} type="text" placeholder="Search item name..." />
									</div>
								</div>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="sites_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th>Order code</th>
								      	<th>Total quantity</th>
								      	<th>Status</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{indSite.orders_pushed &&
								    		<>
								    			{indSite.orders_pushed.length > 0
								    				? 
								    				<>
									    				{indSite.orders_pushed.map((IO) => {
									    					return (
														    	<tr className={styles.sitesind_tr}>
														    		<td>{IO.order_code}</td>
														    		<td>{IO.total_qty} items</td>
														    		<td className={styles.site_statustd}>
														    			<div className={`${styles.site_status} ${IO.status == "Pending" && styles.status_pending} ${(IO.status == "Active" || IO.status == "Completed") && styles.status_active} ${IO.status == "Inactive" && styles.status_inactive}`}>
														    				<p className={styles.smaller_font}>{IO.status}</p>
														    			</div>
														    		</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>Create your first order through the Orders page!</h1>
								    			}
								    		</>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	)
}





const Sites = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const [isLoading, setIsLoading] = useState(true);
	const [accountData, setAccountData] = useState({});
	const [sitesData, setSitesData] = useState([]);
	const [validationError, setValidationError] = useState(null)

	const [isAuthenticated, setIsAuthenticated] = useState(false);
 	const isSuperAdmin = accountData && accountData.role && accountData.role.name === 'SuperAdmin';
 	const isAdmin = accountData && accountData.role && accountData.role.name === 'Admin';
 	const isAccount = accountData && accountData.role && accountData.role.name === 'Account';

 	// add popup
 	const [newSiteData, setNewSiteData] = useState({});
 	const [newSiteAddress, setNewSiteAddress] = useState(null)
 	const [addSitePopup, setAddSitePopup] = useState(false);
 	const [popUpPage, setPopUpPage] = useState(1);


 	// ind site popup
 	const [indSitePopup, setIndSitePopup] = useState(false);
 	const [indSite, setIndSite] = useState(null)
 

	const param = useParams();
	const url = `/api/sites/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};


	useEffect(() => {
		setIsLoading(true)
		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					setAccountData(data.data);
					setSitesData(data.data.sites);

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}

				setIsLoading(false)
			} catch (error) {
				setAccountData(error);
			}

		    document.addEventListener('keydown', handleKeyPress);

		    return () => {
		      document.removeEventListener('keydown', handleKeyPress);
		    };
		};


		initLoad();
	}, [param, url]);


	const search_control = (event) => {
		var input, filter, table, tr, td, td2, td3, i, txtValue, txtValue2, txtValue3;
		input = document.getElementById("searchbar");
		filter = input.value.toUpperCase();
		table = document.getElementById("sites_table");
		tr = table.getElementsByTagName("tr");

		for (i = 0; i < tr.length; i++) {
			td = tr[i].getElementsByTagName("td")[0];
			td2 = tr[i].getElementsByTagName("td")[1];
			td3 = tr[i].getElementsByTagName("td")[2];

			if (td) {
				txtValue = td.textContent || td.innerText;
				txtValue2 = td2.textContent || td2.innerText;
				txtValue3 = td3.textContent || td3.innerText;

				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else if (txtValue2.toUpperCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else if (txtValue3.toUpperCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else {
					tr[i].style.display = "none";
				}
			}
		}
	}

	
	const addSite = () => {
		setAddSitePopup(true)
	}

	const popUpControls = async (control) => {
		switch(control) {
			case 'prev':
				setPopUpPage(popUpPage-1);
				break;
			case 'next':

				if (popUpPage == 1) {
					if (!(newSiteData.hasOwnProperty('site_name')) || newSiteData['site_name'] == '') {
						setValidationError(`Please enter a site name.`)
						return false;
					}

					if (!(newSiteAddress)) {
						setValidationError(`Please enter a site address.`)
						return false;
					}
					
				} else if (popUpPage == 2) {
					if (!(newSiteData.hasOwnProperty('site_contact_fname')) || newSiteData['site_contact_fname'] == '') {
						setValidationError(`Please enter a site contact first name.`)
						return false;
					}

					if (!(newSiteData.hasOwnProperty('site_contact_lname')) || newSiteData['site_contact_lname'] == '') {
						setValidationError(`Please enter a site contact last name.`)
						return false;
					}

					if (!(newSiteData.hasOwnProperty('site_contact_email')) || newSiteData['site_contact_email'] == '') {
						setValidationError(`Please enter a site contact email address.`)
						return false;
					} else {
						if (!(isValidEmail(newSiteData.site_contact_email))) {
							setValidationError(`Please enter a valid email address.`)
							return false;
						}
					}

					if (!(newSiteData.hasOwnProperty('site_contact_phone')) || newSiteData['site_contact_phone'] == '') {
						setValidationError(`Please enter a site contact phone number.`)
						return false;
					}
				}
				setValidationError(null)
				setPopUpPage(popUpPage+1)
				break
			case 'close':
				if (Object.keys(newSiteData).length > 0) {
					const confirm_close = window.confirm(`Are you sure you would like to discard this new site?`)
					if (confirm_close) {
						setAddSitePopup(false);

						// RESET ADD FORM DATA
						setNewSiteData({})
						setNewSiteAddress(null)
						setPopUpPage(1)
						setValidationError(null)
					}
				} else {
					setAddSitePopup(false);
					setValidationError(null)
				}

				break;
			case 'submit':
				await axios.post(`/api/sites/${param._id}`, {site_data: newSiteData, site_address: newSiteAddress})
				window.location.reload(false);
				break;
		}
	}

	const handleNewSiteData = ({ currentTarget: input }) => {
		setNewSiteData({ ...newSiteData, [input.name]: input.value });
	};

	const handleNewSiteAddress = (place) => {
		setNewSiteAddress(place);
	};

	const deleteSite = async (e, site_id) => {
		e.stopPropagation();
		const confirm_delete = window.confirm(`Are you sure you would like to delete this site?`)
		if (confirm_delete) {
			await axios.post(`/api/sites/delete/${param._id}`, {site_id: site_id})
			window.location.reload(false);
		} else {
			return false
		}
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}
	
	const toggleInd = (site) => {
		setIndSitePopup(true)
		setIndSite(site)
	}

	const indPopUpControls = async (control) => {
		switch(control) {
			case 'close':
				setIndSitePopup(false);
				break;
		}
	}

    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setIndSitePopup(false);
        setIndSite(null)
      }
    };



	return (
		<>
			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={styles.main_container}>
								<NavigationBar {...{ param, handleLogout }} />
								<ComingSoon />
								{/* <SitesPage {...{ param, addSite, addSitePopup, sitesData, deleteSite, search_control, toggleInd, indSitePopup }} /> */}
								{/* {addSitePopup && <AddPopUp {...{ param, popUpPage, popUpControls, newSiteData, handleNewSiteData, handleNewSiteAddress, newSiteAddress, validationError }} />} */}
								{/* {indSitePopup && <IndPopUp {...{ param, indPopUpControls, sitesData, indSite }} />} */}
							</div>
							: <Forbidden />
						}
					</>
				:
					<Loading/>
			}
		</>
	);
};

export default Sites;