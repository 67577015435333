import styles from "./Users.module.css"

const PopUpIndUser = ({ param, indPopUpControls, indUser, changeIndNav, indNav, formatDate, changeStatus, changingStatus }) => {
	return (
		<div className={styles.indsite_container}>
			<div className={styles.indsite_popup}>

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
					<button className={styles.nextPopup} onClick={() => indPopUpControls('delete', indUser)}><i className="fa-solid fa-trash-can"></i></button>

					<h1 className={styles.medium_font}>User {indNav.toLowerCase()}</h1>
					<p className={styles.smaller_font}>Please find the specific {indNav.toLowerCase()} for your enquiry here.</p>


					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndNav('Details')}><p>Details</p></button>
						</div>
					</div>

					{indNav == "Details" &&
						<div className={`${styles.sites_tablecontainer} ${changingStatus && styles.hidden_container}`}>
							<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Name</label>
									<p>{indUser.contacts[0].first_name} {indUser.contacts[0].last_name}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Email</label>
									<a className={styles.clickable_nav} href={`mailto:${indUser.email}`}><p>{indUser.email}</p></a>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Verified</label>
									<p>{indUser.verified ? "Yes" : "No"}</p>
								</div>

								<div className={styles.popup_formbox} style={{position: 'relative'}}>
									<div style={{position: 'absolute'}}>
										<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
											<label className={styles.smaller_font}>Status</label>
							    			<button onClick={() => changeStatus('toggle')} className={`${styles.site_status} ${indUser.status == "Pending" && styles.status_pending} ${(indUser.status == "Active" || indUser.status == "Completed") && styles.status_active} ${indUser.status == "Inactive" && styles.status_inactive}`}>
							    				<p className={styles.smaller_font}>{indUser.status}</p>
							    			</button>
						    			</div>
					    			</div>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Date of birth</label>
									<p>{formatDate(indUser.dob)}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Work anniversary</label>
									<p>{formatDate(indUser.work_anniversary)}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Permissions</label>

									<div className={styles.induser_permissions}>
										{indUser.permissions && indUser.permissions.map((AP) => {
										    const dynamicClassName = `${styles.user_status} ${styles['status_' + AP.replace(' ', '')]}`;

										    return (
										        <div className={dynamicClassName}>
										            <p className={styles.smaller_font}>{AP}</p>
										        </div>
										    );
										})}
									</div>
								</div>
							</div>
						</div>
					}

	    			{changingStatus &&
	    				<div className={styles.statusOptions}>
	    					<button className={`${styles.closePopup} ${styles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

							<h1 className={styles.medium_font}>Change status</h1>
							<p className={`${styles.smaller_font}`}>Use this module to update the status for {indUser.contacts[0].first_name} {indUser.contacts[0].last_name}</p>

							<div className={styles.statusOuter}>
								<div className={styles.statusInner}>
					    			{indUser.status !== "Active" &&
						    			<button onClick={() => changeStatus('set', 'User', indUser, "Active")} className={`${styles.site_status} ${styles.status_active}`}>
						    				<p className={styles.smaller_font}>Active</p>
						    			</button>
						    		}

						    		{indUser.status !== "Inactive" &&
						    			<button onClick={() => changeStatus('set', 'User', indUser, "Inactive")} className={`${styles.site_status} ${styles.status_inactive}`}>
						    				<p className={styles.smaller_font}>Inactive</p>
						    			</button>
						    		}
					    		</div>
				    		</div>
	    				</div>
	    			}


				</div>
			</div>
		</div>
	)
}

export default PopUpIndUser;