import Select from 'react-select';
import styles from "./Fleet.module.css"

import refuel from '../../../images/refuel.jpg'
import drive from '../../../images/drive.jpg'

import ReactAutocomplete from "react-google-autocomplete";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpLogs = ({ param, popUpPage, popUpControls, indPopUpControls, validationError, handleNewLogData, newLogData, formatDate }) => {
	return (
		<div className={styles.popup_container}>

			<button className={styles.closePopup} onClick={() => indPopUpControls('logs_close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Log details</h1>
					<p className={styles.smaller_font}>Please enter the specific log details to create.</p>

					<div className={`${styles.popup_form}`}>
						<button className={`${styles.order_typebox} ${newLogData["type"] && newLogData["type"] === "Refuel" ? styles.order_typeboxactive : ""}`} onClick={(selected) => handleNewLogData('type', {value: "Refuel"})}>
							<div className={styles.order_imgbox}>
								<img src={refuel}/>
							</div>

							<div className={styles.order_contentbox}>
								<h1 className={styles.smaller_font}>Refuel logs</h1>
								<p className={styles.smaller_font}>Keep track of when vehicles have been refueled.</p>
							</div>
						</button>

						<button className={`${styles.order_typebox} ${newLogData["type"] && newLogData["type"] === "Drive" ? styles.order_typeboxactive : ""}`} onClick={(selected) => handleNewLogData('type', {value: "Drive"})}>
							<div className={styles.order_imgbox}>
								<img src={drive}/>
							</div>

							<div className={styles.order_contentbox}>
								<h1 className={styles.smaller_font}>Drive logs</h1>
								<p className={styles.smaller_font}>Store records for every drive that has been completed.</p>
							</div>
						</button>
					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Log details</h1>
					<p className={styles.smaller_font}>Please enter the specific log details to create.</p>

					{(newLogData["type"] && newLogData["type"] == "Drive") &&
						<div className={styles.popup_form}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Driver</label>
								<input type="text" placeholder="Brian" onChange={(selected) => handleNewLogData('driver', selected)} value={newLogData["driver"] && newLogData["driver"]}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Start mileage (km)</label>
								<input type="text" placeholder="10000" onChange={(selected) => handleNewLogData('start_mileage', selected)} value={newLogData["start_mileage"] && newLogData["start_mileage"]}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>End mileage (km)</label>
								<input type="text" placeholder="10050" onChange={(selected) => handleNewLogData('end_mileage', selected)} value={newLogData["end_mileage"] && newLogData["end_mileage"]}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Date of drive</label>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										className={styles.overridebg}
										value={newLogData && newLogData.date_drove ? dayjs(newLogData.date_drove) : null}
										onChange={(selected) => handleNewLogData("date_drove", {value: selected})}
										format="DD/MM/YYYY"
									/>
								</LocalizationProvider>
							</div>
						</div>
					}


					{(newLogData["type"] && newLogData["type"] == "Refuel") &&
						<div className={styles.popup_form}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Driver</label>
								<input type="text" placeholder="Brian" onChange={(selected) => handleNewLogData('driver', selected)} value={newLogData["driver"] && newLogData["driver"]}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Amount refueled</label>
								<input type="text" placeholder="130 litres" onChange={(selected) => handleNewLogData('amount_refueled', selected)} value={newLogData["amount_refueled"] && newLogData["amount_refueled"]}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Price</label>
								<input type="text" placeholder="$260" onChange={(selected) => handleNewLogData('price', selected)} value={newLogData["price"] && newLogData["price"]}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Date of refuel</label>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										className={styles.overridebg}
										value={newLogData && newLogData.date_refueled ? dayjs(newLogData.date_refueled) : null}
										onChange={(selected) => handleNewLogData("date_refueled", {value: selected})}
										format="DD/MM/YYYY"
									/>
								</LocalizationProvider>
							</div>
						</div>
					}
				</>
			}


			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 2 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 2 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => indPopUpControls('logs_submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpLogs;