import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import NavigationBar from '../Utils/NavigationBar'
import fstyles from "./Forbiddenstyles.module.css"
import styles from "../../Dashboard/Dashboard.module.css"


const Forbidden = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const param = useParams();
	const [collapseDeg, setCollapseDeg] = useState(0)
	const [collapseFilters, setCollapseFilters] = useState(true)

	return (
		<div className={collapseFilters ? fstyles.main_container : fstyles.main_container_full}>
			<NavigationBar 
				param={param}
				active={"Forbidden"}
				handleLogout={handleLogout}
				collapseDeg={collapseDeg}
				setCollapseDeg={setCollapseDeg}
				collapseFilters={collapseFilters}
				setCollapseFilters={setCollapseFilters}
			/>

			<div className={styles.page_container}>
				<div className={fstyles.hover}>
				  <div className={fstyles.background}>
				    <div className={fstyles.door}><p className={fstyles.forbidden_1}>403</p><p className={styles.forbidden_text}>Access Denied</p></div>
				    <div className={fstyles.rug}></div>
				  </div>
				  <div className={fstyles.foreground}>
				    <div className={fstyles.bouncer}>
				      <div className={fstyles.head}>
				        <div className={fstyles.neck} />
				        <div className={`${fstyles.eye} ${fstyles.left}`} />
				        <div className={`${fstyles.eye} ${fstyles.right}`} />
				        <div className={fstyles.ear} />
				      </div>
				      <div className={fstyles.body} />
				      <div className={fstyles.arm} />
				    </div>
				    <div className={fstyles.poles}>
				      <div className={`${fstyles.pole} ${fstyles.left}`} />
				      <div className={`${fstyles.pole} ${fstyles.right}`} />
				      <div className={fstyles.rope} />
				    </div>
				  </div>
				</div>

			</div>
		</div>
	)
};

export default Forbidden;
