import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import ofglogo from '../../images/logo.png'
import styles from "./Loading.module.css"

import ReactAutocomplete from "react-google-autocomplete";

const NavigationBar = ({ param, handleLogout }) => {
	return (
		<div className={styles.sidebar_container}>
			<div className={styles.sidebar_account}>
				<i className={`fa-solid fa-circle-user ${styles.sidebar_icon}`} />
			</div>

			<div className={styles.sidebar_centered}>
				<div className={styles.sidebar_grid}>
					<Link to={`/dashboard/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-gauge-high ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/sites/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-location-dot ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/orders/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-box-open ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/dashboard/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-file-invoice-dollar ${styles.sidebar_icon}`} />
						</div>
					</Link>
				</div>
			</div>
		</div>
	)
}


const Loading = () => {
	const param = useParams();

	return (
		<>
			<div className={styles.main_container}>
				<NavigationBar {...{ param }} />

				<div className={styles.loadpage_container}>
					<div className={styles.load}>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
		</>
	);
};

export default Loading;