import Select from 'react-select';
import styles from "./Dashboard.module.css"

 
const PopUpAllergies = ({ param, handleAllergies, allergiesData, inventoryData, formatOptions }) => {
	return (
		<div className={`${styles.popup_container}`}>
			<button className={styles.closePopup} onClick={() => handleAllergies('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Manage Allergies</h1>
			<p className={styles.smaller_font}>Select the items that this site would like to permanently exclude.</p>

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Allergies</label>
					<Select
						isMulti={true}
						closeMenuOnSelect={true}
						options={formatOptions(inventoryData.map(inv => inv.item_name))}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								boxShadow: 'none !important',
							   '&:hover': {
							       borderColor: '#fce7eb !important'
							    },
								borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
							}),
						}}
						onChange={(selected) => handleAllergies('set', selected)}
						value={allergiesData.allergies && formatOptions(allergiesData.allergies)}
					/>
				</div>
			</div>

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handleAllergies('submit')}>Submit</button>
				</div>
			</div>
		</div>
	)
}

export default PopUpAllergies;