import styles from "./Dashboard.module.css"

const PopUpIndInteraction = ({ param, indPopUpControls, allAccounts, allSites, indInteraction, changeIndSiteNav, indSiteNav, formatDate, handleOpenEmail, emailBody, searchControl }) => {
	return (
		<div className={styles.indsite_container}>
			<div className={`${styles.indsite_popup} ${emailBody && styles.hidden_indcontainer} ${(indSiteNav === 'Emails' && indInteraction.emails.length > 0) && styles.super_width}`}>

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
					<button className={styles.nextPopup} onClick={() => indPopUpControls('delete', indInteraction)}><i className="fa-solid fa-trash-can"></i></button>

					<h1 className={styles.medium_font}>Interaction {indSiteNav.toLowerCase()}</h1>
					<p className={styles.smaller_font}>Please find the specific {indSiteNav.toLowerCase()} for your interaction here.</p>


					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Details')}><p>Details</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Emails' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Emails')}><p>Emails</p></button>
						</div>
					</div>

					{indSiteNav == "Details" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Account</label>
									<p className={styles.clickable_nav} onClick={() => indPopUpControls('navigate', allAccounts.filter(AS => AS._id == indInteraction.account)[0])}>{allAccounts.filter(AS => AS._id == indInteraction.account)[0].company}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Site</label>
									<p className={styles.clickable_nav} onClick={() => indPopUpControls('navigate', allSites.filter(AS => AS._id == indInteraction.site)[0])}>{allSites.filter(AS => AS._id == indInteraction.site)[0].site_name}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Interaction contact</label>
									<div className={styles.site_contact_flex}>
										<i className="fa-solid fa-user"></i>
										<p>{(indInteraction.contact.first_name && indInteraction.contact.last_name && indInteraction.contact.first_name != '' && indInteraction.contact.last_name != '') ? `${indInteraction.contact.first_name} ${indInteraction.contact.last_name}` : '-'}</p>
									</div>
									<div className={styles.site_contact_flex}>
										<i className="fa-solid fa-envelope"></i>
										{(indInteraction.contact.email && indInteraction.contact.email != '')
											? <a className={styles.clickable_nav} href={`mailto:${indInteraction.contact.email}`}><p>{indInteraction.contact.email}</p></a>
											: '-'
										}
									</div>
									<div className={styles.site_contact_flex}>
										<i className="fa-solid fa-phone"></i>
										{(indInteraction.contact.phone && indInteraction.contact.phone != '')
											? <a className={styles.clickable_nav} href={`tel:${indInteraction.contact.phone}`}><p>{indInteraction.contact.phone}</p></a>
											: '-'
										}
									</div>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Comment</label>
									<p>{indInteraction.comment}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Date created</label>
									<p>{formatDate(indInteraction.date_created)}</p>
								</div>

								{indInteraction.follow_up_date &&
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Follow up date</label>
										<p>{formatDate(indInteraction.follow_up_date)}</p>
									</div>
								}

								{(indInteraction.tags && indInteraction.tags.length > 0)
									?
										<>
											<div className={styles.popup_formbox} style={{position: 'relative'}}>
												<div style={{position: 'absolute'}}>
													<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
														<label className={styles.smaller_font}>Tags</label>

														<div className={styles.tag_flex}>
															{indInteraction.tags.map((IT) => {
																return (
													    			<div className={`${styles.site_status} ${styles.status_active}`}>
													    				<p className={styles.smaller_font}>{IT}</p>
													    			</div>
																)
															})}
														</div>
									    			</div>
								    			</div>
											</div>

										</>
									:
										<div className={styles.popup_formbox}>
											<label className={styles.smaller_font}>Tags</label>
											<p>-</p>
										</div>
								}

							</div>
						</div>
					}


					{indSiteNav === "Emails" &&
						<div className={`${styles.sites_tablecontainer}`}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th colspan="2">From</th>
								      	<th colspan="2">To</th>
								      	<th colspan="2">Date sent</th>
								      	<th colspan="1">Tools</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{indInteraction.emails
								    		?
								    		<>
								    			{indInteraction.emails.length > 0
								    				? 
								    				<>
									    				{indInteraction.emails.map((IE) => {
									    					return (
														    	<tr className={styles.sitesind_tr}>
														    		<td colspan="2">{IE.from.replaceAll('"', "").split(' <')[0]}<br/>{IE.from.replaceAll('"', "").replaceAll('>', "").split(' <')[1]}</td>
														    		<td colspan="2">{IE.to.replaceAll('"', "").split(' <')[0]}<br/>{IE.to.replaceAll('"', "").replaceAll('>', "").split(' <')[1]}</td>
														    		<td colspan="2">{formatDate(IE.date_sent, true, true).split(' @ ')[0]}<br/>@ {formatDate(IE.date_sent, true, true).split(' @ ')[1]}</td>
														    		<td colspan="1">
														    			<div className={styles.emailUploadedIcons}>
														    				<button onClick={() => handleOpenEmail('open', IE)}><i className="fa-solid fa-eye"></i></button>
														    				<button onClick={() => handleOpenEmail('delete', IE)}><i class="fa-solid fa-trash-can"></i></button>
														    			</div>
														    		</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This interaction does not have any emails attached!</h1>
								    			}
								    		</>
								    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This interaction does not have any emails attached!</h1>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}

				</div>
			</div>
		</div>
	)
}

export default PopUpIndInteraction;