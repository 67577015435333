import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from "./Warehouse.module.css"

import ReactAutocomplete from "react-google-autocomplete";


const PopUpOverflow = ({ param, popUpPage, popUpControls, newInventoryData, validationError, allInventory, handleNewInventoryData, subCatOptions, formatOptions, allSuppliers, handleOverflow, overflowData }) => {
	return (
		<div className={`${styles.popup_container} ${popUpPage !== 1 && styles.fixed_container_height}`}>

			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Overflow details</h1>
					<p className={styles.smaller_font}>Use this module to manage the overflows for purchase lists.</p>

					<div className={styles.popup_form_margin}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Delivery day</label>
							<Select
								closeMenuOnSelect={true}
								options={formatOptions(['Whole Week', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'])}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleOverflow("update", "day", selected)}
								value={{label: overflowData["day"], value: overflowData["day"]}}
							/>
						</div>
					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Overflow details</h1>
					<p className={styles.smaller_font}>Use this module to manage the overflows for purchase lists.</p>

					<div className={`${styles.draggableContainer} ${styles.altered_margin}`}>
						<div className={styles.draggableContainerBulk}>
							{overflowData.items.map((AI, index) => (
								<div className={`${styles.popup_form_overflow}`}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Product name</label>
										<input type="text" disabled={true} value={AI.item}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Percentage</label>
										<input type="text" value={AI.percentage} onChange={(selected) => handleOverflow("update", AI.item, selected)}/>
									</div>
								</div>
							))}
						</div>
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 2 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 2 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')} disabled={validationError !== null}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpOverflow;