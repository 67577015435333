import { useEffect, useState, useCallback, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Offline, Online, Detector } from "react-detect-offline";
import axios from "axios";
import 'animate.css';

import Forbidden from '../Forbidden';
import Loading from '../Loading';
import ofglogo from '../../../images/logo.png'
import styles from "./Reports.module.css"

import NavigationBar from '../Utils/NavigationBar'
import BarChart from './BarChart';
import BarChartVertical from './BarChartVertical'
import BarChartStacked from './BarChartStacked'
import LineChart from './LineChart';
import CalendarChart from './CalendarChart';
import PieChart from './PieChart';


const ReportsPage = ({ param, changeNavType, navType, search_control, popUpControls, indPopUpControls, lineChartData, calendarChartData, staticCharts }) => {
	return (
		<div className={`${styles.page_container}`}>
			<div className={`${styles.sites_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType}</h1>
						{navType == 'Reports' &&
							<button onClick={() => popUpControls('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Add report</p>
							</button>
						}
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'Reports' && styles.sitenav_active}`} onClick={() => changeNavType('Reports')}><p>Reports</p></button>
					</div>
				</div>

				<div className={styles.graphGrid}>
					<div className={`${styles.graphCell} ${styles.three_four}`}><BarChart barChartData={staticCharts['top10']} /></div>
					<div className={`${styles.graphCell} ${styles.one_four}`}><PieChart pieChartData={staticCharts['topItems']} /></div>

					<div className={`${styles.full_row}`}>
						<div className={`${styles.graphCell}`}><BarChartStacked barChartStackedData={staticCharts['orderBreakdown']} /></div>
						<div className={`${styles.graphCell}`}><BarChartVertical barChartVerticalData={staticCharts['itemsGrossMargin']} /></div>
					</div>
				</div>


			</div>
		</div>
	)
}


const Reports = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const [isLoading, setIsLoading] = useState(true);
	const [internalLoading, setInternalLoading] = useState(false);
	const [accountData, setAccountData] = useState({});
	const [collapseDeg, setCollapseDeg] = useState(0)
	const [collapseFilters, setCollapseFilters] = useState(true)

	const [isAuthenticated, setIsAuthenticated] = useState(false);
 	const isSuperAdmin = accountData && accountData.role && accountData.role.name === 'SuperAdmin';
 	const isAdmin = accountData && accountData.role && accountData.role.name === 'Admin';
 	const isAccount = accountData && accountData.role && accountData.role.name === 'Account';

 	const [navType, setNavType] = useState('Reports');
 	const [indNav, setIndNav] = useState("Details");
 	const [popUpPage, setPopUpPage] = useState(1);
 	const [validationError, setValidationError] = useState(null);

 	// charts
 	const [staticCharts, setStaticCharts] = useState(null)



	const param = useParams();
	const url = `/api/admin/reports/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					setAccountData(data.data);
					setStaticCharts(data.data.staticCharts)

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}

				setIsLoading(false)
			} catch (error) {
				setAccountData(error);
			}
		};
		initLoad();
	}, [param, url]);


	const formatDate = (dateString, showDay) => {
	    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	    const dateObj = new Date(dateString);

	    dateObj.setHours(dateObj.getHours() + 10);

	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);

	    const currentDayNumber = dateObj.getDay();

	    if (showDay) {
	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year}`;
	    } else {
	        return `${day}/${month}/${year}`;
	    }
	};

	const search_control = (event) => {
		var input, filter, table, tr, td, i, txtValue;
		input = document.getElementById("searchbar");
		filter = input.value.toUpperCase();
		table = document.getElementById("table_main");
		tr = table.getElementsByTagName("tr");

		for (i = 0; i < tr.length; i++) {
			td = tr[i].getElementsByTagName("td")[0];

			if (td) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else {
					tr[i].style.display = "none";
				}
			}
		}
	}

	const changeNavType = (type) => {
		setNavType(type)
	}

	const changeIndNav = async (type) => {
		setIndNav(type)
	}

	const popUpControls = async (control) => {
		switch(control) {
			case 'open':
				break;
			case 'prev':
				break;
			case 'next':
				break
			case 'close':
				break;
			case 'submit':
				break;
		}
	}

	const indPopUpControls = async (control, ind) => {
		switch(control) {
			case 'open':
				break
			case 'edit':
				break
			case 'close':
				break;
		}
	}

	// const barChartData = [
	//   {
	//     customer: "One Plus",
	//     value: 350
	//   },
	//   {
	//     customer: "Samsung",
	//     value: 280
	//   },
	//   {
	//     customer: "Nokia",
	//     value: 240
	//   }
	// ]


	const lineChartData = [
	  {
	    "id": "japan",
	    "color": "hsl(56, 70%, 50%)",
	    "data": [
	      {
	        "x": "plane",
	        "y": 57
	      },
	      {
	        "x": "helicopter",
	        "y": 1
	      },
	      {
	        "x": "boat",
	        "y": 148
	      },
	      {
	        "x": "train",
	        "y": 176
	      },
	      {
	        "x": "subway",
	        "y": 249
	      },
	      {
	        "x": "bus",
	        "y": 58
	      },
	      {
	        "x": "car",
	        "y": 20
	      },
	      {
	        "x": "moto",
	        "y": 46
	      },
	      {
	        "x": "bicycle",
	        "y": 43
	      },
	      {
	        "x": "horse",
	        "y": 222
	      },
	      {
	        "x": "skateboard",
	        "y": 197
	      },
	      {
	        "x": "others",
	        "y": 114
	      }
	    ]
	  },
	  {
	    "id": "france",
	    "color": "hsl(360, 70%, 50%)",
	    "data": [
	      {
	        "x": "plane",
	        "y": 108
	      },
	      {
	        "x": "helicopter",
	        "y": 284
	      },
	      {
	        "x": "boat",
	        "y": 101
	      },
	      {
	        "x": "train",
	        "y": 160
	      },
	      {
	        "x": "subway",
	        "y": 253
	      },
	      {
	        "x": "bus",
	        "y": 239
	      },
	      {
	        "x": "car",
	        "y": 236
	      },
	      {
	        "x": "moto",
	        "y": 4
	      },
	      {
	        "x": "bicycle",
	        "y": 46
	      },
	      {
	        "x": "horse",
	        "y": 31
	      },
	      {
	        "x": "skateboard",
	        "y": 176
	      },
	      {
	        "x": "others",
	        "y": 195
	      }
	    ]
	  },
	  {
	    "id": "us",
	    "color": "hsl(216, 70%, 50%)",
	    "data": [
	      {
	        "x": "plane",
	        "y": 174
	      },
	      {
	        "x": "helicopter",
	        "y": 276
	      },
	      {
	        "x": "boat",
	        "y": 10
	      },
	      {
	        "x": "train",
	        "y": 290
	      },
	      {
	        "x": "subway",
	        "y": 162
	      },
	      {
	        "x": "bus",
	        "y": 26
	      },
	      {
	        "x": "car",
	        "y": 90
	      },
	      {
	        "x": "moto",
	        "y": 55
	      },
	      {
	        "x": "bicycle",
	        "y": 84
	      },
	      {
	        "x": "horse",
	        "y": 65
	      },
	      {
	        "x": "skateboard",
	        "y": 6
	      },
	      {
	        "x": "others",
	        "y": 61
	      }
	    ]
	  },
	  {
	    "id": "germany",
	    "color": "hsl(277, 70%, 50%)",
	    "data": [
	      {
	        "x": "plane",
	        "y": 89
	      },
	      {
	        "x": "helicopter",
	        "y": 130
	      },
	      {
	        "x": "boat",
	        "y": 285
	      },
	      {
	        "x": "train",
	        "y": 180
	      },
	      {
	        "x": "subway",
	        "y": 214
	      },
	      {
	        "x": "bus",
	        "y": 126
	      },
	      {
	        "x": "car",
	        "y": 69
	      },
	      {
	        "x": "moto",
	        "y": 134
	      },
	      {
	        "x": "bicycle",
	        "y": 284
	      },
	      {
	        "x": "horse",
	        "y": 249
	      },
	      {
	        "x": "skateboard",
	        "y": 251
	      },
	      {
	        "x": "others",
	        "y": 24
	      }
	    ]
	  },
	  {
	    "id": "norway",
	    "color": "hsl(192, 70%, 50%)",
	    "data": [
	      {
	        "x": "plane",
	        "y": 221
	      },
	      {
	        "x": "helicopter",
	        "y": 145
	      },
	      {
	        "x": "boat",
	        "y": 216
	      },
	      {
	        "x": "train",
	        "y": 44
	      },
	      {
	        "x": "subway",
	        "y": 81
	      },
	      {
	        "x": "bus",
	        "y": 240
	      },
	      {
	        "x": "car",
	        "y": 245
	      },
	      {
	        "x": "moto",
	        "y": 106
	      },
	      {
	        "x": "bicycle",
	        "y": 287
	      },
	      {
	        "x": "horse",
	        "y": 294
	      },
	      {
	        "x": "skateboard",
	        "y": 258
	      },
	      {
	        "x": "others",
	        "y": 276
	      }
	    ]
	  }
	]


	const calendarChartData = [
	  {
	    "value": 227,
	    "day": "2017-10-10"
	  },
	  {
	    "value": 324,
	    "day": "2015-06-13"
	  },
	  {
	    "value": 23,
	    "day": "2015-06-16"
	  },
	  {
	    "value": 223,
	    "day": "2016-06-14"
	  },
	  {
	    "value": 302,
	    "day": "2017-08-08"
	  },
	  {
	    "value": 127,
	    "day": "2017-12-28"
	  },
	  {
	    "value": 180,
	    "day": "2018-06-12"
	  },
	  {
	    "value": 22,
	    "day": "2015-08-12"
	  },
	  {
	    "value": 374,
	    "day": "2017-01-22"
	  },
	  {
	    "value": 166,
	    "day": "2016-05-28"
	  },
	  {
	    "value": 30,
	    "day": "2017-03-11"
	  },
	  {
	    "value": 302,
	    "day": "2017-10-08"
	  },
	  {
	    "value": 29,
	    "day": "2018-05-27"
	  },
	  {
	    "value": 115,
	    "day": "2018-07-01"
	  },
	  {
	    "value": 79,
	    "day": "2015-11-09"
	  },
	  {
	    "value": 385,
	    "day": "2015-04-19"
	  },
	  {
	    "value": 310,
	    "day": "2017-07-06"
	  },
	  {
	    "value": 190,
	    "day": "2016-05-14"
	  },
	  {
	    "value": 20,
	    "day": "2018-08-09"
	  },
	  {
	    "value": 393,
	    "day": "2016-11-02"
	  },
	  {
	    "value": 266,
	    "day": "2015-04-27"
	  },
	  {
	    "value": 214,
	    "day": "2017-07-21"
	  },
	  {
	    "value": 311,
	    "day": "2016-11-20"
	  },
	  {
	    "value": 14,
	    "day": "2015-09-11"
	  },
	  {
	    "value": 35,
	    "day": "2016-07-26"
	  },
	  {
	    "value": 135,
	    "day": "2018-03-21"
	  },
	  {
	    "value": 326,
	    "day": "2017-02-07"
	  },
	  {
	    "value": 370,
	    "day": "2017-02-05"
	  },
	  {
	    "value": 245,
	    "day": "2015-10-01"
	  },
	  {
	    "value": 184,
	    "day": "2016-02-01"
	  },
	  {
	    "value": 73,
	    "day": "2016-04-27"
	  },
	  {
	    "value": 48,
	    "day": "2017-08-07"
	  },
	  {
	    "value": 210,
	    "day": "2016-01-19"
	  },
	  {
	    "value": 240,
	    "day": "2016-11-25"
	  },
	  {
	    "value": 286,
	    "day": "2018-07-14"
	  },
	  {
	    "value": 327,
	    "day": "2015-10-27"
	  },
	  {
	    "value": 32,
	    "day": "2018-07-11"
	  },
	  {
	    "value": 362,
	    "day": "2016-05-01"
	  },
	  {
	    "value": 121,
	    "day": "2015-04-05"
	  },
	  {
	    "value": 96,
	    "day": "2016-01-12"
	  },
	  {
	    "value": 39,
	    "day": "2015-12-21"
	  },
	  {
	    "value": 322,
	    "day": "2018-08-06"
	  },
	  {
	    "value": 315,
	    "day": "2016-06-12"
	  },
	  {
	    "value": 376,
	    "day": "2016-10-25"
	  },
	  {
	    "value": 120,
	    "day": "2015-05-24"
	  },
	  {
	    "value": 106,
	    "day": "2017-12-02"
	  },
	  {
	    "value": 362,
	    "day": "2017-04-17"
	  },
	  {
	    "value": 275,
	    "day": "2015-05-20"
	  },
	  {
	    "value": 237,
	    "day": "2018-04-26"
	  },
	  {
	    "value": 42,
	    "day": "2018-04-05"
	  },
	  {
	    "value": 129,
	    "day": "2015-07-05"
	  },
	  {
	    "value": 116,
	    "day": "2017-12-23"
	  },
	  {
	    "value": 165,
	    "day": "2018-06-23"
	  },
	  {
	    "value": 347,
	    "day": "2015-08-01"
	  },
	  {
	    "value": 246,
	    "day": "2017-05-17"
	  },
	  {
	    "value": 250,
	    "day": "2018-03-14"
	  },
	  {
	    "value": 113,
	    "day": "2017-09-05"
	  },
	  {
	    "value": 191,
	    "day": "2018-07-20"
	  },
	  {
	    "value": 325,
	    "day": "2015-09-06"
	  },
	  {
	    "value": 293,
	    "day": "2017-05-09"
	  },
	  {
	    "value": 311,
	    "day": "2016-05-20"
	  },
	  {
	    "value": 309,
	    "day": "2017-11-24"
	  },
	  {
	    "value": 223,
	    "day": "2017-12-10"
	  },
	  {
	    "value": 11,
	    "day": "2016-06-10"
	  },
	  {
	    "value": 42,
	    "day": "2015-08-20"
	  },
	  {
	    "value": 3,
	    "day": "2017-05-06"
	  },
	  {
	    "value": 341,
	    "day": "2018-06-08"
	  },
	  {
	    "value": 341,
	    "day": "2015-12-17"
	  },
	  {
	    "value": 29,
	    "day": "2015-11-06"
	  },
	  {
	    "value": 290,
	    "day": "2016-07-27"
	  },
	  {
	    "value": 23,
	    "day": "2018-03-01"
	  },
	  {
	    "value": 356,
	    "day": "2016-12-04"
	  },
	  {
	    "value": 198,
	    "day": "2015-04-16"
	  },
	  {
	    "value": 399,
	    "day": "2015-10-14"
	  },
	  {
	    "value": 341,
	    "day": "2015-12-31"
	  },
	  {
	    "value": 191,
	    "day": "2017-01-14"
	  },
	  {
	    "value": 59,
	    "day": "2015-07-15"
	  },
	  {
	    "value": 63,
	    "day": "2017-03-27"
	  },
	  {
	    "value": 65,
	    "day": "2016-06-19"
	  },
	  {
	    "value": 66,
	    "day": "2017-08-16"
	  },
	  {
	    "value": 351,
	    "day": "2017-03-05"
	  },
	  {
	    "value": 94,
	    "day": "2016-02-23"
	  },
	  {
	    "value": 333,
	    "day": "2015-05-11"
	  },
	  {
	    "value": 140,
	    "day": "2017-11-29"
	  },
	  {
	    "value": 376,
	    "day": "2016-03-31"
	  },
	  {
	    "value": 53,
	    "day": "2016-07-28"
	  },
	  {
	    "value": 161,
	    "day": "2017-03-19"
	  },
	  {
	    "value": 337,
	    "day": "2015-04-22"
	  },
	  {
	    "value": 128,
	    "day": "2017-10-30"
	  },
	  {
	    "value": 111,
	    "day": "2015-08-29"
	  },
	  {
	    "value": 233,
	    "day": "2016-11-13"
	  },
	  {
	    "value": 167,
	    "day": "2017-07-26"
	  },
	  {
	    "value": 130,
	    "day": "2015-06-24"
	  },
	  {
	    "value": 286,
	    "day": "2017-07-03"
	  },
	  {
	    "value": 385,
	    "day": "2017-12-11"
	  },
	  {
	    "value": 393,
	    "day": "2016-04-05"
	  },
	  {
	    "value": 329,
	    "day": "2018-05-15"
	  },
	  {
	    "value": 280,
	    "day": "2018-02-05"
	  },
	  {
	    "value": 368,
	    "day": "2015-11-01"
	  },
	  {
	    "value": 4,
	    "day": "2017-04-20"
	  },
	  {
	    "value": 123,
	    "day": "2016-03-16"
	  },
	  {
	    "value": 56,
	    "day": "2017-07-30"
	  },
	  {
	    "value": 203,
	    "day": "2016-03-06"
	  },
	  {
	    "value": 308,
	    "day": "2015-07-09"
	  },
	  {
	    "value": 74,
	    "day": "2015-04-21"
	  },
	  {
	    "value": 22,
	    "day": "2018-01-10"
	  },
	  {
	    "value": 84,
	    "day": "2018-06-02"
	  },
	  {
	    "value": 202,
	    "day": "2015-09-21"
	  },
	  {
	    "value": 51,
	    "day": "2018-01-09"
	  },
	  {
	    "value": 33,
	    "day": "2016-10-18"
	  },
	  {
	    "value": 143,
	    "day": "2016-03-24"
	  },
	  {
	    "value": 164,
	    "day": "2016-09-07"
	  },
	  {
	    "value": 190,
	    "day": "2015-12-15"
	  },
	  {
	    "value": 240,
	    "day": "2016-04-24"
	  },
	  {
	    "value": 310,
	    "day": "2015-08-08"
	  },
	  {
	    "value": 308,
	    "day": "2017-07-17"
	  },
	  {
	    "value": 134,
	    "day": "2017-12-19"
	  },
	  {
	    "value": 209,
	    "day": "2016-10-15"
	  },
	  {
	    "value": 396,
	    "day": "2018-06-25"
	  },
	  {
	    "value": 140,
	    "day": "2015-07-29"
	  },
	  {
	    "value": 333,
	    "day": "2016-05-08"
	  },
	  {
	    "value": 353,
	    "day": "2017-11-11"
	  },
	  {
	    "value": 65,
	    "day": "2016-05-31"
	  },
	  {
	    "value": 57,
	    "day": "2017-01-12"
	  },
	  {
	    "value": 238,
	    "day": "2016-10-22"
	  },
	  {
	    "value": 111,
	    "day": "2015-12-25"
	  },
	  {
	    "value": 172,
	    "day": "2015-11-13"
	  },
	  {
	    "value": 58,
	    "day": "2017-01-15"
	  },
	  {
	    "value": 192,
	    "day": "2015-10-12"
	  },
	  {
	    "value": 134,
	    "day": "2017-08-21"
	  },
	  {
	    "value": 16,
	    "day": "2018-02-22"
	  },
	  {
	    "value": 142,
	    "day": "2015-11-27"
	  },
	  {
	    "value": 333,
	    "day": "2016-05-29"
	  },
	  {
	    "value": 245,
	    "day": "2016-06-23"
	  },
	  {
	    "value": 41,
	    "day": "2016-03-07"
	  },
	  {
	    "value": 171,
	    "day": "2017-03-31"
	  },
	  {
	    "value": 281,
	    "day": "2016-01-30"
	  },
	  {
	    "value": 151,
	    "day": "2015-08-14"
	  },
	  {
	    "value": 159,
	    "day": "2015-12-14"
	  },
	  {
	    "value": 51,
	    "day": "2015-04-02"
	  },
	  {
	    "value": 258,
	    "day": "2015-09-14"
	  },
	  {
	    "value": 150,
	    "day": "2016-12-01"
	  },
	  {
	    "value": 259,
	    "day": "2017-08-01"
	  },
	  {
	    "value": 373,
	    "day": "2015-05-08"
	  },
	  {
	    "value": 347,
	    "day": "2017-09-15"
	  },
	  {
	    "value": 392,
	    "day": "2016-12-22"
	  },
	  {
	    "value": 52,
	    "day": "2018-02-10"
	  },
	  {
	    "value": 257,
	    "day": "2017-09-06"
	  },
	  {
	    "value": 111,
	    "day": "2017-08-13"
	  },
	  {
	    "value": 59,
	    "day": "2015-10-24"
	  },
	  {
	    "value": 139,
	    "day": "2017-02-27"
	  },
	  {
	    "value": 139,
	    "day": "2016-09-28"
	  },
	  {
	    "value": 162,
	    "day": "2017-02-19"
	  },
	  {
	    "value": 275,
	    "day": "2016-10-03"
	  },
	  {
	    "value": 131,
	    "day": "2017-10-11"
	  },
	  {
	    "value": 368,
	    "day": "2017-05-18"
	  },
	  {
	    "value": 242,
	    "day": "2017-02-06"
	  },
	  {
	    "value": 171,
	    "day": "2017-03-12"
	  },
	  {
	    "value": 357,
	    "day": "2017-02-13"
	  },
	  {
	    "value": 345,
	    "day": "2016-10-27"
	  },
	  {
	    "value": 106,
	    "day": "2016-01-01"
	  },
	  {
	    "value": 229,
	    "day": "2016-01-04"
	  },
	  {
	    "value": 103,
	    "day": "2017-10-16"
	  },
	  {
	    "value": 380,
	    "day": "2015-07-17"
	  },
	  {
	    "value": 151,
	    "day": "2016-12-10"
	  },
	  {
	    "value": 342,
	    "day": "2015-11-03"
	  },
	  {
	    "value": 22,
	    "day": "2018-05-29"
	  },
	  {
	    "value": 89,
	    "day": "2016-12-25"
	  },
	  {
	    "value": 266,
	    "day": "2016-09-16"
	  },
	  {
	    "value": 134,
	    "day": "2015-11-26"
	  },
	  {
	    "value": 117,
	    "day": "2015-12-02"
	  },
	  {
	    "value": 117,
	    "day": "2017-04-08"
	  },
	  {
	    "value": 177,
	    "day": "2015-10-28"
	  },
	  {
	    "value": 101,
	    "day": "2017-12-31"
	  },
	  {
	    "value": 245,
	    "day": "2017-06-28"
	  },
	  {
	    "value": 193,
	    "day": "2017-12-12"
	  },
	  {
	    "value": 257,
	    "day": "2017-02-03"
	  },
	  {
	    "value": 190,
	    "day": "2017-03-08"
	  },
	  {
	    "value": 359,
	    "day": "2017-11-25"
	  },
	  {
	    "value": 224,
	    "day": "2016-08-23"
	  },
	  {
	    "value": 267,
	    "day": "2017-05-12"
	  },
	  {
	    "value": 381,
	    "day": "2016-12-27"
	  },
	  {
	    "value": 104,
	    "day": "2017-06-26"
	  },
	  {
	    "value": 52,
	    "day": "2017-12-15"
	  },
	  {
	    "value": 148,
	    "day": "2018-05-25"
	  },
	  {
	    "value": 354,
	    "day": "2015-06-18"
	  },
	  {
	    "value": 308,
	    "day": "2016-09-01"
	  },
	  {
	    "value": 355,
	    "day": "2017-07-31"
	  },
	  {
	    "value": 262,
	    "day": "2015-06-09"
	  },
	  {
	    "value": 384,
	    "day": "2015-12-24"
	  },
	  {
	    "value": 190,
	    "day": "2015-08-19"
	  },
	  {
	    "value": 14,
	    "day": "2016-10-16"
	  },
	  {
	    "value": 210,
	    "day": "2017-12-26"
	  },
	  {
	    "value": 192,
	    "day": "2017-08-20"
	  },
	  {
	    "value": 69,
	    "day": "2017-11-04"
	  },
	  {
	    "value": 356,
	    "day": "2015-07-20"
	  },
	  {
	    "value": 122,
	    "day": "2017-01-02"
	  },
	  {
	    "value": 86,
	    "day": "2016-07-18"
	  },
	  {
	    "value": 246,
	    "day": "2015-08-25"
	  },
	  {
	    "value": 332,
	    "day": "2016-05-23"
	  },
	  {
	    "value": 134,
	    "day": "2017-08-23"
	  },
	  {
	    "value": 224,
	    "day": "2016-05-15"
	  },
	  {
	    "value": 104,
	    "day": "2018-02-12"
	  },
	  {
	    "value": 87,
	    "day": "2017-06-08"
	  },
	  {
	    "value": 363,
	    "day": "2018-05-20"
	  },
	  {
	    "value": 33,
	    "day": "2016-08-05"
	  },
	  {
	    "value": 336,
	    "day": "2016-09-13"
	  },
	  {
	    "value": 89,
	    "day": "2017-09-03"
	  },
	  {
	    "value": 241,
	    "day": "2015-12-12"
	  },
	  {
	    "value": 7,
	    "day": "2016-03-30"
	  },
	  {
	    "value": 252,
	    "day": "2017-02-11"
	  },
	  {
	    "value": 149,
	    "day": "2017-04-27"
	  },
	  {
	    "value": 63,
	    "day": "2015-12-27"
	  },
	  {
	    "value": 247,
	    "day": "2016-06-30"
	  },
	  {
	    "value": 109,
	    "day": "2016-08-31"
	  },
	  {
	    "value": 282,
	    "day": "2015-05-09"
	  },
	  {
	    "value": 3,
	    "day": "2015-09-07"
	  },
	  {
	    "value": 35,
	    "day": "2017-06-27"
	  },
	  {
	    "value": 44,
	    "day": "2016-04-16"
	  },
	  {
	    "value": 36,
	    "day": "2017-11-08"
	  },
	  {
	    "value": 298,
	    "day": "2017-03-10"
	  },
	  {
	    "value": 134,
	    "day": "2017-07-28"
	  },
	  {
	    "value": 188,
	    "day": "2017-12-24"
	  },
	  {
	    "value": 125,
	    "day": "2015-06-28"
	  },
	  {
	    "value": 258,
	    "day": "2015-08-24"
	  },
	  {
	    "value": 248,
	    "day": "2017-11-15"
	  },
	  {
	    "value": 70,
	    "day": "2017-12-29"
	  },
	  {
	    "value": 21,
	    "day": "2016-01-03"
	  },
	  {
	    "value": 287,
	    "day": "2016-07-13"
	  },
	  {
	    "value": 111,
	    "day": "2017-12-22"
	  },
	  {
	    "value": 188,
	    "day": "2017-05-01"
	  },
	  {
	    "value": 137,
	    "day": "2018-08-05"
	  },
	  {
	    "value": 216,
	    "day": "2017-10-13"
	  },
	  {
	    "value": 163,
	    "day": "2016-02-22"
	  },
	  {
	    "value": 289,
	    "day": "2016-05-09"
	  },
	  {
	    "value": 14,
	    "day": "2016-01-20"
	  }
	]


	return (
		<>
			<Detector
				polling={{ timeout: 7000 }}
				render={({ online }) => (
					<>
						{!online && (
							<div className="offline_container">
								<div className="offline_inner animate__animated animate__slideInDown">
									<i className="fa-solid fa-heart-crack"></i>
									<div className="offline_content">
										<p className="offline_title">You are offline</p>
										<p className="offline_subtitle">Please check your internet connect.</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			/>
			
			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={collapseFilters ? styles.main_container : styles.main_container_full}>
								<NavigationBar 
									param={param}
									active={"Reports"}
									handleLogout={handleLogout}
									collapseDeg={collapseDeg}
									setCollapseDeg={setCollapseDeg}
									collapseFilters={collapseFilters}
									setCollapseFilters={setCollapseFilters}
								/>
								<ReportsPage {...{ param, changeNavType, navType, search_control, popUpControls, indPopUpControls, lineChartData, calendarChartData, staticCharts }} />
							</div>
							: <Forbidden />
						}
					</>
				:
					<Loading current={"Reports"} />
			}
		</>
	);
};


export default Reports;