import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import ofglogo from '../../images/logo.png'
import citysvg from '../../images/city.svg'
import styles from "./Register.module.css"


const RegisterModule = ({ handleChange, data, error, isAuthenticated, newAccountData, registerPage, popUpControls, validationError, roleBool }) => {
	return (
		<div className={styles.bg}>
			<div className={styles.container}>
			  <div className={styles.outerSquare}>
			   <a href="/login"><img className={styles.logo} src={ofglogo} /></a>
			    <div className={styles.leftSide}>
			      {registerPage == 1 && <h1>OfficeFruit Registration</h1>}
			      {registerPage == 2 && <h1>Secondary Contact</h1>}
			      {registerPage == 3 && <h1>Accountant Contact</h1>}
			      <p>Welcome, please follow the registration process.</p>

			      	<div className={styles.formscontainer}>
			      	{registerPage == 1 &&
			      		<>
				      	<div className={styles.formWrapper}>
					        <label>Company</label>
					        <input
					          type="text"
					          value={data && data.company ? data.company : "-"}
					          disabled={true}
					        />
				        </div>

				      	<div className={styles.formWrapper}>
					        <label htmlFor="email">Primary email</label>
					        <input
					       	  className={styles.input_line}
					          type="text"
					          value={data ? data.email : "-"}
					          disabled={true}
					        />
				        </div>

				        <div className={styles.formWrapper}>
					        <label htmlFor="password">Password</label>
					        <input
					          className={styles.bottom_line}
						      type="password"
						      name="password"
						      placeholder="*********"
						      onChange={handleChange}
						      value={newAccountData && newAccountData.password}
					        />
				        </div>
				        </>
			    	}

			      	{registerPage == 2 && !roleBool &&
			      		<>
				      	<div className={styles.formWrapper}>
					        <label htmlFor="scontact_fname">First name</label>
					        <input
					       	  name="scontact_fname"
					          type="text"
					          placeholder="John"
						      onChange={handleChange}
						      value={newAccountData.scontact_fname}
					        />
				        </div>
				      	<div className={styles.formWrapper}>
					        <label htmlFor="scontact_lname">Last name</label>
					        <input
					       	  className={styles.input_line}
					       	  name="scontact_lname"
					          type="text"
					          placeholder="Doe"
						      onChange={handleChange}
						      value={newAccountData.scontact_lname}
					        />
				        </div>
				      	<div className={styles.formWrapper}>
					        <label htmlFor="scontact_email">Email</label>
					        <input
					       	  className={styles.input_line}
					       	  name="scontact_email"
					          type="text"
					          placeholder="johndoe@company.com.au"
						      onChange={handleChange}
						      value={newAccountData.scontact_email}
					        />
				        </div>
				      	<div className={styles.formWrapper}>
					        <label htmlFor="scontact_phone">Phone</label>
					        <input
					       	  className={styles.bottom_line}
					       	  name="scontact_phone"
					          type="text"
					          placeholder="+61 412 345 678"
						      onChange={handleChange}
						      value={newAccountData.scontact_phone}
					        />
				        </div>
				       	</>
			    	}

			      	{registerPage == 3 && !roleBool &&
			      		<>
				      	<div className={styles.formWrapper}>
					        <label htmlFor="acontact_fname">First name</label>
					        <input
					       	  name="acontact_fname"
					          type="text"
					          placeholder="John"
						      onChange={handleChange}
						      value={newAccountData.acontact_fname}
					        />
				        </div>
				      	<div className={styles.formWrapper}>
					        <label htmlFor="acontact_lname">Last name</label>
					        <input
					       	  className={styles.input_line}
					       	  name="acontact_lname"
					          type="text"
					          placeholder="Doe"
						      onChange={handleChange}
						      value={newAccountData.acontact_lname}
					        />
				        </div>
				      	<div className={styles.formWrapper}>
					        <label htmlFor="acontact_email">Email</label>
					        <input
					       	  className={styles.input_line}
					       	  name="acontact_email"
					          type="text"
					          placeholder="johndoe@company.com.au"
						      onChange={handleChange}
						      value={newAccountData.acontact_email}
					        />
				        </div>
				      	<div className={styles.formWrapper}>
					        <label htmlFor="acontact_phone">Phone</label>
					        <input
					       	  className={styles.bottom_line}
					       	  name="acontact_phone"
					          type="text"
					          placeholder="+61 412 345 678"
						      onChange={handleChange}
						      value={newAccountData.acontact_phone}
					        />
				        </div>
				       	</>
			    	}
			    	</div>


					{validationError &&
						<div className={`${styles.notification} ${styles.validationError}`}>
							<p className={styles.smaller_font}>{validationError}</p>
						</div>
					}

			        <div className={styles.controls}>
			        	<div className={styles.controlsinner}>
			        		{registerPage != 1 &&
						        <button
						          className={styles.prevBtn}
						          type="button"
						          disabled={!isAuthenticated}
						          onClick={() => popUpControls('prev')}
						        >
						          Back
						        </button>
					    	}

					    	{registerPage != 3 && !roleBool &&
						        <button
						          className={styles.nextBtn}
						          type="button"
						          disabled={!isAuthenticated}
						          onClick={() => popUpControls('next')}
						        >
						          Next
						        </button>
					    	}

					    	{registerPage == 3 &&
						        <button
						          className={styles.submitBtn}
						          type="button"
						          disabled={!isAuthenticated}
						          onClick={() => popUpControls('submit')}
						        >
						          Submit
						        </button>
					    	}

					    	{roleBool &&
						        <button
						          className={styles.submitBtn}
						          type="button"
						          disabled={!isAuthenticated}
						          onClick={() => popUpControls('submit')}
						        >
						          Submit
						        </button>
					    	}
				        </div>
			      </div>
			      <div className={styles.followus}>
			        <p>follow us</p>
			        <div className={styles.socials}>
			          <a>
			            <p>website</p>
			          </a>
			          <a>
			            <p>instagram</p>
			          </a>
			          <a>
			            <p>facebook</p>
			          </a>
			        </div>
			      </div>

			      {error &&
			        <div className={`${styles.notification} ${styles.error}`}>
			          <p>{error}</p>
			        </div>
			      }

			    </div>
			    <div className={styles.rightSide}>
			      <img className={styles.citySvg} src={citysvg} />
			    </div>
			  </div>

		  </div>
		</div>
	)
}


const Register = () => {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [newAccountData, setNewAccountData] = useState({});
	const [roleBool, setRoleBool] = useState(false);

	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const [validationError, setValidationError] = useState(null);
	const [registerPage, setRegisterPage] = useState(1);


	const param = useParams();
	const url = `/api/auth/${param._id}/register/${param.token}`;

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url);
					setData(data.data[0])
					if (data.data[1] || data.data[3]) {
						setRoleBool(true)
						// make skip the pages for signing up.
					}

					if (data.data[2]) {
						setIsLoading(true)
						await axios.post(`/api/auth/${param._id}/reverify/${param.token}`, {
							account_data: data.data[0]
						});
						navigate(`/login`)
					}
					
					setIsAuthenticated(true)
				} catch(e) {
					setError(e.response.data.message)
					setIsAuthenticated(false);
				}

				setIsLoading(false)

			} catch (error) {
				console.log(error)
			}
		};
		initLoad();
	}, [param, url]);


	function isValidPassword(password, companyName) {
		const minLength = 8;
		const uppercaseRegex = /[A-Z]/;
		const lowercaseRegex = /[a-z]/;
		const numericRegex = /[0-9]/;
		const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

		if (password.length < minLength) {
			return "The password length must be greater than or equal to 8 characters";
		}

		if (!uppercaseRegex.test(password)) {
			return "The password must contain one or more uppercase characters";
		}

		if (!lowercaseRegex.test(password)) {
			return "The password must contain one or more lowercase characters";
		}

		if (!numericRegex.test(password)) {
			return "The password must contain one or more numeric values";
		}

		if (!specialCharRegex.test(password)) {
			return "The password must contain one or more special characters";
		}

		if (companyName && password.toLowerCase().includes(companyName.toLowerCase())) {
			return "The password can not contain the company name";
		}

		return true;
	}

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	const handleChange = ({ currentTarget: input }) => {
		setNewAccountData({ ...newAccountData, [input.name]: input.value });
	};

	const popUpControls = async (control) => {
		switch(control) {
			case 'prev':
				setRegisterPage(registerPage-1);
				break;
			case 'next':
				if (registerPage == 1) {
					if (!(newAccountData.hasOwnProperty('password')) || newAccountData['password'] == '') {
						setValidationError(`Please enter a password.`)
						return false;
					} else {
						if (isValidPassword(newAccountData['password'], data.company) !== true) {
							setValidationError(isValidPassword(newAccountData['password'], data.company))
							return false;
						}
					}
				}

				if (registerPage == 2) {
					if (!(newAccountData.hasOwnProperty('scontact_fname')) || newAccountData['scontact_fname'] == '') {
						setValidationError(`Please enter a secondary contact first name.`)
						return false;
					}
					if (!(newAccountData.hasOwnProperty('scontact_lname')) || newAccountData['scontact_lname'] == '') {
						setValidationError(`Please enter a secondary contact last name.`)
						return false;
					}
					if (!(newAccountData.hasOwnProperty('scontact_email')) || newAccountData['scontact_email'] == '') {
						setValidationError(`Please enter a secondary contact email address.`)
						return false;
					} else {
						if (!(isValidEmail(newAccountData.scontact_email))) {
							setValidationError(`Please enter a valid email address.`)
							return false;
						}
					}
					if (!(newAccountData.hasOwnProperty('scontact_phone')) || newAccountData['scontact_phone'] == '') {
						setValidationError(`Please enter a secondary contact phone number.`)
						return false;
					}
				}
				if (registerPage == 3) {
					if (!(newAccountData.hasOwnProperty('acontact_fname')) || newAccountData['acontact_fname'] == '') {
						setValidationError(`Please enter an accountant contact first name.`)
						return false;
					}
					if (!(newAccountData.hasOwnProperty('acontact_lname')) || newAccountData['acontact_lname'] == '') {
						setValidationError(`Please enter an accountant contact last name.`)
						return false;
					}
					if (!(newAccountData.hasOwnProperty('acontact_email')) || newAccountData['acontact_email'] == '') {
						setValidationError(`Please enter an accountant contact email address.`)
						return false;
					} else {
						if (!(isValidEmail(newAccountData.acontact_email))) {
							setValidationError(`Please enter a valid email address.`)
							return false;
						}
					}
					if (!(newAccountData.hasOwnProperty('acontact_phone')) || newAccountData['acontact_phone'] == '') {
						setValidationError(`Please enter an accountant contact phone number.`)
						return false;
					}
				}
				setValidationError(null)
				setRegisterPage(registerPage+1)
				break;
			case 'submit':
				if (roleBool) {
					if (!(newAccountData.hasOwnProperty('password')) || newAccountData['password'] == '') {
						setValidationError(`Please enter a password.`)
						return false;
					} else {
						if (isValidPassword(newAccountData['password'], data.company) !== true) {
							setValidationError(isValidPassword(newAccountData['password'], data.company))
							return false;
						}
					}
				} else {
					if (!(newAccountData.hasOwnProperty('acontact_fname')) || newAccountData['acontact_fname'] == '') {
						setValidationError(`Please enter an accountant contact first name.`)
						return false;
					}
					if (!(newAccountData.hasOwnProperty('acontact_lname')) || newAccountData['acontact_lname'] == '') {
						setValidationError(`Please enter an accountant contact last name.`)
						return false;
					}
					if (!(newAccountData.hasOwnProperty('acontact_email')) || newAccountData['acontact_email'] == '') {
						setValidationError(`Please enter an accountant contact email address.`)
						return false;
					} else {
						if (!(isValidEmail(newAccountData.acontact_email))) {
							setValidationError(`Please enter a valid email address.`)
							return false;
						}
					}
					if (!(newAccountData.hasOwnProperty('acontact_phone')) || newAccountData['acontact_phone'] == '') {
						setValidationError(`Please enter an accountant contact phone number.`)
						return false;
					}
				}

				setValidationError(null)
				setIsLoading(true)
				await axios.post(`/api/auth/${param._id}/register/${param.token}`, {
					account_data: newAccountData
				});

				navigate(`/login`)
				break;
		}
	}


	return (
		<>
			{!isLoading
				?
					<RegisterModule {...{ handleChange, data, error, isAuthenticated, newAccountData, registerPage, popUpControls, validationError, roleBool }} />
				:
					<div className={styles.load_container}>
						<div className={styles.load}>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
			}
		</>
	);
};

export default Register;