import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from "./Warehouse.module.css"

import ReactAutocomplete from "react-google-autocomplete";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const PopUpBulk = ({ param, popUpPage, popUpControls, validationError, formatOptions, allInventory, handleBulk, bulkProducts, changeBulkCategory, bulkCategory }) => {
	return (
		<div className={`${styles.fixed_container_height_bulk}`}>
			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Bulk update</h1>
					<p className={styles.smaller_font}>Use this module to bulk update inventory prices and stock.</p>

					<div className={`${styles.innersitenav}`}>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Fruit" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Fruit")}><p>Fruit</p></button>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Milk" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Milk")}><p>Milk</p></button>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Bread" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Bread")}><p>Bread</p></button>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Coffee" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Coffee")}><p>Coffee</p></button>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Cakes" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Cakes")}><p>Cakes</p></button>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Catering" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Catering")}><p>Catering</p></button>
					</div>


					<div className={`${styles.draggableContainerBulkUpdate}`}>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Item selection</label>
							<Select
								isMulti={true}
								closeMenuOnSelect={false}
								options={formatOptions(allInventory.filter(item => item.category.toLowerCase() === bulkCategory.toLowerCase()).map(item => item.item_name))}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb'
									}),
								}}
								onChange={(selected) => handleBulk('add', selected)}
								value={bulkProducts[bulkCategory] ? formatOptions(bulkProducts[bulkCategory].map(item => item.item_name)) : []}
							/>
						</div>

						<div className={styles.draggableContainerBulk}>
							{(bulkProducts[bulkCategory] || []).map((BP, index) => (
								<div className={`${styles.popup_form_bulk}`}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Product</label>
										<input type="text" disabled={true} value={BP.item_name}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Purchase price</label>
										<input type="text" value={BP.purchase_price} onChange={(selected) => handleBulk('update', '-', index, 'purchase_price', selected)}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Sale price</label>
										<input type="text" value={BP.sale_price} onChange={(selected) => handleBulk('update', '-', index, 'sale_price', selected)}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Stock</label>
										<input type="text" value={BP.stock} onChange={(selected) => handleBulk('update', '-', index, 'stock', selected)}/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Seasonal</label>
										<div className={`${styles.radio_container} ${styles.align_bulk_radio} ${(allInventory.filter(item => item._id.toString() === BP._id.toString())[0].category !== "Fruit" && allInventory.filter(item => item._id.toString() === BP._id.toString())[0].is_seasonal === false) && styles.hidden_radio_container}`}>
											<label className={styles.radio_button}>Yes
										  		<input type="radio" name={`radio${index}`} checked={(BP.is_seasonal === true) && "checked"} onChange={(selected) => handleBulk('update', '-', index, 'is_seasonal', {value: true})}/>
										    	<span className={styles.checkmark}></span>
										  	</label>

										  	<label className={styles.radio_button}>No
										    	<input type="radio" name={`radio${index}`} checked={(BP.is_seasonal === false) && "checked"} onChange={(selected) => handleBulk('update', '-', index, 'is_seasonal', {value: false})}/>
										    	<span className={styles.checkmark}></span>
										  	</label>
										</div>
									</div>
								</div>
							))}
						</div>

					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

					<div className={`${styles.innersitenav}`}>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Fruit" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Fruit")}><p>Fruit</p></button>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Milk" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Milk")}><p>Milk</p></button>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Bread" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Bread")}><p>Bread</p></button>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Coffee" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Coffee")}><p>Coffee</p></button>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Cakes" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Cakes")}><p>Cakes</p></button>
						<button className={`${styles.sitenav_box} ${bulkCategory == "Catering" && styles.sitenav_active}`} onClick={() => changeBulkCategory("Catering")}><p>Catering</p></button>
					</div>

					<div className={`${styles.draggableContainerBulk} ${styles.extendedMargin}`}>
						{(bulkProducts[bulkCategory] || []).map((BP, index) => (
							<div className={`${styles.popup_form_bulk}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Product</label>
									<input type="text" disabled={true} value={BP.item_name}/>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Purchase price</label>
									<input type="text" value={BP.purchase_price} disabled={true}/>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Sale price</label>
									<input type="text" value={BP.sale_price} disabled={true}/>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Stock</label>
									<input type="text" value={BP.stock} disabled={true}/>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Seasonal</label>
									<div className={`${styles.radio_container} ${styles.align_bulk_radio} ${styles.hidden_radio_container}`}>
										<label className={styles.radio_button}>Yes
									  		<input type="radio" name={`radio${index}`} checked={(BP.is_seasonal === true) && "checked"} onChange={(selected) => handleBulk('update', '-', index, 'is_seasonal', {value: true})}/>
									    	<span className={styles.checkmark}></span>
									  	</label>

									  	<label className={styles.radio_button}>No
									    	<input type="radio" name={`radio${index}`} checked={(BP.is_seasonal === false) && "checked"} onChange={(selected) => handleBulk('update', '-', index, 'is_seasonal', {value: false})}/>
									    	<span className={styles.checkmark}></span>
									  	</label>
									</div>
								</div>
							</div>
						))}
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError} ${styles.customValidation}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={`${styles.popUpControls} ${styles.controls_bottom}`}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 2 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 2 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpBulk;