// mapUtils.js
import mapboxgl from 'mapbox-gl';
import axios from 'axios';

const initializeMapWithAccessToken = async (param, zoom) => {
  const url = `/api/admin/warehouse/mapbox/${param._id}`;
  const data = await axios.get(url);

  mapboxgl.accessToken = data.data.access_token;

  return new Promise((resolve) => {
    const createMap = () => {
      const mapContainer = document.getElementById('map');
      if (mapContainer && mapContainer.childNodes.length == 0) {
        const map = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/hikasdasd/clduslmz4000401qsu6p53z6o',
          center: ['144.96701770519513', '-37.81532629828826'],
          zoom: zoom,
        });

        // Add markers to the map
        const markerData = [
          { lngLat: [144.96701770519513, -37.81532629828826], title: 'Marker 1' },
        ];

        markerData.forEach((markerInfo) => {

          const { lngLat, title } = markerInfo;
          const marker = new mapboxgl.Marker()
            .setLngLat(lngLat)
            .addTo(map);
        });

        resolve(map);
      } else {
        // Container not available, wait for the next frame and try again
        requestAnimationFrame(createMap);
      }
    };

    createMap();
  });
};

export default initializeMapWithAccessToken;