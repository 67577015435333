import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ResizableDiv from '../Utils/ResizableDiv';
import styles from "./Dashboard.module.css"

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpIndPreference = ({ param, indPopUpControls, allPreferences, indPreference, nextDeliveryDate, formatDate, inventoryData, changeIndSiteNav, indSiteNav, changeStatus, changingStatus, searchControl, boxActive, handleTempCancel, allTempCancels, handleClone, weekActive, getTotalQuantity, inactiveDatePopup, inactiveDate }) => {
	const itemKeys = Object.keys(indPreference).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1]));

	return (
		<div className={styles.indsite_container}>
			<ResizableDiv className="indsite_popup" startingWidth="40">

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
					<button className={styles.nextPopup} onClick={() => handleClone('open')}><i className="fa-solid fa-clone"></i></button>
					<button className={styles.otherPopup} onClick={() => indPopUpControls('temp_change', indPreference)}><i className="fa-solid fa-clock-rotate-left"></i></button>
					<button className={styles.finPopup} onClick={() => indPopUpControls('delete', indPreference)}><i className="fa-solid fa-trash-can"></i></button>

					<h1 className={styles.medium_font}>Preference {indSiteNav.toLowerCase()}</h1>
					<p className={styles.smaller_font}>Please find the specific {indSiteNav.toLowerCase()} for your order here.</p>


					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Details' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Details')} disabled={changingStatus || inactiveDatePopup}><p>Details</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Items' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Items')} disabled={changingStatus || inactiveDatePopup}><p>Items</p></button>
							<button className={`${styles.sitenav_box} ${indSiteNav == 'Cancellations' && styles.sitenav_active}`} onClick={() => changeIndSiteNav('Cancellations')} disabled={changingStatus || inactiveDatePopup}><p>Cancellations</p></button>
						</div>
					</div>

					{indSiteNav == "Details" &&
						<div className={`${styles.sites_tablecontainer} ${(changingStatus || inactiveDatePopup) && styles.hidden_container}`}>
							<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Preference code</label>
									<p>{indPreference.preference_code}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Preference type</label>
									<p>Ongoing</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Company</label>
									<a onClick={() => indPopUpControls('navigate', 'Account')} className={styles.clickable_nav}><p>{indPreference.company}</p></a>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Site name</label>
									<a onClick={() => indPopUpControls('navigate', 'Site')} className={styles.clickable_nav}><p>{indPreference.site_name}</p></a>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Address</label>
									<a className={styles.clickable_nav} href={`http://maps.google.com/?q=${indPreference.site_location.hasOwnProperty('level') && `Level ${indPreference.site_location.level}, `}, ${indPreference.site_location.street}, ${indPreference.site_location.city} ${indPreference.site_location.state} ${indPreference.site_location.postcode}, ${indPreference.site_location.country}`} target="_blank"><p>{indPreference.site_location.hasOwnProperty('level') && `Level ${indPreference.site_location.level}, `}{indPreference.site_location.street}, {indPreference.site_location.city} {indPreference.site_location.state} {indPreference.site_location.postcode}, {indPreference.site_location.country}</p></a>
								</div>

								{/* FIND BETTER SOLUTION WITHOUT INLINE */}
								<div className={styles.popup_formbox} style={{position: 'relative'}}>
									<div style={{position: 'absolute'}}>
										<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px', gridTemplateColumns: 'max-content'}}>
											<label className={styles.smaller_font}>Status</label>
							    			<button onClick={() => changeStatus('toggle')} className={`${styles.site_status} ${indPreference.status == "Pending" && styles.status_pending} ${(indPreference.status == "Active" || indPreference.status == "Completed") && styles.status_active} ${indPreference.status == "Inactive" && styles.status_inactive}`}>
							    				<p className={styles.smaller_font}>{indPreference.status}</p>
							    			</button>
						    			</div>

						    			{indPreference.hasOwnProperty('inactive_commencement') &&
						    				<p className={`${styles.smaller_font}`}>Becoming inactive: {formatDate(indPreference.inactive_commencement)}</p>
						    			}
					    			</div>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Delivery day</label>
									<p>{indPreference['delivery_day'] ? indPreference.delivery_day : '-'}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Next delivery</label>
									<p>{indPreference.delivery_day && formatDate(nextDeliveryDate(indPreference.delivery_day, indPreference.commencement_date, indPreference.last_generated)) || '-'}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Frequency</label>
									<p>{indPreference.frequency}</p>
								</div>

								{indPreference.frequency === "Monthly" &&
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Monthly Frequency</label>
										<p>{indPreference.monthly_frequency}</p>
									</div>
								}

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Product</label>
									<p>{indPreference.preference_type}</p>
								</div>

								{indPreference['box_type'] &&
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Box type</label>
										<p>{indPreference.box_type}</p>
									</div>
								}

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Total quantity</label>
									<p>{getTotalQuantity(indPreference)} items</p>
								</div>

								{indPreference.last_generated && (indPreference.last_generated !== indPreference.date_created) &&
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Last generated</label>
										<p>{formatDate(indPreference.last_generated)}</p>
									</div>
								}

							</div>
						</div>
					}

	    			{(changingStatus && !inactiveDatePopup) &&
	    				<div className={styles.statusOptions}>
	    					<button className={`${styles.closePopup} ${styles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

							<h1 className={styles.medium_font}>Change status</h1>
							<p className={`${styles.smaller_font}`}>Use this module to update the status for {indPreference.preference_code}</p>

							<div className={styles.statusOuter}>
								<div className={styles.statusInner}>
			    					{indPreference.status !== "Pending" &&
						    			<button onClick={() => changeStatus('set', 'Preference', indPreference, 'Pending')} className={`${styles.site_status} ${styles.status_pending}`}>
						    				<p className={styles.smaller_font}>Pending</p>
						    			</button>
					    			}

					    			{indPreference.status !== "Active" &&
						    			<button onClick={() => changeStatus('set', 'Preference', indPreference, 'Active')} className={`${styles.site_status} ${styles.status_active}`}>
						    				<p className={styles.smaller_font}>Active</p>
						    			</button>
						    		}

						    		{indPreference.status !== "Inactive" &&
						    			<button onClick={() => changeStatus('set', 'Preference', indPreference, 'Inactive')} className={`${styles.site_status} ${styles.status_inactive}`}>
						    				<p className={styles.smaller_font}>Inactive</p>
						    			</button>
						    		}
					    		</div>
				    		</div>
	    				</div>
	    			}

	    			{inactiveDatePopup &&
	    				<div className={styles.statusOptionsDate}>
	    					<button className={`${styles.closePopup} ${styles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

							<h1 className={styles.medium_font}>Set Inactive</h1>
							<p className={`${styles.smaller_font}`}>Use this module to update the status for {indPreference.preference_code} to inactive</p>

							<div className={`${styles.popup_form}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Starting date</label>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
									    <DatePicker
									        className={styles.overridebg}
									        localeText={{ start: '', end: '' }}
									        value={inactiveDate && inactiveDate}
									        onChange={(selected) => changeStatus('set_date', selected)}
									        disablePast
									        format="DD/MM/YYYY"
									    />
									</LocalizationProvider>
								</div>
							</div>

							<div className={styles.popUpControls}>
								<div className={styles.popUpControlsContainer}>
									<button type="button" className={styles.submitBtn} onClick={() => changeStatus('set', 'Preference', indPreference, 'Inactive')}>Submit</button>
								</div>
							</div>

	    				</div>
	    			}

					{indSiteNav == "Items" &&
						<div className={`${styles.sites_tablecontainer} ${
				            indPreference.items.reduce((count, item) => count + Array.isArray(item), 0) > 0 ?
				                ((indPreference.hasOwnProperty('freqDetails') && Object.keys(indPreference.freqDetails).length > 0) ? styles.indorder_seasonal_combined : styles.indorder_seasonal) :
				                ((indPreference.hasOwnProperty('freqDetails') && Object.keys(indPreference.freqDetails).length > 0) ? styles.indorder_seasonal : '')
				        }`}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>
							</div>

							{(indPreference && indPreference.hasOwnProperty('freqDetails') && Object.keys(indPreference.freqDetails).length > 0) &&
								<div className={styles.box_layout}>
									{itemKeys.map((key, index) => (
						    			<button onClick={() => indPopUpControls('handleWeek', index)}  className={`${styles.site_status} ${weekActive === index ? styles.week_active : styles.status_week}`}>
						    				<p className={styles.smaller_font}>Fortnight {index+1}</p>
						    			</button>
						    		))}
								</div>
							}

							{(indPreference && indPreference.items.reduce((count, item) => count + Array.isArray(item), 0) > 0) &&
								<div className={styles.box_layout}>
				    				{indPreference.items.map((item, index) => {
				    					return (
							    			<button onClick={() => indPopUpControls('handleBox', index)} className={`${styles.site_status} ${boxActive === index ? styles.box_active : styles.status_active}`}>
							    				<p className={styles.smaller_font}>Box {index+1}</p>
							    			</button>
								    	)
								    })}
								</div>
							}

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th>Item name</th>
								      	<th>Quantity</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{(indPreference['box_type'] === "Seasonal" || indPreference['box_type'] === "Flexi")
								    	?
								    		<>
								    			{(indPreference && indPreference.hasOwnProperty('freqDetails') && Object.keys(indPreference.freqDetails).length > 0)
								    				?
									    				<>
											    			{indPreference[`items_${weekActive+1}`] && indPreference[`items_${weekActive+1}`].length > 0
											    				? 
											    				<>
												    				{indPreference[`items_${weekActive+1}`][boxActive].map((II) => {
												    					return (
																	    	<tr className={styles.sitesind_tr}>
																	    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																	    		<td>{II.quantity} items</td>
																	    	</tr>
																    	)
																    })}

															    	<tr className={styles.sitesind_tr}>
															    		<td>Total Quantity</td>
															    		<td>{indPreference[`items_${weekActive+1}`][boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
															    	</tr>
															    </>
											    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
											    			}
										    			</>
								    				:
									    				<>
											    			{indPreference.items && indPreference.items.length > 0
											    				? 
											    				<>
												    				{indPreference.items[boxActive].map((II) => {
												    					return (
																	    	<tr className={styles.sitesind_tr}>
																	    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																	    		<td>{II.quantity} items</td>
																	    	</tr>
																    	)
																    })}

															    	<tr className={styles.sitesind_tr}>
															    		<td>Total Quantity</td>
															    		<td>{indPreference.items[boxActive].map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)}</td>
															    	</tr>
															    </>
											    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
											    			}
										    			</>
								    			}
								    		</>
								    	:
								    		<>
								    			{(indPreference && indPreference.hasOwnProperty('freqDetails') && Object.keys(indPreference.freqDetails).length > 0)
								    				?
								    					<>
											    			{indPreference[`items_${weekActive+1}`] && indPreference[`items_${weekActive+1}`].length > 0
											    				? 
											    				<>
												    				{indPreference[`items_${weekActive+1}`].map((II) => {
												    					return (
																	    	<tr className={styles.sitesind_tr}>
																	    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																	    		<td>{II.quantity} items</td>
																	    	</tr>
																    	)
																    })}
															    </>
											    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
											    			}
										    			</>
								    				:
								    					<>
											    			{indPreference.items && indPreference.items.length > 0
											    				? 
											    				<>
												    				{indPreference.items.map((II) => {
												    					return (
																	    	<tr className={styles.sitesind_tr}>
																	    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
																	    		<td>{II.quantity} items</td>
																	    	</tr>
																    	)
																    })}
															    </>
											    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any items.</h1>
											    			}
										    			</>
								    			}
								    		</>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}


					{indSiteNav == "Cancellations" &&
						<div className={`${styles.sites_tablecontainer}`}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table2")}
										/>
									</div>
								</div>

								<button className={styles.indsitebtn} onClick={() => handleTempCancel('toggle')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add cancellation</p>
								</button>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table2">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th colspan="3">Dates</th>
								      	<th colspan="1">Tools</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{allTempCancels.filter(temp => temp.preference.toString() === indPreference._id.toString()).length > 0
								    		?
								    		<>
							    				{allTempCancels.filter(temp => temp.preference.toString() === indPreference._id.toString()).map((IO) => {
							    					return (
												    	<tr className={styles.sitesind_tr}>
												    		<td colspan="3">{formatDate(IO.dates[0])} - {formatDate(IO.dates[1])}</td>
												    		<td colspan="1">
												    			<div className={styles.emailUploadedIcons}>
												    				<button onClick={() => handleTempCancel('delete', IO)}><i class="fa-solid fa-trash-can"></i></button>
												    			</div>
												    		</td>
												    	</tr>
											    	)
											    })}
								    		</>
								    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This preference does not have any cancellations!</h1>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}

				</div>
			</ResizableDiv>
		</div>
	)
}

export default PopUpIndPreference;