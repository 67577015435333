import Select from 'react-select';
import styles from "./Dashboard.module.css"

 
const PopUpSiteGroups = ({ param, handleSiteGroups, siteGroupsData, formatOptions, validationError, indAccount }) => {
	return (
		<div className={`${styles.popup_container}`}>
			<button className={styles.closePopup} onClick={() => handleSiteGroups('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Group {siteGroupsData['edit'] ? siteGroupsData['group_name'] : indAccount.site_groups && indAccount.site_groups.length+1 || "1"}</h1>
			<p className={styles.smaller_font}>Select the sites that you would like to group for invoicing.</p>

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Sites</label>
					<Select
						isMulti={true}
						closeMenuOnSelect={true}
						options={formatOptions(siteGroupsData.site_options)}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								boxShadow: 'none !important',
							   '&:hover': {
							       borderColor: '#fce7eb !important'
							    },
								borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
							}),
						}}
						onChange={(selected) => handleSiteGroups('set', selected)}
						value={siteGroupsData.sites_selected && formatOptions(siteGroupsData.sites_selected)}
					/>
				</div>
			</div>

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handleSiteGroups('submit')}>Submit</button>
				</div>
			</div>
		</div>
	)
}

export default PopUpSiteGroups;