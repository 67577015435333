import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import Forbidden from '../Forbidden';
import Loading from '../Loading';
import ofglogo from '../../images/logo.png'
import styles from "./Orders.module.css"
import ComingSoon from '../ComingSoon';

import ReactAutocomplete from "react-google-autocomplete"

import PopUpOrder from './PopUpOrder';

const NavigationBar = ({ param, handleLogout }) => {
	return (
		<div className={styles.sidebar_container}>
			<div className={styles.sidebar_account}>
				<i className={`fa-solid fa-circle-user ${styles.sidebar_icon}`} />
			</div>

			<div className={styles.sidebar_centered}>
				<div className={styles.sidebar_grid}>
					<Link to={`/dashboard/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-gauge-high ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/sites/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-location-dot ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/orders/${param._id}`}>
						<div className={`${styles.sidebar_box} ${styles.sidebar_active}`}>
							<i className={`fa-solid fa-box-open ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/dashboard/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-file-invoice-dollar ${styles.sidebar_icon}`} />
						</div>
					</Link>
				</div>
			</div>
		</div>
	)
}


const OrdersPage = ({ param, addOrder, orderPopup, ordersData, search_control, orderType, orderTypeNav, changeNavType, formatDate, toggleInd, indOrderPopup, ongoingOrders, nextDeliveryDate, formatDateProgress, formatDatePacking, formatOrders }) => {
	return (
		<div className={`${styles.page_container} ${(orderPopup || indOrderPopup) && styles.hidden_container}`}>
			<div className={`${styles.sites_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>Orders</h1>
						<button onClick={() => addOrder()}>
							<i className="fa-solid fa-plus"></i>
							<p className={styles.smaller_font}>Add order</p>
						</button>
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${orderType == 'Ongoing' && styles.sitenav_active}`} onClick={() => changeNavType('Ongoing')}><p>Ongoing</p></button>
						<button className={`${styles.sitenav_box} ${orderType == 'Upcoming' && styles.sitenav_active}`} onClick={() => changeNavType('Upcoming')}><p>Upcoming</p></button>
						<button className={`${styles.sitenav_box} ${orderType == 'History' && styles.sitenav_active}`} onClick={() => changeNavType('History')}><p>History</p></button>
					</div>
				</div>

				<div className={styles.sites_tablecontainer}>
					<div className={styles.sites_controls}>
						<div className={styles.sites_filters}>
							<div className={styles.filter_active}>
								<p className={styles.smaller_font}>All time</p>
								<i className="fa-solid fa-x"></i>
							</div>

							<div className={styles.filter_add}>
								<i class="fa-solid fa-filter"></i>
								<p className={styles.smaller_font}>More filters</p>
							</div>
						</div>
						<div className={styles.sites_searchcontainer}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input className={styles.smaller_font} type="text" placeholder="Search order code, site name or date..." id="searchbar" onKeyUp={event => search_control(event)} />
							</div>
						</div>
					</div>

					<div className={styles.table_container}>
						<table className={styles.sites_table} id="sites_table">
						    <thead>
						      <tr className={styles.table_rowth}>
						      	<th>Order code</th>
						      	<th>Order type</th>
						        <th>Site name</th>
						        <th>Quantity</th>
						        <th>{orderType == "Ongoing" ? "Delivery day" : "Delivery date"}</th>
						        <th>Status</th>
						      </tr>
						    </thead>
						    <tbody>

						    {orderType == "Ongoing" &&
						    	<>
									{(ongoingOrders && Object.keys(ongoingOrders).length > 0) &&
										<>
											{ongoingOrders.map((OO) => {
												return (
										    		<tr onClick={() => toggleInd(OO, true)}>
											    		<td>{OO.preference_code}</td>
											    		<td>{OO.preference_type}</td>
											    		<td>{OO.site_name}</td>

											    		<td>{OO.total_qty || '0'} items</td>
											    		<td>{OO['delivery_day'] ? OO.delivery_day : '-'}</td>
											    		<td className={styles.site_statustd}>
											    			<div className={`${styles.site_status} ${OO.status == "Pending" && styles.status_pending} ${(OO.status == "Active" || OO.status == "Completed") && styles.status_active} ${OO.status == "Inactive" && styles.status_inactive}`}>
											    				<p className={styles.smaller_font}>{OO.status}</p>
											    			</div>
											    		</td>
											    	</tr>
									    		)
									    	})}
										</>
									}
								</>
							}

						    {orderType == "Upcoming" &&
						    	<>
									{(ordersData && Object.keys(formatOrders(ordersData, false)).length > 0) &&
										<>
											{formatOrders(ordersData, false).map((OD) => {
												return (
										    		<tr onClick={() => toggleInd(OD, false)}>
											    		<td>{OD.order_code}</td>
											    		<td>{OD.order_type}</td>
											    		<td>{OD.site_name}</td>

											    		<td>{OD.total_qty || '0'} items</td>
											    		<td>{OD['delivery_date'] ? formatDate(OD.delivery_date) : '-'}</td>
											    		<td className={styles.site_statustd}>
											    			<div className={`${styles.site_status} ${OD.status == "Pending" && styles.status_pending} ${(OD.status == "Active" || OD.status == "Completed") && styles.status_active} ${OD.status == "Inactive" && styles.status_inactive}`}>
											    				<p className={styles.smaller_font}>{OD.status}</p>
											    			</div>
											    		</td>
											    	</tr>
									    		)
									    	})}
										</>
									}
								</>
							}

						    {orderType == "History" &&
						    	<>
									{(ordersData && Object.keys(formatOrders(ordersData, false)).length > 0) &&
										<>
											{formatOrders(ordersData, true).map((OD) => {
												return (
										    		<tr onClick={() => toggleInd(OD, false)}>
											    		<td>{OD.order_code}</td>
											    		<td>{OD.order_type}</td>
											    		<td>{OD.site_name}</td>

											    		<td>{OD.total_qty || '0'} items</td>
											    		<td>{OD['delivery_date'] ? formatDate(OD.delivery_date) : '-'}</td>
											    		<td className={styles.site_statustd}>
											    			<div className={`${styles.site_status} ${OD.status == "Pending" && styles.status_pending} ${(OD.status == "Active" || OD.status == "Completed") && styles.status_active} ${OD.status == "Inactive" && styles.status_inactive}`}>
											    				<p className={styles.smaller_font}>{OD.status}</p>
											    			</div>
											    		</td>
											    	</tr>
									    		)
									    	})}
										</>
									}
								</>
							}

						    </tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}


const IndPopUp = ({ param, indPopUpControls, indOrder, formatDate, inventoryData, indOngoing, orderTypeNav, nextDeliveryDate, convertToDay }) => {
	return (
		<div className={styles.indsite_container}>
			<div className={styles.indsite_popup}>

				<div className={styles.indsite_inner}>
					<div>
						<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
						{indOrder.status == "Active" && indOngoing &&
							<button className={styles.editPopup} onClick={() => indPopUpControls('edit', indOrder.site_name, indOrder.delivery_day, indOrder.preference_type)}><i className="fa-solid fa-pen"></i></button>
						}

						<h1 className={styles.medium_font}>Order details</h1>
						<p className={styles.smaller_font}>Please find the specific details for this order here.</p>
					</div>

					<div className={styles.ind_grid}>
						<div className={styles.indinfo_grid}>

							{!indOngoing
							?
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Order code</label>
								<p>{indOrder.order_code || '-'}</p>
							</div>
							:
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Order code</label>
								<p>{indOrder.preference_code || '-'}</p>
							</div>
							}

							{!indOngoing
							?
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Order type</label>
								<p>{indOrder.recurring && 'Ongoing' || indOrder.recurring_temp && 'Temporary' || indOrder.onetime && 'One-time'}</p>
							</div>
							:
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Order type</label>
								<p>Ongoing</p>
							</div>
							}

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Address</label>
								<p>{indOrder.site_location.street}, {indOrder.site_location.city} {indOrder.site_location.state} {indOrder.site_location.postcode}, {indOrder.site_location.country}</p>
							</div>

							{/* FIND BETTER SOLUTION WITHOUT INLINE */}
							<div className={styles.popup_formbox} style={{position: 'relative'}}>
								<div style={{position: 'absolute'}}>
									<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
										<label className={styles.smaller_font}>Status</label>
						    			<div className={`${styles.site_status} ${indOrder.status == "Pending" && styles.status_pending} ${(indOrder.status == "Active" || indOrder.status == "Completed") && styles.status_active} ${indOrder.status == "Inactive" && styles.status_inactive}`}>
						    				<p className={styles.smaller_font}>{indOrder.status}</p>
						    			</div>
					    			</div>
				    			</div>
							</div>



							{!indOngoing
							?
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Delivery day</label>
								<p>{convertToDay(indOrder.delivery_date)}</p>
							</div>
							:
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Delivery day</label>
								<p>{indOrder['delivery_day'] ? indOrder.delivery_day : '-'}</p>
							</div>
							}

							{!indOngoing
							?
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Delivery date</label>
								<p>{formatDate(indOrder.delivery_date)}</p>
							</div>
							:
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Next delivery</label>
								<p>{indOrder.delivery_day && formatDate(nextDeliveryDate(indOrder.delivery_day)) || '-'}</p>
							</div>
							}

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Frequency</label>
								<p>{indOrder.frequency || '-'}</p>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Total quantity</label>
								<p>{indOrder.total_qty} items</p>
							</div>

						</div>

						{/* orders per ind site */}
						<div className={styles.sites_tablecontainer}>
							<div className={styles.sites_controls}>
								<div className={styles.sites_searchcontainer}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input className={styles.smaller_font} type="text" placeholder="Search item name..." />
									</div>
								</div>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="sites_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th>Item name</th>
								      	<th>Quantity</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{indOrder.items &&
								    		<>
								    			{indOrder.items.length > 0
								    				? 
								    				<>
									    				{indOrder.items.map((II) => {
									    					return (
														    	<tr className={styles.sitesind_tr}>
														    		<td>{inventoryData.find((invItem) => invItem._id === II.item).item_name}</td>
														    		<td>{II.quantity} items</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <p>Create your first order to start!</p>
								    			}
								    		</>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


const Orders = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const [isLoading, setIsLoading] = useState(true);
	const [accountData, setAccountData] = useState({});
	const [ordersData, setOrdersData] = useState([]);
	const [ongoingOrders, setOngoingOrders] = useState([]);
	const [allSites, setAllSites] = useState([]);
	const [activeSites, setActiveSites] = useState([]);
	const [inventoryData, setInventoryData] = useState([]);
	const [validationError, setValidationError] = useState(null)
	// const [imgLoaded, setImgLoaded] = useState({1: "Loading", 2: "Loading", 3: "Loading", 4: "Loading"})

	const [isAuthenticated, setIsAuthenticated] = useState(false);
 	const isSuperAdmin = accountData && accountData.role && accountData.role.name === 'SuperAdmin';
 	const isAdmin = accountData && accountData.role && accountData.role.name === 'Admin';
 	const isAccount = accountData && accountData.role && accountData.role.name === 'Account';

 	const [orderType, setOrderType] = useState('Ongoing');
 	const [orderTypeNav, setOrderTypeNav] = useState('Fruit');
 	
 	// order info
 	const [newOrderData, setNewOrderData] = useState({});
 	const [prevOrderType, setPrevOrderType] = useState(null)
 	const [orderPopup, setOrderPopup] = useState(false);
 	const [popUpPage, setPopUpPage] = useState(1);
 	const [deliveryDayOptions, setDeliveryDayOptions] = useState([])
 	const [mainOrder, setMainOrder] = useState({});
 	const [mainObj, setMainObj] = useState({});

 	// ind site popup
 	const [indOrderPopup, setIndOrderPopup] = useState(false);
 	const [indOrder, setIndOrder] = useState(null)
 	const [indOngoing, setIndOngoing] = useState(false)

 	// ind category info
 	// const [milkType, setMilkType] = useState('Fresh');
 	// const [breadType, setBreadType] = useState('Abbotts');
 	const [orderCategory, setOrderCategory] = useState(null);
 	
	const param = useParams();
	const url = `/api/orders/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					setAccountData(data.data);
					setOrdersData(data.data.orders);
					setAllSites(data.data.sites)
					setActiveSites(data.data.sites.filter(site => site.status == "Active"))
					setInventoryData(data.data.inventory)
					setOngoingOrders(data.data.ongoing_orders)

					setIsAuthenticated(true);
				} catch(e) {
					setIsAuthenticated(false);
				}

				setIsLoading(false)

			} catch (error) {
				setAccountData(error);
			}

		    document.addEventListener('keydown', handleKeyPress);

		    return () => {
		      document.removeEventListener('keydown', handleKeyPress);
		    };
		};
		initLoad();
	}, [param, url]);


	const search_control = (event) => {
		var input, filter, table, tr, td, td2, td3, i, txtValue, txtValue2, txtValue3;
		input = document.getElementById("searchbar");
		filter = input.value.toUpperCase();
		table = document.getElementById("sites_table");
		tr = table.getElementsByTagName("tr");

		for (i = 0; i < tr.length; i++) {
			td = tr[i].getElementsByTagName("td")[0];
			td2 = tr[i].getElementsByTagName("td")[1];
			td3 = tr[i].getElementsByTagName("td")[2];

			if (td) {
				txtValue = td.textContent || td.innerText;
				txtValue2 = td2.textContent || td2.innerText;
				txtValue3 = td3.textContent || td3.innerText;

				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else if (txtValue2.toUpperCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else if (txtValue3.toUpperCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else {
					tr[i].style.display = "none";
				}
			}
		}
	}

	const formatOrders = (ordersArr, past) => {
	    const currentDate = new Date();

	    const filteredOrders = ordersArr.filter(order => {
	        const deliveryDate = new Date(order.delivery_date);
	        return past ? deliveryDate < currentDate : deliveryDate >= currentDate;
	    });

	    return filteredOrders;
	};


	const formatDate = (dateString) => {
	    const dateObj = new Date(dateString);
	    const year = dateObj.getUTCFullYear();
	    const month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getUTCDate()).slice(-2);

	    return `${day}/${month}/${year}`;
	};

	const convertToDay = (dateString) => {
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		
		const currentDayNumber = new Date(dateString).getDay();
		const dayName = daysOfWeek[currentDayNumber];

		return dayName
	}

	const formatDateProgress = (dateString) => {
		const date_pulled = new Date(dateString);
		const day_pulled = date_pulled.getDate();
		const month_pulled = date_pulled.toLocaleString('en-US', { month: 'long' });
		const formattedDateProgress = `${day_pulled} ${month_pulled}`;

		return formattedDateProgress
	}

	const formatDatePacking = (dateString) => {
		const date_pulled = new Date(dateString);
		const day_pulled = date_pulled.getDate();
		const month_pulled = date_pulled.toLocaleString('en-US', { month: 'long' });
		const formattedDateProgress = `${day_pulled-1} ${month_pulled}`;

		return formattedDateProgress
	}

	const nextDeliveryDate = (day) => {
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

		const currentDate = new Date();
		const currentDayNumber = currentDate.getDay();

		const targetDayNumber = daysOfWeek.indexOf(day);
			if (targetDayNumber === -1) {
			return null;
		}

		let daysToAdd = targetDayNumber - currentDayNumber;
			if (daysToAdd <= 0) {
			daysToAdd += 7;
		}

		const nextDate = new Date(currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
		return nextDate;
	};

	const checkDateRange = (dateArr, validDay) => {
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

		// Parse the date strings in dateArr to Date objects
		let startDate = new Date(dateArr[0]);
		let endDate = new Date(dateArr[1]);

		// Iterate over the range of dates
		for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
			let dayOfWeek = daysOfWeek[date.getDay()];

			if (dayOfWeek === validDay) {
				return true;
			}
		}

		return false;
	};

	const formatOptions = (options) => {
		let options_arr = []
		for (let o=0; o < options.length; o++) {
			options_arr.push({label: options[o], value: options[o]})
		}

		return options_arr
	}


	const changeNavType = (type) => {
		setOrderType(type)
	}

	const changeOrderCategory = (type, orderNav) => {
		setOrderCategory(type)
	}
	
	const addOrder = () => {
		setOrderPopup(true)	
	}

	const popUpControls = async (control) => {
		switch(control) {
			case 'prev':
				if (popUpPage == 2) {
					setValidationError(null)
					setPrevOrderType(newOrderData['order_type'])
				}
				setPopUpPage(popUpPage-1);
				break;
			case 'next':
				if (popUpPage == 1) {
					if (prevOrderType && newOrderData['order_type'] != prevOrderType) {
						setNewOrderData({order_type: newOrderData['order_type']})
						setMainOrder({})
						setMainObj({})
					}

					if (!(newOrderData.hasOwnProperty('order_type')) || newOrderData['order_type'] == '') {
						setValidationError(`Please select one of the order categories.`)
						return false;
					}

				} else if (popUpPage == 2) {
					if (!(newOrderData.hasOwnProperty('order_condition')) || newOrderData['order_condition'] == '') {
						setValidationError(`Please select one of the order types.`)
						return false;
					}
				} else if (popUpPage == 3) {
					if (!(newOrderData.hasOwnProperty('delivery_site')) || newOrderData['delivery_site'] == '') {
						setValidationError(`Please select a site for this order.`)
						return false;
					}

					if (newOrderData['order_condition'] == "recurring_temp") {
						if (newOrderData['delivery_day'] || newOrderData['delivery_day'] == '') {
							if (newOrderData['delivery_date_range'] || newOrderData['delivery_date_range'] == '') {
								if (!(newOrderData['delivery_date_range'].includes(null))) {
									if (!(checkDateRange(newOrderData['delivery_date_range'], newOrderData['delivery_day']))) {
										setValidationError(`Please select a date range that includes your delivery day (${newOrderData['delivery_day']}).`)
										return false;
									}
								} else {
									setValidationError(`Please select a date range.`)
									return false;
								}
							} else {
								setValidationError(`Please select a date range.`)
								return false;
							}
						} else {
							setValidationError(`Please select a delivery day.`)
							return false;
						}
					}

					if (newOrderData['order_condition'] != "onetime" && !(newOrderData.hasOwnProperty('frequency')) || newOrderData['frequency'] == '') {
						setValidationError(`Please select a frequency for this order.`)
						return false;
					} else {
						if (newOrderData['order_condition'] == "onetime") {
							if (!(newOrderData.hasOwnProperty('delivery_date')) || newOrderData['delivery_date'] == '') {
								setValidationError(`Please select a delivery date`)
								return false;
							}
						}
					}
				} else if (popUpPage == 4) {
					if (Object.keys(mainObj).length == 0) {
						setValidationError(`Please select at least 1 item.`)
						return false
					} else {
						for (let i=0; i < Object.keys(mainObj).length; i++) {
							let checkQuantity = Object.values(mainObj[Object.keys(mainObj)[i]]).filter(indval => indval == '')
							if (checkQuantity.length > 0) {
								let quantityFalse = Object.keys(mainObj)[i]
								setValidationError(`Please specify a quantity under ${quantityFalse}.`)
								return false;
							}
						}
					}
				}

				setValidationError(null)
				setPopUpPage(popUpPage+1)
				break
			case 'close':
				if (Object.keys(newOrderData).length > 0) {
					const confirm_close = window.confirm(`Are you sure you would like to discard this ${orderTypeNav} order?`)
					if (confirm_close) {
						setOrderPopup(false);

						// RESET ADD FORM DATA
						setNewOrderData({})
						setPopUpPage(1)
						setOrderCategory(null)
						setMainOrder({})
						setMainObj({})
						setValidationError(null)
					}
				} else {
					setOrderPopup(false);
					setValidationError(null)
				}

				break;
			case 'submit':
				await axios.post(`/api/orders/${param._id}`, {
					order_type: orderTypeNav,
					order_info: newOrderData,
					order_data: Object.entries(mainObj).flatMap(([category, items]) => Object.entries(items).map(([item, quantity]) => ({ [item]: quantity })))
				})
				window.location.reload(false);
				break;
		}
	}

	const handleNewOrderData = (name, selected, delivery_day_passed=null, condition_passed=null, type_passed=null) => {
		let newOrderDataTemp;
		if (selected.value) {
			if (delivery_day_passed && condition_passed && type_passed) {
				newOrderDataTemp = { ...newOrderData, [name]: selected.value, "delivery_site": delivery_day_passed, "order_condition": condition_passed, "warning": true, "order_type": type_passed };
				setOrderTypeNav(type_passed)
				setOrderCategory([...new Set(inventoryData.filter(inv => inv.category == type_passed).map(invitem => invitem.subcategory))][0])
			} else {
				newOrderDataTemp = { ...newOrderData, [name]: selected.value, "warning": false }
			}
		} else {
			newOrderDataTemp = { ...newOrderData, [name]: selected.target.value, "warning": false };
		}

		if (name == "order_type") {
			setOrderTypeNav(selected.value)
			setOrderCategory([...new Set(inventoryData.filter(inv => inv.category == selected.value).map(invitem => invitem.subcategory))][0])
		}

		if (name == "delivery_site") {
			let delivery_day_options = []

			let filtered_sites = activeSites.filter(s => toString(s.site_name) == toString(selected.value))
			if (filtered_sites.length > 0) {
				for (let sp in filtered_sites) {
					if (filtered_sites[sp].hasOwnProperty('preferences')) {
						for (let ip in filtered_sites[sp].preferences) {
							for (let og in ongoingOrders) {
								if (filtered_sites[sp].preferences[ip].preference == ongoingOrders[og]._id && ongoingOrders[og].preference_type == orderTypeNav && ongoingOrders[og].status == "Active" && ongoingOrders[og].hasOwnProperty('delivery_day')) {
									delivery_day_options.push(ongoingOrders[og].delivery_day)
								}
							}
						}
					}
				}
			}

			setDeliveryDayOptions(delivery_day_options)
		}

		if (name == "delivery_day" || delivery_day_passed) {

			let filtered_sites = activeSites.filter(s => toString(s.site_name) == toString(newOrderData.delivery_site))
			if (filtered_sites.length > 0) {
				for (let sp in filtered_sites) {
					if (filtered_sites[sp].hasOwnProperty('preferences')) {
						for (let ip in filtered_sites[sp].preferences) {
							for (let og in ongoingOrders) {
								if (filtered_sites[sp].preferences[ip].preference == ongoingOrders[og]._id && (ongoingOrders[og].preference_type == orderTypeNav || ongoingOrders[og].preference_type == type_passed) && ongoingOrders[og].status == "Active" && ongoingOrders[og].hasOwnProperty('delivery_day')) {
									if (ongoingOrders[og].delivery_day == selected.value || ongoingOrders[og].delivery_day == delivery_day_passed) {
										newOrderDataTemp['frequency'] = ongoingOrders[og].frequency

										let result1 = {};
										let result2 = {};
										for (let item of ongoingOrders[og].items) {
											let inventoryItem = inventoryData.find((invItem) => invItem._id === item.item);

											if (inventoryItem) {
												const { subcategory, item_name } = inventoryItem;

												if (!result1[subcategory]) {
												  result1[subcategory] = {};
												}
												result1[subcategory][item_name] = String(item.quantity);

												if (!result2[subcategory]) {
												  result2[subcategory] = [];
												}
												result2[subcategory].push(item_name);
											}
										}

										setMainObj(result1)
										setMainOrder(result2)
									}
								}
							}
						}
					}
				}
			}
		}
		setNewOrderData(newOrderDataTemp)
	};

	const handleMainOrder = (selected) => {
		if (selected) {
			let mainOrderArr = selected.map(sel => sel.value)
			setMainOrder({...mainOrder, [orderCategory]: mainOrderArr})

			let mainObjOutput = {...mainObj[orderCategory]};
			for (let orderitem of mainOrderArr) {
			  if (!mainObjOutput.hasOwnProperty(orderitem)) {
			    mainObjOutput[orderitem] = '';
			  }
			}

			let updatedMainObj = Object.keys(mainObjOutput)
			  .filter(key => mainOrderArr.includes(key))
			  .reduce((acc, key) => {
			    acc[key] = mainObjOutput[key];
			    return acc;
			  }, {});

			setMainObj({...mainObj, [orderCategory]: updatedMainObj})
		} else {
			setMainOrder({...mainOrder, [orderCategory]: []})
		}
	};

	const handleMainObj = (selected) => {
		let mainObjUpdated = {...mainObj[orderCategory]};
		mainObjUpdated[selected.target.name] = selected.target.value;

		setMainObj({...mainObj, [orderCategory]: mainObjUpdated})
	};

	const toggleInd = (order, isOngoing) => {
		setIndOrderPopup(true)

		if (isOngoing) {
			setIndOngoing(true)
		} else {
			setIndOngoing(false)
		}

		setIndOrder(order)
	}
	
	const indPopUpControls = async (control, site=null, day=null, type=null) => {
		switch(control) {
			case 'close':
				setIndOrderPopup(false);
				break;
			case 'edit':
				setIndOrderPopup(false);
				setOrderPopup(true)
				setPopUpPage(3)
				handleNewOrderData('delivery_day', {value: day}, site, 'recurring_change', type)

				// await axios.post(`/api/sites/${param._id}`, {site_data: newSiteData, site_address: newSiteAddress})
				// window.location.reload(false);
				break;
		}
	}

    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setIndOrderPopup(false);
        setIndOrder(null)
      }
    };

	const filterDates = (date, exclusions) => {
		const dateObj = new Date(date);
		const dayOfWeek = dateObj.toLocaleDateString('en-US', { weekday: 'long' });

		return !exclusions.includes(dayOfWeek);
	};


	return (
		<>
			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={styles.main_container}>
								<NavigationBar {...{ param, handleLogout }} />
								<ComingSoon />
								{/* <OrdersPage {...{ param, addOrder, orderPopup, ordersData, search_control, orderType, orderTypeNav, changeNavType, formatDate, toggleInd, indOrderPopup, ongoingOrders, nextDeliveryDate, formatDateProgress, formatDatePacking, formatOrders }} /> */}
								
								{/* {(orderPopup) && <PopUpOrder {...{ param, orderType, orderTypeNav, formatOptions, activeSites, popUpPage, popUpControls, newOrderData, handleNewOrderData, orderCategory, handleMainOrder, mainOrder, handleMainObj, changeOrderCategory, inventoryData, mainObj, validationError, ordersData, deliveryDayOptions, filterDates, ongoingOrders }} />} */}
								{/* {(orderPopup && orderType == "bread_container") && <PopUpBread {...{ param, orderType, orderTypeNav, formatOptions, activeSites, popUpPage, popUpControls, newOrderData, handleNewOrderData, breadType, handleMainOrder, mainOrder, handleMainObj, changeOrderCategory, inventoryData, mainObj, validationError, ordersData, deliveryDayOptions, filterDates, ongoingOrders }} />} */}
								{/* {indOrderPopup && <IndPopUp {...{ param, indPopUpControls, indOrder, formatDate, inventoryData, indOngoing, orderTypeNav, nextDeliveryDate, convertToDay }} />} */}
							</div>
							: <Forbidden />
						}
					</>
				:
					<Loading/>
			}
		</>
	);
};

export default Orders;