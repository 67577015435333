import styles from "./Users.module.css"
import ResizableDiv from '../Utils/ResizableDiv';


const PopUpSummary = ({ param, searchControl, indPopUpControls, popUpControls, handleDates, formatDate, currentDateSelection, weekOffset, internalLoading, boxActive, filteredShifts, calcHours, allDrivers }) => {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const currentDay = days[boxActive];
    let allDayShifts = [];

    filteredShifts.forEach(FS => {
        const dayShifts = FS.shifts[currentDay] || [];
        dayShifts.forEach(shift => {
            allDayShifts.push({ ...shift, driverName: FS.driverName, driverID: FS.driverID });
        });
    });

    allDayShifts.sort((a, b) => new Date(a.clocked_on) - new Date(b.clocked_on));

	return (
		<div className={styles.popup_container_six}>
			<button className={styles.closePopup} onClick={() => popUpControls('summary')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Driver shifts</h1>
			<p className={styles.smaller_font}>View a summary of driver's shifts week to week.</p>

			<div className={styles.sites_tablecontainer_auto}>
				<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
					<div className={`${styles.currDateSel}`}>
						<button disabled={internalLoading} onClick={() => handleDates('prev')}><i className="fa-solid fa-angle-left"></i></button>
						<p>{formatDate(currentDateSelection[0], false)} - {formatDate(currentDateSelection[1], false)}</p>
						<button disabled={internalLoading || weekOffset === 0} onClick={() => handleDates('next')}><i className="fa-solid fa-angle-right"></i></button>
					</div>
				</div>

				<div className={styles.box_layout}>
    				{["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((item, index) => {
    					return (
			    			<button onClick={() => indPopUpControls('handleBox', index)} className={`${styles.site_status} ${boxActive === index ? styles.box_active : styles.status_active}`}>
			    				<p className={styles.smaller_font}>{item}</p>
			    			</button>
				    	)
				    })}
				</div>

				<div className={`${styles.sites_controls} ${styles.indsite_controls_wh} ${styles.extra_margin_bottom}`}>
					<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
						<div className={styles.sites_search}>
							<i className="fa-solid fa-magnifying-glass"></i>
							<input
							    type="text"
							    className={styles.smaller_font}
							    placeholder="Search for any of the column headings..."
							    onKeyUp={(event) => searchControl(event, "ind_table")}
							/>
						</div>
					</div>
				</div>

				{internalLoading
					?
						<div className={styles.loadpage_container}>
							<div className={styles.load}>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
					:
						<div className={styles.table_container}>
							<table className={styles.sites_table} id="ind_table">
							    <thead className={styles.sitesind_thead}>
							      <tr className={`${styles.table_rowth} ${styles.tr_only}`}>
							      	<th colspan="2" className={styles.small_font}>Name</th>
									<th colspan="2" className={styles.small_font}>Clocked on</th>
									<th colspan="2" className={styles.small_font}>Clocked off</th>
									<th colspan="2" className={styles.small_font}>Clicked clock off</th>
									<th colspan="1" className={styles.small_font}>Hours</th>
							      </tr>
							    </thead>
							    <tbody className={styles.indtbody_cus}>

						            {allDayShifts.length > 0 ? (
						                <>
						                    {allDayShifts.map((IS) => {
						                    	let driverInd = allDrivers.find(driver => IS.driverID.toString() === driver._id.toString())
						                    	
						                    	return (
							                        <tr key={IS._id} className={styles.sitesind_tr} onClick={() => indPopUpControls('open', driverInd)}>
							                            <td colSpan="2">{IS.driverName}</td>
							                            <td colSpan="2">
							                                <a className={styles.clickable_nav} href={IS.clocked_on_img} target="_blank" rel="noopener noreferrer">
							                                    <p>{formatDate(IS.clocked_on, false, true)}</p>
							                                </a>
							                            </td>
							                            <td colSpan="2">
							                                {IS.clocked_off ? (
							                                	<>
								                                    <a className={styles.clickable_nav} href={IS.clocked_off_img} target="_blank" rel="noopener noreferrer">
								                                        <p>{formatDate(IS.clocked_off, false, true)}</p>
								                                    </a>
								                                    {IS.custom_clocked_off && <p className={styles.small_font}>user selected this date</p>}
							                                    </>
							                                ) : "-"}
							                            </td>
							                            <td colSpan="2">
											    			{IS.admin_override
											    				? "ADMIN OVERRIDE"
											    				:
											    					<>
										                            	{IS.clocked_off
										                            		? formatDate(IS.clocked_off_logged, false, true)
										                            		: "-"
										                            	}
										                            </>
										                     }
							                            </td>

							                            <td colSpan="1">{(IS.clocked_on && IS.clocked_off) ? calcHours(IS.clocked_on, IS.clocked_off) : "-"}</td>
							                        </tr>
						                        )
						                    })}
						                </>
						            ) : (
						                <h1 className={`${styles.medium_font} ${styles.create_first2}`}>There are no shifts yet!</h1>
						            )}

							    </tbody>
							</table>
						</div>
				}
			</div>
		</div>
	)
}

export default PopUpSummary;