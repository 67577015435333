import { Route, Routes, Navigate } from "react-router-dom";

import Login from "./components/Login";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import Dashboard from "./components/Dashboard";
import Sites from "./components/Sites";
import Orders from "./components/Orders";

import AdminDashboard from "./components/Admin/Dashboard";
import AdminWarehouse from "./components/Admin/Warehouse";
import AdminInvoices from "./components/Admin/Invoices";
import AdminFleet from "./components/Admin/Fleet";
import AdminReports from "./components/Admin/Reports";
import AdminUsers from "./components/Admin/Users";
import AdminProfile from "./components/Admin/Profile";
import ComingSoon from "./components/Admin/ComingSoon";
import Bin from "./components/Admin/Bin";

import ClockOn from "./components/ClockOn/Dashboard";


function App() {
	const account = localStorage.getItem("token");

	return (
		<Routes>
			<Route path="/login" exact element={<Login />} />
			<Route path="/register/:_id/:token" exact element={<Register />} />
			<Route path="/forgot-password" exact element={<ForgotPassword />} />

			{account && <Route path="/dashboard/:_id" exact element={<Dashboard />} />}
			{account && <Route path="/sites/:_id" exact element={<Sites />} />}
			{account && <Route path="/orders/:_id" exact element={<Orders />} />}

			{account && <Route path="/admin/dashboard/:_id" exact element={<AdminDashboard />} />}
			{account && <Route path="/admin/warehouse/:_id" exact element={<AdminWarehouse />} />}
			{account && <Route path="/admin/invoices/:_id" exact element={<AdminInvoices />} />}
			{account && <Route path="/admin/fleet/:_id" exact element={<AdminFleet />} />}
			{account && <Route path="/admin/reports/:_id" exact element={<AdminReports />} />}
			{account && <Route path="/admin/users/:_id" exact element={<AdminUsers />} />}
			{account && <Route path="/admin/profile/:_id" exact element={<AdminProfile />} />}
			{account && <Route path="/admin/bin/:_id" exact element={<Bin />} />}

			<Route path="/clockon" exact element={<ClockOn/>}/>

			<Route path="/" element={<Navigate replace to="/login" />} />
		</Routes>
	);
}

export default App;
