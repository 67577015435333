import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import fstyles from "./Forbiddenstyles.module.css"
import styles from "../Dashboard/Dashboard.module.css"

const NavigationBar = ({ param, handleLogout }) => {
	return (
		<div className={styles.sidebar_container}>
			<div className={styles.sidebar_account}>
				<i className={`fa-solid fa-circle-user ${styles.sidebar_icon}`} />
			</div>

			<div className={styles.sidebar_centered}>
				<div className={styles.sidebar_grid}>
					<Link to={`/dashboard/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-gauge-high ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/sites/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-location-dot ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/orders/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-box-open ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/dashboard/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-file-invoice-dollar ${styles.sidebar_icon}`} />
						</div>
					</Link>
				</div>
			</div>
		</div>
	)
}

const Forbidden = () => {
	const param = useParams();

	return (
		<div className={styles.main_container}>
			<NavigationBar {...{ param }} />

			<div className={styles.page_container}>
				<div className={fstyles.hover}>
				  <div className={fstyles.background}>
				    <div className={fstyles.door}><p className={fstyles.forbidden_1}>403</p><p className={styles.forbidden_text}>Access Denied</p></div>
				    <div className={fstyles.rug}></div>
				  </div>
				  <div className={fstyles.foreground}>
				    <div className={fstyles.bouncer}>
				      <div className={fstyles.head}>
				        <div className={fstyles.neck} />
				        <div className={`${fstyles.eye} ${fstyles.left}`} />
				        <div className={`${fstyles.eye} ${fstyles.right}`} />
				        <div className={fstyles.ear} />
				      </div>
				      <div className={fstyles.body} />
				      <div className={fstyles.arm} />
				    </div>
				    <div className={fstyles.poles}>
				      <div className={`${fstyles.pole} ${fstyles.left}`} />
				      <div className={`${fstyles.pole} ${fstyles.right}`} />
				      <div className={fstyles.rope} />
				    </div>
				  </div>
				</div>

			</div>
		</div>
	)
};

export default Forbidden;
