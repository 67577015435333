import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import styles from "./Users.module.css"

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpEditShift = ({ param, customShiftEdit, editShiftDates }) => {
	return (
		<div className={`${styles.popup_container} ${styles.popup_maxwidth}`}>
			<button className={styles.closePopup} onClick={() => customShiftEdit('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Edit shift</h1>
			<p className={styles.smaller_font}>Change a particular shift from this driver's summary.</p>

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Start shift</label>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
					    <DateTimePicker
					        className={styles.overridebg}
					        localeText={{ start: '', end: '' }}
					        value={editShiftDates && editShiftDates[0]}
					        onChange={(selected) => customShiftEdit('set', selected)}
					        format="DD/MM/YYYY @ hh:mm a"
					    />
					</LocalizationProvider>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>End shift</label>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
					    <DateTimePicker
					        className={styles.overridebg}
					        localeText={{ start: '', end: '' }}
					        value={editShiftDates && editShiftDates[1]}
					        onChange={(selected) => customShiftEdit('set', selected, true)}
					        format="DD/MM/YYYY @ hh:mm a"
					    />
					</LocalizationProvider>
				</div>
			</div>

			{/* {validationError && */}
			{/* 	<div className={`${styles.notification} ${styles.validationError}`}> */}
			{/* 		<p className={styles.smaller_font}>{validationError}</p> */}
			{/* 	</div> */}
			{/* } */}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button
						type="button"
						className={styles.submitBtn}
						onClick={() => customShiftEdit('submit')}
					>
						Submit
					</button>
				</div>
			</div>
		</div>
	)
}

export default PopUpEditShift;