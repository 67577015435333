import React, { useMemo, useState } from 'react';
import { useReactTable, getCoreRowModel, getSortedRowModel, flexRender } from '@tanstack/react-table';
import styles from "./TableUtil.module.css";


const DisplayTable = ({ table, selectedRows, toggleSelectAll, toggleRowSelection, handleRowClick, indInteraction, formatDate }) => {
  return (
    <table className={styles.sites_table}>
      <thead className={styles.table_rowth}>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {/* <th colspan="1"> */}
            {/*   <input */}
            {/*     type="checkbox" */}
            {/*     checked={table.getRowModel().rows.every(row => selectedRows[row.id])} */}
            {/*     onChange={toggleSelectAll} */}
            {/*   /> */}
            {/* </th> */}
            {headerGroup.headers.filter(h => h.id !== "item" && h.id !== "_id").map(header => (
              <th
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
                {header.column.getIsSorted() ? (
                  header.column.getIsSorted() === 'desc' ? (
                    <i className="fa-solid fa-arrow-up" />
                  ) : (
                    <i className="fa-solid fa-arrow-down" />
                  )
                ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={styles.table_bodyrowth}>
        {table.getRowModel().rows.map((row, index) => (
          <tr key={row.id} onClick={() => handleRowClick('Interactions', row.original)}>
            {/* <td> */}
            {/*   <input */}
            {/*     type="checkbox" */}
            {/*     checked={selectedRows[row.id] || false} */}
            {/*     onChange={() => toggleRowSelection(row.id)} */}
            {/*   /> */}
            {/* </td> */}
            {row.getVisibleCells().filter(r => r.id !== `${index}_item` && r.id !== `${index}__id`).map(cell => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}


const TableUtil = ({ allBin, formatDate, allAccounts, allSites, indInteraction, handleRowClick }) => {
  const [selectedRows, setSelectedRows] = useState({});

  const toggleRowSelection = (rowId) => {
    setSelectedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const toggleSelectAll = () => {
    const allSelected = table.getRowModel().rows.every(row => selectedRows[row.id]);
    const newSelectedRows = {};
    table.getRowModel().rows.forEach(row => {
      newSelectedRows[row.id] = !allSelected;
    });
    setSelectedRows(newSelectedRows);
  };

  function generateOutput(arr) {
    if (arr.length === 0) return [];

    const keys = Object.keys(arr[0]);
    return keys.map(key => {
      const column = {
        accessorKey: key,
        header: key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
      };

      // Add custom cell rendering for the deleted_date column
      if (key === 'deleted_date' || key === 'expiration_date') {
        column.cell = info => formatDate(info.getValue());
      }

      return column;
    });
  }

  const data = useMemo(() => allBin, [allBin]);
  const columns = useMemo(() => generateOutput(allBin), [allBin, formatDate]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });


  return (
    <DisplayTable {...{ table, selectedRows, toggleSelectAll, toggleRowSelection, handleRowClick, indInteraction, formatDate }}/>
  );
};

export default TableUtil;
