import Select from 'react-select';
import styles from "./Dashboard.module.css"

import ReactAutocomplete from "react-google-autocomplete";

const PopUpSite = ({ param, popUpPage, popUpControls, newSiteData, handleNewSiteData, handleNewSiteAddress, newSiteAddress, validationError, allAccounts, formatOptions, inventoryData }) => {
	return (
		<div className={styles.popup_container}>
			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Site details</h1>
					<p className={styles.smaller_font}>Please enter the name of your site, and the address.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Account</label>
							<Select
								closeMenuOnSelect={true}
								options={formatOptions(allAccounts.filter(acc => acc.role == "Account" && acc.status == "Active").map(c => c.company))}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewSiteData(selected, 'account')}
								value={{label: newSiteData["account"], value: newSiteData["account"]}}
							/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Department name</label>
							<input type="text" name="department" value={newSiteData.department} placeholder="Reception" onChange={handleNewSiteData}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site level</label>
							<input type="text" disabled={newSiteData['edit']} name="site_level" value={newSiteData.site_level} placeholder="12" onChange={handleNewSiteData}/>
						</div>

						{newSiteData['search_type'] === "Establishment"
							?
								<>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Site address</label>
										<ReactAutocomplete
										  disabled={newSiteData['edit'] || newSiteData['clone']}
										  apiKey="AIzaSyAPGR-rJOJE8h4YwVPlgJQjnUEzJcOQqb4"
										  onPlaceSelected={(place) => handleNewSiteAddress(place)}
										  options={{
										    types: ["establishment"],
										    componentRestrictions: { country: "au" },
										  }}
										  placeholder="Start typing your address..."
										  value={(newSiteAddress && newSiteAddress.hasOwnProperty('formatted_address')) && newSiteAddress.formatted_address}
										/>

										<button disabled={newSiteData['edit'] || newSiteData['clone']} className={styles.switchAddressType} onClick={() => handleNewSiteData({value: "Address"}, 'search_type')}><label className={styles.smaller_font}>Address Search</label></button>
									</div>
								</>
							:
								<>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Site address</label>
										<ReactAutocomplete
										  disabled={newSiteData['edit'] || newSiteData['clone']}
										  apiKey="AIzaSyAPGR-rJOJE8h4YwVPlgJQjnUEzJcOQqb4"
										  onPlaceSelected={(place) => handleNewSiteAddress(place)}
										  options={{
										    types: ["address"],
										    componentRestrictions: { country: "au" },
										  }}
										  placeholder="Start typing your address..."
										  value={(newSiteAddress && newSiteAddress.hasOwnProperty('formatted_address')) && newSiteAddress.formatted_address}
										/>

										<button disabled={newSiteData['edit'] || newSiteData['clone']} className={styles.switchAddressType} onClick={() => handleNewSiteData({value: "Establishment"}, 'search_type')}><label className={styles.smaller_font}>Establishment Search</label></button>
									</div>
								</>
						}

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Delivery instructions</label>
							<input type="text" name="site_instructions" value={newSiteData.site_instructions} placeholder="Leave all orders outside..." onChange={handleNewSiteData}/>
						</div>
					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Site contact</h1>
					<p className={styles.smaller_font}>Please enter the primary contact details for this site.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>First name</label>
								<input type="text" name="site_contact_fname" value={newSiteData.site_contact_fname} placeholder="John" onChange={handleNewSiteData}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Last name</label>
								<input type="text" name="site_contact_lname" value={newSiteData.site_contact_lname} placeholder="Doe" onChange={handleNewSiteData}/>
							</div>
						</div>

						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" name="site_contact_email" value={newSiteData.site_contact_email} placeholder="johndoe@gmail.com" onChange={handleNewSiteData}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" name="site_contact_phone" value={newSiteData.site_contact_phone} placeholder="1300 231 323" onChange={handleNewSiteData}/>
							</div>
						</div>
					</div>
				</>
			}

			{popUpPage == 3 &&
				<>
					<h1 className={styles.medium_font}>Site secondary contact</h1>
					<p className={styles.smaller_font}>Please enter the secondary contact details for this site.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>First name</label>
								<input type="text" name="site_contact_fname_sec" value={newSiteData.site_contact_fname_sec} placeholder="John" onChange={handleNewSiteData}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Last name</label>
								<input type="text" name="site_contact_lname_sec" value={newSiteData.site_contact_lname_sec} placeholder="Doe" onChange={handleNewSiteData}/>
							</div>
						</div>

						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" name="site_contact_email_sec" value={newSiteData.site_contact_email_sec} placeholder="johndoe@gmail.com" onChange={handleNewSiteData}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" name="site_contact_phone_sec" value={newSiteData.site_contact_phone_sec} placeholder="1300 231 323" onChange={handleNewSiteData}/>
							</div>
						</div>
					</div>
				</>
			}

			{popUpPage == 4 &&
				<>
					<h1 className={styles.medium_font}>Site details</h1>
					<p className={styles.smaller_font}>Please enter the name of your site, and the address.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Supplier Platform?</label>
							<Select
								closeMenuOnSelect={true}
								options={[{label: "No", value: "No"}, {label: "Yordar", value: "Yordar"}, {label: "EatFirst", value: "EatFirst"}]}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewSiteData(selected, 'supplier_platform')}
								value={{label: newSiteData["supplier_platform"], value: newSiteData["supplier_platform"]}}
							/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Crate?</label>
							<Select
								closeMenuOnSelect={true}
								options={[{label: "Yes", value: "Yes"}, {label: "No", value: "No"}]}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewSiteData(selected, 'crate')}
								value={{label: newSiteData["crate"], value: newSiteData["crate"]}}
							/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site pass</label>
							<Select
								closeMenuOnSelect={true}
								options={[{label: "Yes", value: "Yes"}, {label: "No", value: "No"}]}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewSiteData(selected, 'site_pass')}
								value={{label: newSiteData["site_pass"], value: newSiteData["site_pass"]}}
							/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Pass number</label>
							<input type="text" name="pass_number" value={newSiteData.pass_number} placeholder="32341232" onChange={handleNewSiteData}/>
						</div>
					</div>
				</>
			}

			{popUpPage == 5 &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Account</label>
							<input type="text" value={newSiteData.account} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site level</label>
							<input type="text" value={newSiteData.site_level} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Site address</label>
							<input type="text" value={newSiteAddress.formatted_address} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Delivery instructions</label>
							<input type="text" value={newSiteData.site_instructions} disabled={true}/>
						</div>

						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>First name</label>
								<input type="text" value={newSiteData.site_contact_fname} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Last name</label>
								<input type="text" value={newSiteData.site_contact_lname} disabled={true}/>
							</div>
						</div>

						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" value={newSiteData.site_contact_email} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" value={newSiteData.site_contact_phone} disabled={true}/>
							</div>
						</div>

						<div className={styles.popup_form_flex}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Has pass?</label>
								<input type="text" value={newSiteData.site_pass} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Pass number</label>
								<input type="text" value={newSiteData.pass_number || '-'} disabled={true}/>
							</div>
						</div>
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 5 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 5 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpSite;