import ReactAutocomplete from "react-google-autocomplete";
import styles from "./Dashboard.module.css"

const PopUpEmail = ({ param, emailBody, DisplayHTMLComponent, popUpControls, formatDate, handleOpenEmail }) => {
	return (
		<div className={`${styles.popup_container} ${styles.popup_maxwidth}`}>

			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>
			<button className={styles.editPopup} onClick={() => handleOpenEmail('delete', emailBody)}><i class="fa-solid fa-trash-can"></i></button>

			<h1 className={styles.medium_font}>Email contents</h1>
			<p className={styles.smaller_font}>View the body of the email attached in this module.</p>


			<div className={styles.popUpEmailBox}>
				<div className={styles.emailContainer}>
					<div>
						<p className={styles.emailPopupLabel}>To:</p>
						<p>{emailBody.from.replaceAll('"', "").split(' <')[0]}<br/>{emailBody.from.replaceAll('"', "").replaceAll('>', "").split(' <')[1]}</p>
					</div>
					
					<div>
						<p className={styles.emailPopupLabel}>From:</p>
						<p>{emailBody.to.replaceAll('"', "").split(' <')[0]}<br/>{emailBody.to.replaceAll('"', "").replaceAll('>', "").split(' <')[1]}</p>
					</div>

					<div>
						<p className={styles.emailPopupLabel}>Subject:</p>
						<p>{emailBody.subject}</p>
					</div>

					<div>
						<p className={styles.emailPopupLabel}>Date sent:</p>
						<p>{formatDate(emailBody.date_sent, true, true)}</p>
					</div>
				</div>

				<div className={styles.linebreak}></div>
				
				<DisplayHTMLComponent htmlContent={emailBody.html_body} />
			</div>
		</div>
	)
}

export default PopUpEmail;