import { ResponsiveBar } from '@nivo/bar'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const BarChart = ({ barChartData }) => {
    return (
        <ResponsiveBar
            data={barChartData}
            keys={["value"]}
            layout="horizontal"
            indexBy="customer"
            margin={{ top: 0, right: 0, bottom: 50, left: 150 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            colorBy="value"

            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Top 10 Spending (sites)',
                legendPosition: 'middle',
                legendOffset: 40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'brighter',
                        100
                    ]
                ]
            }}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={e=>e.id+": "+e.formattedValue+" in customer: "+e.indexValue}
            label={null}
        />
    )
}

export default BarChart;