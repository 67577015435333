import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import styles from "./Users.module.css"

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpCustomShift = ({ param, handleCustomShift, customShiftDates }) => {
	return (
		<div className={`${styles.popup_container} ${styles.popup_maxwidth}`}>
			<button className={styles.closePopup} onClick={() => handleCustomShift('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Custom shift</h1>
			<p className={styles.smaller_font}>Create a particular shift for this driver's summary.</p>

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Start shift</label>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
					    <DateTimePicker
					        className={styles.overridebg}
					        localeText={{ start: '', end: '' }}
					        value={customShiftDates && customShiftDates[0]}
					        onChange={(selected) => handleCustomShift('set', selected)}
					        format="DD/MM/YYYY @ hh:mm a"
					        disableFuture
					    />
					</LocalizationProvider>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>End shift</label>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
					    <DateTimePicker
					        className={styles.overridebg}
					        localeText={{ start: '', end: '' }}
					        value={customShiftDates && customShiftDates[1]}
					        onChange={(selected) => handleCustomShift('set', selected, true)}
					        format="DD/MM/YYYY @ hh:mm a"
					        disableFuture
					    />
					</LocalizationProvider>
				</div>
			</div>

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button
						type="button"
						className={styles.submitBtn}
						onClick={() => handleCustomShift('submit')}
					>
						Submit
					</button>
				</div>
			</div>
		</div>
	)
}

export default PopUpCustomShift;