import ReactAutocomplete from "react-google-autocomplete";
import styles from "./Dashboard.module.css"

const PopUpEnquiry = ({ param, popUpPage, popUpControls, validationError, formatOptions, handleNewEnquiryData, handleNewEnquiryAddress, newEnquiryAddress, newEnquiryData }) => {
	return (
		<div className={styles.popup_container}>

			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Enquiry details</h1>
					<p className={styles.smaller_font}>Please enter the specific enquiry details to create.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Company</label>
							<input type="text" placeholder="Seek" onChange={(input) => handleNewEnquiryData('company', input)} value={newEnquiryData['company']}/>
						</div>

						<div className={styles.popup_form_flex_rows}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>First name</label>
								<input type="text" placeholder="Jane" onChange={(input) => handleNewEnquiryData('first_name', input)} value={newEnquiryData['first_name']}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Last name</label>
								<input type="text" placeholder="Doe" onChange={(input) => handleNewEnquiryData('last_name', input)} value={newEnquiryData['last_name']}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" placeholder="jdoe@seek.com.au" onChange={(input) => handleNewEnquiryData('email', input)} value={newEnquiryData['email']}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" placeholder="+61 442 323 432" onChange={(input) => handleNewEnquiryData('phone', input)} value={newEnquiryData['phone']}/>
							</div>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Address</label>
							<ReactAutocomplete
							  apiKey="AIzaSyAFbl7W6hpI-ivmBfB5Mf9_uJpyHPTjHp0"
							  onPlaceSelected={(place) => handleNewEnquiryAddress(place)}
							  options={{
							    types: ["address"],
							    componentRestrictions: { country: "au" },
							  }}
							  placeholder="Start typing your address..."
							  defaultValue={newEnquiryAddress && newEnquiryAddress.formatted_address}
							/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Reason we can't supply</label>
							<textarea placeholder="Unavailable for delivery in this area, ect..." onChange={(input) => handleNewEnquiryData('reason', input)} value={newEnquiryData['reason']}></textarea>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Notes</label>
							<textarea placeholder="Requested fruit, ect..." onChange={(input) => handleNewEnquiryData('notes', input)} value={newEnquiryData['notes']}></textarea>
						</div>

					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

					<div className={styles.popup_form}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Company</label>
							<input type="text" value={newEnquiryData['company']} disabled={true}/>
						</div>

						<div className={styles.popup_form_flex_rows}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>First name</label>
								<input type="text" value={newEnquiryData['first_name']} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Last name</label>
								<input type="text" value={newEnquiryData['last_name']} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" value={newEnquiryData['email']} disabled={true}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" value={newEnquiryData['phone']} disabled={true}/>
							</div>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Address</label>
							<input type="text" value={newEnquiryAddress.formatted_address} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Reason we can't supply</label>
							<textarea value={newEnquiryData['reason']} disabled={true}></textarea>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Notes</label>
							<textarea value={newEnquiryData['notes']} disabled={true}></textarea>
						</div>
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 2 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 2 &&
		      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpEnquiry;