import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Offline, Online, Detector } from "react-detect-offline";
import axios from "axios";

import NavigationBar from '../Utils/NavigationBar'
import Forbidden from '../Forbidden';
import Loading from '../Loading';
import TableUtil from '../Utils/TableUtil';
import ofglogo from '../../../images/logo.png'
import styles from "./Bin.module.css"

import PopUpIndInteraction from './PopUpIndInteraction';


const BinPage = ({ param, navType, searchControl, allBin, formatDate, allAccounts, allSites, indInteraction, handleRowClick }) => {
	return (
		<div className={`${styles.page_container} ${indInteraction && styles.hidden_container}`}>
			<div className={`${styles.sites_container_with_warning_bin}`}>

					<div className={styles.flaggedContainer}>
						<div className={styles.flaggedOrdersWarning}>
							<i className={`fa-solid fa-flag ${styles.smaller_font}`}></i>
							<p className={styles.medium_font}>Items will remain in the recycling bin for 30 days, before being deleted permanently.</p>
						</div>
					</div>

				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>Recycling Bin</h1>
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'Bin' && styles.sitenav_active}`}><p>Bin</p></button>
					</div>
				</div>

				<div className={styles.sites_tablecontainer}>
					<div className={styles.sites_controls}>
						<div className={styles.admin_searchcontainer}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input
								    type="text"
								    placeholder="Search for any of the column headings..."
								    onKeyUp={(event) => searchControl(event, "main_table")}
								/>
							</div>
						</div>
					</div>

					<div className={styles.table_container}>

						<TableUtil {...{ allBin, formatDate, allAccounts, allSites, indInteraction, handleRowClick }} />




{/* 						<table className={styles.sites_table} id="main_table" align="center">						     */}
{/* 						    {navType == "Bin" && */}
{/* 							    <> */}
{/* 								    <thead> */}
{/* 								      <tr className={styles.table_rowth}> */}
{/* 								      	<th>Item type</th> */}
{/* 								      	<th>Item code</th> */}
{/* 								      	<th>Deleted by</th> */}
{/* 								        <th>Deleted date</th> */}
{/* 								        <th>Tools</th> */}
{/* 								      </tr> */}
{/* 								    </thead> */}
{/*  */}
{/* 							    	<tbody> */}
{/* 									</tbody> */}
{/* 								</> */}
{/* 							} */}
{/* 						</table> */}
					</div>
				</div>
			</div>
		</div>
	)
}

const Bin = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [accountData, setAccountData] = useState({});
	const [allBin, setAllBin] = useState(null);
	const [key, setKey] = useState(0);
	const [collapseDeg, setCollapseDeg] = useState(0)
	const [collapseFilters, setCollapseFilters] = useState(true)
	const [allAccounts, setAllAccounts] = useState([])
	const [allSites, setAllSites] = useState([])

	const [indBin, setIndBin] = useState(null)
	const [indInteraction, setIndInteraction] = useState(null)

	const [isSuperAdmin, setIsSuperAdmin] = useState(false)
	const [isAdmin, setIsAdmin] = useState(false)
	const [isAccount, setIsAccount] = useState(false)
	const [isAuthenticated, setIsAuthenticated] = useState(false);

 	const [navType, setNavType] = useState('Bin');
 	const [popUpPage, setPopUpPage] = useState(1);
 	const [validationError, setValidationError] = useState(null);

	const param = useParams();
	const url = `/api/admin/bin/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					setAccountData(data.data);
					setAllBin(data.data.bin)

					setAllAccounts(data.data.allAccounts)
					setAllSites(data.data.allSites)

				 	setIsSuperAdmin(data.data.role && data.data.role === 'SuperAdmin');
				 	setIsAdmin(data.data.role && data.data.role === 'Admin');
				 	setIsAccount(data.data.role && data.data.role === 'Account');

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}
				setIsLoading(false)

			} catch (error) {
				setAccountData(error);
			}
		};
		initLoad();
	}, [param, url, key]);

	const refreshDashboard = () => {
		setKey((prevKey) => prevKey + 1);
	};

	const searchControl = (event, tableId) => {
	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = tableId === "button_table"
	    	? document.getElementById(tableId).children
	    	: document.getElementById(tableId).children[1].children;


	    if (tableId === "button_table") {
		    for (let i = 0; i < target_div.length; i++) {
		        let mainValue = target_div[i].getElementsByTagName("p")[0].textContent.trim().replaceAll("  ", " ") || target_div[i].getElementsByTagName("p")[0].innerText.trim().replaceAll("  ", " ")
		        let subValue = target_div[i].getElementsByTagName("p")[1].textContent.trim().replaceAll("  ", " ") || target_div[i].getElementsByTagName("p")[1].innerText.trim().replaceAll("  ", " ")
		        let lowerValue = target_div[i].getElementsByTagName("p")[2].textContent.trim().replaceAll("  ", " ") || target_div[i].getElementsByTagName("p")[2].innerText.trim().replaceAll("  ", " ")
		        let higValue = target_div[i].getElementsByTagName("p").length > 3 ? target_div[i].getElementsByTagName("p")[3].textContent.trim().replaceAll("  ", " ") || target_div[i].getElementsByTagName("p")[3].innerText.trim().replaceAll("  ", " ") : null
		        let itemValue = target_div[i].getElementsByTagName("p").length > 4 ? target_div[i].getElementsByTagName("p")[4].textContent.trim().replaceAll("  ", " ") || target_div[i].getElementsByTagName("p")[4].innerText.trim().replaceAll("  ", " ") : null

		        let showItem = false;
		        if (higValue) {
		        	if (itemValue) {
				        [mainValue.toUpperCase(), subValue.toUpperCase(), lowerValue.toUpperCase(), higValue.toUpperCase(), itemValue.toUpperCase()].forEach((value) => {
				            if (value.indexOf(filter) > -1) {
				                showItem = true;
				            }
				        });
		        	} else {
				        [mainValue.toUpperCase(), subValue.toUpperCase(), lowerValue.toUpperCase(), higValue.toUpperCase()].forEach((value) => {
				            if (value.indexOf(filter) > -1) {
				                showItem = true;
				            }
				        });
		        	}
		        } else {
			        [mainValue.toUpperCase(), subValue.toUpperCase(), lowerValue.toUpperCase()].forEach((value) => {
			            if (value.indexOf(filter) > -1) {
			                showItem = true;
			            }
			        });
		        }


		        if (showItem) {
		            target_div[i].style.display = "flex";
		        } else {
		            target_div[i].style.display = "none";
		        }
		    }
	    } else {
		    for (let i = 0; i < target_div.length; i++) {
		        let txtValues = [];
		        for (let j = 0; j < target_div[i].children.length; j++) {
	                if (target_div[i].children[j].className.includes('status')) {
	                    // handle status
	                    let txtValue = target_div[i].children[j].getElementsByTagName("p")[0].textContent || target_div[i].children[j].getElementsByTagName("p")[0].innerText
	                    txtValues.push(txtValue.toUpperCase());
	                } else {
		            	let txtValue = target_div[i].children[j].textContent || target_div[i].children[j].innerText;
		            	txtValues.push(txtValue.toUpperCase());
		            }
		        }

		        let showItem = false;
		        txtValues.forEach((value) => {
		            if (value.indexOf(filter) > -1) {
		                showItem = true;
		            }
		        });

		        if (showItem) {
		            target_div[i].style.display = "table";
		        } else {
		            target_div[i].style.display = "none";
		        }
		    }
		}
	};

	const formatDate = (dateString, showDay, showTime) => {
	    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	    const dateObj = new Date(dateString);

	    if (!showTime) {
	    	dateObj.setHours(0, 0, 0, 0);
	    }

	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);

	    const currentDayNumber = dateObj.getDay();

	    if (showDay && showTime) {
	        let shours = ('0' + dateObj.getHours()).slice(-2);
	        let sminutes = ('0' + dateObj.getMinutes()).slice(-2);
	        let sseconds = ('0' + dateObj.getSeconds()).slice(-2);

	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year} @ ${shours}:${sminutes}:${sseconds}`;

	    } else if (showDay) {
	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year}`;
	    } else if (showTime) {
	        let hours = ('0' + dateObj.getHours()).slice(-2);
	        let minutes = ('0' + dateObj.getMinutes()).slice(-2);
	        let seconds = ('0' + dateObj.getSeconds()).slice(-2);

	        return `${day}/${month}/${year} @ ${hours}:${minutes}:${seconds}`;
	    } else {
	        return `${day}/${month}/${year}`;
	    }
	};


	const handleRowClick = (type, ind) => {
		switch (type) {
			case 'Interactions':
				setIndInteraction(ind ? ind.item : ind)
				setIndBin(ind)
				break;
		}
	}

	const handleRestore = async (type) => {
		switch (type) {
			case 'Interactions':
				const confirmRestore = window.confirm("Are you sure you would like to restore this interaction?")

				if (confirmRestore) {
					setIsLoading(true)
					await axios.post(`/api/admin/dashboard/restore/${param._id}`, {
						type: 'Interactions',
						ind: indInteraction,
						indBin: indBin
					});

					const navData = { type: type, _id: indInteraction._id };
					sessionStorage.setItem('arrivedExternally', 'true');
					sessionStorage.setItem('navData', JSON.stringify(navData));

					navigate(`/admin/dashboard/${param._id}`);
				}
				break;
		}
	}

	return (
		<div key={key}>	
			<Detector
				polling={{ timeout: 7000 }}
				render={({ online }) => (
					<>
						{!online && (
							<div className="offline_container">
								<div className="offline_inner animate__animated animate__slideInDown">
									<i className="fa-solid fa-heart-crack"></i>
									<div className="offline_content">
										<p className="offline_title">You are offline</p>
										<p className="offline_subtitle">Please check your internet connect.</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			/>

			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={collapseFilters ? styles.main_container : styles.main_container_full}>
								<NavigationBar 
									param={param}
									active={"Bin"}
									handleLogout={handleLogout}
									collapseDeg={collapseDeg}
									setCollapseDeg={setCollapseDeg}
									collapseFilters={collapseFilters}
									setCollapseFilters={setCollapseFilters}
								/>
								<BinPage {...{ param, navType, searchControl, allBin, formatDate, allAccounts, allSites, indInteraction, handleRowClick }} />

								{indInteraction && <PopUpIndInteraction {...{ handleRowClick, indInteraction, formatDate, allAccounts, allSites, handleRestore }}/>}
							</div>
							: <Forbidden />
						}
					</>
				:
					<Loading current={"Bin"} />
			}
		</div>
	);
};


export default Bin;