import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import Forbidden from '../Forbidden';
import Loading from '../Loading';
import ofglogo from '../../images/logo.png'
import ComingSoon from '../ComingSoon';
import styles from "./Dashboard.module.css"


const NavigationBar = ({ param, handleLogout }) => {
	return (
		<div className={styles.sidebar_container}>
			<div className={styles.sidebar_account}>
				<i className={`fa-solid fa-circle-user ${styles.sidebar_icon}`} />
			</div>

			<div className={styles.sidebar_centered}>
				<div className={styles.sidebar_grid}>
					<Link to={`/dashboard/${param._id}`}>
						<div className={`${styles.sidebar_box} ${styles.sidebar_active}`}>
							<i className={`fa-solid fa-gauge-high ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/sites/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-location-dot ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/orders/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-box-open ${styles.sidebar_icon}`} />
						</div>
					</Link>

					<Link to={`/dashboard/${param._id}`}>
						<div className={`${styles.sidebar_box}`}>
							<i className={`fa-solid fa-file-invoice-dollar ${styles.sidebar_icon}`} />
						</div>
					</Link>
				</div>
			</div>

		</div>
	)
}

const DashboardPage = ({ param }) => {
	return (
		<div className={`${styles.page_container}`}>
			<div className={styles.sites_container}>

				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>Dashboard</h1>
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${styles.sitenav_active}`}><p>Sites</p></button>
						<button className={`${styles.sitenav_box}`}><p>Orders</p></button>
					</div>
				</div>

			</div>
		</div>
	)
}


const Dashboard = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

	const [isLoading, setIsLoading] = useState(true);
	const [accountData, setAccountData] = useState({});

	const [isAuthenticated, setIsAuthenticated] = useState(false);
 	const isSuperAdmin = accountData && accountData.role && accountData.role.name === 'SuperAdmin';
 	const isAdmin = accountData && accountData.role && accountData.role.name === 'Admin';
 	const isAccount = accountData && accountData.role && accountData.role.name === 'Account';


	const param = useParams();
	const url = `/api/dashboard/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};


	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					setAccountData(data.data);

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}

				setIsLoading(false)

			} catch (error) {
				setAccountData(error);
			}
		};
		initLoad();
	}, [param, url]);



	return (
		<>
			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={styles.main_container}>
								<NavigationBar {...{ param, handleLogout }} />
								{/* <DashboardPage {...{ param }} /> */}
								<ComingSoon />
							</div>
							: <Forbidden />
						}
					</>
				:
					<Loading/>
			}
		</>

	);
};


export default Dashboard;
