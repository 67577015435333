import { useEffect, useState, useCallback } from "react";
import styles from "./Invoices.module.css"

import invoice_all from '../../../images/invoice_all.jpg'
import invoice_selected from '../../../images/invoice_selected.jpg'
import calculateTotalAmount from "../../../utils/calculateTotalAmount"

import Select from 'react-select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne');


const PopUpAutomatic = ({ param, popUpPage, popUpControls, validationError, handleNewInvoiceData, newInvoiceData, formatOptions, allSites, invoiceOrders, allAccounts, formatDate, allInventory, sortInvoiceOrders, allOrders, allTiers, groupInvoiceOrders }) => {
	const [indInvoiceGroup, setIndInvoiceGroup] = useState(null)

	const handleInvoiceGroup = (control, ind) => {
		switch (control) {
			case 'toggle':
				if (indInvoiceGroup) {
					setIndInvoiceGroup(null)
				} else {
					setIndInvoiceGroup(ind)
				}

				break;
		}
	}

	return (
		<div className={popUpPage == 4 ? styles.popup_container_custom_inv : styles.popup_container}>
			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Invoice details</h1>
					<p className={styles.smaller_font}>Please enter the specific invoice details to create.</p>

					<div className={`${styles.popup_form}`}>
						<button className={`${styles.order_typebox} ${newInvoiceData.invoice_condition && newInvoiceData.invoice_condition == "invoice_all" ? styles.order_typeboxactive : ""}`} onClick={() => handleNewInvoiceData('invoice_condition', {value: 'invoice_all'})}>
							<div className={styles.order_imgbox}>
								<img src={invoice_all}/>
							</div>

							<div className={styles.order_contentbox}>
								<h1 className={styles.smaller_font}>Invoice all</h1>
								<p className={styles.smaller_font}>Create and send invoices to all customers that apply.</p>
							</div>
						</button>

						<button className={`${styles.order_typebox} ${newInvoiceData.invoice_condition && newInvoiceData.invoice_condition == "invoice_selected" ? styles.order_typeboxactive : ""}`} onClick={() => handleNewInvoiceData('invoice_condition', {value: 'invoice_selected'})}>
							<div className={styles.order_imgbox}>
								<img src={invoice_selected}/>
							</div>

							<div className={styles.order_contentbox}>
								<h1 className={styles.smaller_font}>Invoice selected</h1>
								<p className={styles.smaller_font}>Select customers you would like to create invoices for.</p>
							</div>
						</button>
					</div>
				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Invoice details</h1>
					<p className={styles.smaller_font}>Please enter the specific invoice details to create.</p>

					{newInvoiceData.invoice_condition == "invoice_all" &&
						<div className={`${styles.popup_form}`}>
							<button className={`${styles.order_typebox} ${newInvoiceData.invoice_cycle && newInvoiceData.invoice_cycle == "Monthly" ? styles.order_typeboxactive : ""}`} onClick={() => handleNewInvoiceData('invoice_cycle', {value: 'Monthly'})}>
								<div className={styles.order_iconbox}>
									<i className="fa-solid fa-calendar-days"></i>
								</div>

								<div className={styles.order_contentbox}>
									<h1 className={styles.smaller_font}>Monthly</h1>
									<p className={styles.smaller_font}>Pull all orders that are on monthly invoice cycles.</p>
								</div>
							</button>

							<button className={`${styles.order_typebox} ${newInvoiceData.invoice_cycle && newInvoiceData.invoice_cycle == "Fortnightly" ? styles.order_typeboxactive : ""}`} onClick={() => handleNewInvoiceData('invoice_cycle', {value: 'Fortnightly'})}>
								<div className={styles.order_iconbox}>
									<i className="fa-solid fa-calendar-week"></i>
								</div>

								<div className={styles.order_contentbox}>
									<h1 className={styles.smaller_font}>Fortnightly</h1>
									<p className={styles.smaller_font}>Pull all orders that are on fortnightly invoice cycles.</p>
								</div>
							</button>

							<button className={`${styles.order_typebox} ${newInvoiceData.invoice_cycle && newInvoiceData.invoice_cycle == "Weekly" ? styles.order_typeboxactive : ""}`} onClick={() => handleNewInvoiceData('invoice_cycle', {value: 'Weekly'})}>
								<div className={styles.order_iconbox}>
									<i className="fa-solid fa-calendar-minus"></i>
								</div>

								<div className={styles.order_contentbox}>
									<h1 className={styles.smaller_font}>Weekly</h1>
									<p className={styles.smaller_font}>Pull all orders that are on weekly invoice cycles.</p>
								</div>
							</button>
						</div>
					}


					{newInvoiceData.invoice_condition == "invoice_selected" &&
						<div className={`${styles.popup_form_customdate}`}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Sites</label>
								<Select
									isMulti
									closeMenuOnSelect={true}
									options={formatOptions(allSites.filter(AS => AS.status != "Inactive").map(s => s.site_name))}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											boxShadow: 'none !important',
										   '&:hover': {
										       borderColor: '#fce7eb !important'
										    },
											borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
										}),
									}}
									onChange={(selected) => handleNewInvoiceData("sites", selected)}
									value={newInvoiceData['sites'] && formatOptions(newInvoiceData['sites'])}
								/>
							</div>
						</div>
					}
				</>
			}

			{popUpPage == 3 &&
				<>
					<h1 className={styles.medium_font}>Invoice details</h1>
					<p className={styles.smaller_font}>Please enter the specific invoice details to create.</p>

					<div className={styles.popup_form_customdate}>
						<div className={styles.popup_formbox}>
							<div className={styles.label_container}>
								<label className={styles.smaller_font}>From date</label>
								<label className={styles.smaller_font}>To date</label>
							</div>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateRangePicker
									className={styles.overridebg}
									localeText={{ start: '', end: '' }}
									value={newInvoiceData.invoice_date_range && newInvoiceData.invoice_date_range}
									onChange={(selected) => handleNewInvoiceData("invoice_date_range", {value: selected})}
									format="DD/MM/YYYY"
								/>

							</LocalizationProvider>
						</div>
					</div>
				</>
			}

			{popUpPage == 4 &&
				<>
					<h1 className={styles.medium_font}>Invoice details</h1>
					<p className={styles.smaller_font}>Please enter the specific invoice details to create.</p>

					<div className={`${styles.single_flex}`}>
						<div className={styles.sites_tablecontainer}>
							{/* <div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}> */}
							{/* 	<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}> */}
							{/* 		<div className={styles.sites_search}> */}
							{/* 			<i className="fa-solid fa-magnifying-glass"></i> */}
							{/* 			<input type="text" className={styles.smaller_font} placeholder="Search for any of the column headings..." /> */}
							{/* 		</div> */}
							{/* 	</div> */}
							{/* </div> */}

							<div className={`${styles.table_container} ${styles.invoice_grid_new}`}>
						    	{invoiceOrders
						    		?
						    		<>
					    				{Object.keys(invoiceOrders).map((IO, idx) => {
					    					let groupedTotalPrice = Object.values(invoiceOrders[IO]).flat().reduce((total, order) => total + order.price, 0);
					    					let priceCalcObj = calculateTotalAmount(invoiceOrders[IO], allAccounts, allSites, allInventory, allOrders, allTiers)
					    					const totalPrice = Object.values(priceCalcObj).reduce((total, item) => total + (item.price || 0), 0);

											return (
												<>
													<button className={styles.invoice_item_container} onClick={() => handleInvoiceGroup("toggle", IO)}>
														<p className={styles.mid_font}>{IO} - ${totalPrice}</p>
														{(indInvoiceGroup && indInvoiceGroup === IO)
															? <i className="fa-solid fa-chevron-up"></i>
															: <i className="fa-solid fa-chevron-down"></i>
														}
													</button>

													{(indInvoiceGroup && indInvoiceGroup === IO) &&
														<div className={styles.invoice_item_info}>
															{Object.keys(groupInvoiceOrders(invoiceOrders[IO])).map((GIO, index) => {
																return (
																	<>
																		<p className={`${styles.mid_font} ${styles.custom_invoice_sub}`}>{GIO}</p>

																		<div className={styles.mb_2}>
																			<table className={`${styles.custom_site_table} ${styles.auto_height}`} id="sites_table">
																			    <thead className={`${styles.sitesind_thead} ${styles.invoice_newthead}`}>
																			      <tr className={styles.table_rowth}>
																			      	<th colspan="3">Delivery date</th>
																			      	<th colspan="1">Item name</th>
																			      	<th colspan="1">Total qty</th>
																			      	<th colspan="1">Unit price</th>
																			      	<th colspan="1">Total price</th>
																			      </tr>
																			    </thead>

																			    <tbody className={`${styles.indtbody} ${styles.invoice_newtbody}`}>
																					{sortInvoiceOrders(groupInvoiceOrders(invoiceOrders[IO])[GIO]).map((GO, index) => {
																						let priceCalcInd = priceCalcObj[GO['order_code']]

																						return (
																							<div className={(index !== sortInvoiceOrders(groupInvoiceOrders(invoiceOrders[IO])[GIO]).length - 1) && styles.invoice_item_grid}>
																								{GO.order_type === "Fruit"
																									?    
																										<>
																											<tr className={styles.sitesind_tr}>
																												<td colspan="3">{formatDate(GO.delivery_date, true)}</td>

																												<td colspan="1">{(((priceCalcInd.hasOwnProperty('extras') && Object.keys(priceCalcInd.extras).length !== 0) && GO.box_type !== "Seasonal") && Object.keys(priceCalcInd.extras).length > 0) ? "Extras" : GO.box_type} box</td>
																												<td colspan="1">{GO.total_qty} items</td>
																												<td colspan="1">{(priceCalcInd.hasOwnProperty('extras') && Object.keys(priceCalcInd.extras).length > 0) ? "" : (priceCalcInd.unit_price ? `$${priceCalcInd.unit_price}` : "")}</td>
																												<td colspan="1">${priceCalcInd.price}</td>
																											</tr>

																											{(priceCalcInd.hasOwnProperty('extras') && Object.keys(priceCalcInd.extras).length > 0) &&
																												<>
																													{Object.keys(priceCalcInd.extras).map((GE, index) => {
																														return (
																															<>
																																{GO.box_type === "Seasonal" &&
																																	<tr className={`${styles.sitesind_tr} ${styles.inner_tr_smaller}`}>
																																		<td className={styles.smaller_font} colspan="3"></td>

																																		<td className={styles.smaller_font_custom} colspan="1">Seasonal Fruit</td>
																																		<td className={styles.smaller_font_custom} colspan="1">{priceCalcInd.box.quantity} items</td>
																																		<td className={styles.smaller_font_custom} colspan="1">${priceCalcInd.box.unit_price}</td>
																																		<td className={styles.smaller_font_custom} colspan="1">${parseFloat(priceCalcInd.box.unit_price * priceCalcInd.box.quantity) || 0}</td>
																																	</tr>
																																}

																																<tr className={`${styles.sitesind_tr} ${styles.inner_tr_smaller}`}>
																																	<td className={styles.smaller_font} colspan="3"></td>

																																	<td className={styles.smaller_font_custom} colspan="1">{GE}</td>
																																	<td className={styles.smaller_font_custom} colspan="1">{priceCalcInd.extras[GE].quantity} items</td>
																																	<td className={styles.smaller_font_custom} colspan="1">${priceCalcInd.extras[GE].unit_price}</td>
																																	<td className={styles.smaller_font_custom} colspan="1">${priceCalcInd.extras[GE].total_price}</td>
																																</tr>
																															</>
																														)
																													})}
																												</>
																											}
																										</>
																									: GO.order_type === "Milk"
																									? 
																										<>
																											<tr className={styles.sitesind_tr}>
																												<td colspan="3">{formatDate(GO.delivery_date, true)}</td>

																												<td colspan="1">{GO.order_type} order</td>
																												<td colspan="1">{GO.total_qty} items</td>
																												<td colspan="1"></td>
																												<td colspan="1">${priceCalcInd.price}</td>
																											</tr>

																											{Object.keys(priceCalcInd.extras_milk).map((GEM, index) => {
																												return (
																													<>
																														<tr className={`${styles.sitesind_tr} ${styles.inner_tr_smaller}`}>
																															<td className={styles.smaller_font} colspan="3"></td>

																															<td className={styles.smaller_font_custom} colspan="1">{GEM}</td>
																															<td className={styles.smaller_font_custom} colspan="1">{priceCalcInd.extras_milk[GEM].quantity} items</td>
																															<td className={styles.smaller_font_custom} colspan="1">${priceCalcInd.extras_milk[GEM].unit_price}</td>
																															<td className={styles.smaller_font_custom} colspan="1">${priceCalcInd.extras_milk[GEM].total_price}</td>
																														</tr>
																													</>
																												)
																											})}
																										</>
																									:
																										<>
																											<tr className={styles.sitesind_tr}>
																												<td colspan="3">{formatDate(GO.delivery_date, true)}</td>

																												<td colspan="1">{GO.order_type} order</td>
																												<td colspan="1">{GO.total_qty} items</td>
																												<td colspan="1"></td>
																												<td colspan="1">${priceCalcInd.price}</td>
																											</tr>

																											{Object.keys(priceCalcInd.extras_other).map((GEO, index) => {
																												return (
																													<>
																														<tr className={`${styles.sitesind_tr} ${styles.inner_tr_smaller}`}>
																															<td className={styles.smaller_font} colspan="3"></td>

																															<td className={styles.smaller_font_custom} colspan="1">{GEO}</td>
																															<td className={styles.smaller_font_custom} colspan="1">{priceCalcInd.extras_other[GEO].quantity} items</td>
																															<td className={styles.smaller_font_custom} colspan="1">${priceCalcInd.extras_other[GEO].unit_price}</td>
																															<td className={styles.smaller_font_custom} colspan="1">${priceCalcInd.extras_other[GEO].total_price}</td>
																														</tr>
																													</>
																												)
																											})}
																										</>
																								}
																							</div>
																						)
																					})}
																				</tbody>
																			</table>
																		</div>
																	</>
																)
															})}
														</div>
													}
												</>
										    )
									    })}
						    				
						    		</>
						    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>There were no orders found with the configuration applied.</h1>
						    	}
							</div>
						</div>
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{popUpPage != 4 &&
		      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
		      		}

		      		{popUpPage == 4 &&
		      			<>
		      				<button type="button" className={styles.submitBtn}>Create and send invoices</button>
		      				<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Create</button>
		      			</>
		       		}
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpAutomatic;