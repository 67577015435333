import { useEffect, useState, useCallback, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Offline, Online, Detector } from "react-detect-offline";
import axios from "axios";
import dayjs from 'dayjs'
import 'animate.css';

import Forbidden from '../Forbidden';
import Loading from '../Loading';
import ofglogo from '../../../images/logo.png'
import styles from "./Users.module.css"

import NavigationBar from '../Utils/NavigationBar'
import PopUpUsers from './PopUpUsers';
import PopUpIndUser from './PopUpIndUser';
import PopUpSummary from './PopUpSummary'

import PopUpDrivers from './PopUpDrivers';
import PopUpIndDriver from './PopUpIndDriver'
import PopUpEditShift from './PopUpEditShift'
import PopUpCustomShift from './PopUpCustomShift'


const UsersPage = ({ param, changeNavType, navType, popUpControls, indPopUpControls, allUsers, usersPopup, indUserPopup, searchControl, driversPopup, allDrivers, indDriverPopup, showSummary, formatDate, checkClockedOn }) => {
	return (
		<div className={`${styles.page_container} ${(usersPopup || indUserPopup || driversPopup || indDriverPopup || showSummary) && styles.hidden_container}`}>
			<div className={`${styles.sites_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType}</h1>
						{navType == 'Users' &&
							<button onClick={() => popUpControls('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Add user</p>
							</button>
						}

						{navType == 'Drivers' &&
							<div className={styles.dual_buttons}>
								<button onClick={() => popUpControls('summary')}>
									<i className="fa-solid fa-list"></i>
									<p className={styles.smaller_font}>Summary</p>
								</button>

								<button onClick={() => popUpControls('open')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add driver</p>
								</button>
							</div>
						}
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'Users' && styles.sitenav_active}`} onClick={() => changeNavType('Users')}><p>Users</p></button>
						<button className={`${styles.sitenav_box} ${navType == 'Drivers' && styles.sitenav_active}`} onClick={() => changeNavType('Drivers')}><p>Drivers</p></button>
					</div>
				</div>

				{navType === 'Users' &&
					<div className={styles.sites_tablecontainer}>
						<div className={styles.sites_controls}>
							<div className={styles.admin_searchcontainer}>
								<div className={styles.sites_search}>
									<i className="fa-solid fa-magnifying-glass"></i>
									<input
									    type="text"
									    placeholder="Search for any of the column headings..."
									    onKeyUp={(event) => searchControl(event, "button_table")}
									/>
								</div>
							</div>
						</div>

						<div className={styles.table_container}>
							<table className={styles.sites_table}>
								{(allUsers && allUsers.length > 0)
								?
									<tbody className={styles.fleet_tbody}>
										<tr className={`${styles.user_container} ${styles.nohover}`} id="button_table">
											{allUsers.map((AI, key) => {
												return (
													<td className={styles.fleet_td} key={key}>
														<button className={styles.user_box} onClick={() => indPopUpControls('open', AI)}>
															<div className={styles.inner_user}>
																<div className={styles.user_headcontainer}>
																	<div className={styles.user_outer}>
																		<i className="fa-solid fa-circle-user"></i>
																	</div>

																	<div className={styles.fleet_text}>
																		<h1 className={styles.medium_font}>{AI.contacts[0].first_name} {AI.contacts[0].last_name}</h1>
																		<p className={styles.smaller_font}>{AI.email}</p>
																	</div>
																</div>

																<div className={styles.user_permissions}>
																	{AI.permissions && AI.permissions.map((AP, key) => {
																	    const dynamicClassName = `${styles.user_status} ${styles['status_' + AP.replace(' ', '')]}`;

																	    return (
																	        <div className={dynamicClassName} key={key}>
																	            <p className={styles.smaller_font}>{AP}</p>
																	        </div>
																	    );
																	})}
																</div>
															</div>
														</button>
													</td>
												)
											})}
										</tr>
									</tbody>		
								:
									<h1 className={styles.create_first}>No users available!</h1>
								}
							</table>
						</div>
					</div>
				}

				{navType === 'Drivers' &&
					<div className={styles.sites_tablecontainer}>
						<div className={styles.sites_controls}>
							<div className={styles.admin_searchcontainer}>
								<div className={styles.sites_search}>
									<i className="fa-solid fa-magnifying-glass"></i>
									<input
									    type="text"
									    placeholder="Search for any of the column headings..."
									    onKeyUp={(event) => searchControl(event, "button_table")}
									/>
								</div>
							</div>
						</div>

						<div className={styles.table_container}>
							<table className={styles.sites_table}>
								{(allDrivers && allDrivers.length > 0)
								?
									<tbody className={styles.fleet_tbody}>
										<tr className={`${styles.userd_container} ${styles.nohover}`} id="button_table">
											{allDrivers.map((DR, key) => {
												return (
													<td className={`${styles.fleet_td}`} key={key}>
														<button className={`${styles.user_box} ${(DR.status === "Active" && checkClockedOn(DR.shifts[0])) && styles.fleet_flag}`} onClick={() => indPopUpControls('open', DR)}>
															<div className={`${styles.inner_user}`}>
																<div className={styles.user_headcontainer}>
																	<div className={styles.user_outer}>
																		<i className="fa-solid fa-circle-user"></i>
																	</div>

																	<div className={styles.fleet_text}>
																		<h1 className={styles.medium_font}>{DR.first_name} {DR.last_name}</h1>
																		<p className={styles.smaller_font}>{DR.driver_code}</p>
																	</div>

													    			<div className={`${styles.site_status} ${styles.site_right} ${(DR.status === "Active") && styles.status_active} ${DR.status === "Inactive" && styles.status_inactive}`}>
														    			<p className={styles.smaller_font}>{DR.status}</p>
														    		</div>
																</div>

																<div className={styles.user_stats_driver}>
																	<p className={styles.smaller_font}>License number: {DR.license_number ? DR.license_number : "-"}</p>
																	<p className={styles.smaller_font}>License expiration: {DR.license_expiration ? formatDate(DR.license_expiration) : "-"}</p>

																	<br/>

																	<p className={styles.smaller_font}>Last clock on: {DR.shifts.length > 0 ? formatDate(DR.shifts[0].clocked_on, false, true) : "-"}</p>
																	<p className={styles.smaller_font}>Last clock off: {(DR.shifts.length > 0 && DR.shifts[0].clocked_off) ? formatDate(DR.shifts[0].clocked_off, false, true) : "-"}</p>
																</div>
															</div>
														</button>
													</td>
												)
											})}
										</tr>
									</tbody>		
								:
									<h1 className={styles.create_first}>No users available!</h1>
								}
							</table>
						</div>
					</div>
				}

			</div>
		</div>
	)
}


const Users = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		window.location = "/login";
	};

    const getDayNumber = () => {
        const currentDay = new Date().getDay();
        return (currentDay + 6) % 7;
    };

    const [key, setKey] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [internalLoading, setInternalLoading] = useState(false);
	const [accountData, setAccountData] = useState({});
	const [allUsers, setAllUsers] = useState(null);
	const [allAccounts, setAllAccounts] = useState(null);
	const [allDrivers, setAllDrivers] = useState(null);
	const [changingStatus, setChangingStatus] = useState(false)
	const [showSummary, setShowSummary] = useState(false)
	const [weekOffset, setWeekOffset] = useState(0)
	const [currentDateSelection, setCurrentDateSelection] = useState(getCurrentWeekDays(weekOffset))
	const [boxActive, setBoxActive] = useState(getDayNumber())
	const [filteredShifts, setFilteredShifts] = useState([])
	const [collapseDeg, setCollapseDeg] = useState(0)
	const [collapseFilters, setCollapseFilters] = useState(true)

	const [isAuthenticated, setIsAuthenticated] = useState(false);
 	const isSuperAdmin = accountData && accountData.role && accountData.role.name === 'SuperAdmin';
 	const isAdmin = accountData && accountData.role && accountData.role.name === 'Admin';
 	const isAccount = accountData && accountData.role && accountData.role.name === 'Account';

 	const [navType, setNavType] = useState('Users');
 	const [indNav, setIndNav] = useState("Details");
 	const [popUpPage, setPopUpPage] = useState(1);
 	const [validationError, setValidationError] = useState(null);

 	// users
 	const [usersPopup, setUsersPopup] = useState(false);
 	const [newUserData, setNewUserData] = useState({});
 	const [indUserPopup, setIndUserPopup] = useState(false)
 	const [indUser, setIndUser] = useState(null)

 	// drivers
 	const [driversPopup, setDriversPopup] = useState(false)
 	const [newDriverData, setNewDriverData] = useState({})
 	const [indDriverPopup, setIndDriverPopup] = useState(false)
 	const [indDriver, setIndDriver] = useState(null)
 	const [editShift, setEditShift] = useState(false)
 	const [editShiftDates, setEditShiftDates] = useState([])
 	const [shiftToEdit, setShiftToEdit] = useState(null)
 	const [showCustomShift, setShowCustomShift] = useState(false)
 	const [customShiftDates, setCustomShiftDates] = useState([])

	const param = useParams();
	const url = `/api/admin/users/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					setAccountData(data.data);
					setAllUsers(data.data.allUsers)
					setAllAccounts(data.data.allAccounts)
					setAllDrivers(data.data.allDrivers)

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
				}

				setIsLoading(false)
			} catch (error) {
				setAccountData(error);
			}
		};
		initLoad();
	}, [param, url, key]);

	const refreshDashboard = () => {
		setKey((prevKey) => prevKey + 1);
	};

	const searchControl = (event, tableId) => {
	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = tableId === "button_table" ? document.getElementById(tableId).children : document.getElementById(tableId).children[1].children;

	    if (tableId === "button_table") {
		    for (let i = 0; i < target_div.length; i++) {
		        let mainValue = target_div[i].getElementsByTagName("h1")[0].textContent || target_div[i].getElementsByTagName("h1")[0].innerText
		        let allValues = [mainValue.toUpperCase()]

		        for (let s=0; s < target_div[i].getElementsByTagName("p").length; s++) {
		        	let subValue = target_div[i].getElementsByTagName("p")[s].textContent || target_div[i].getElementsByTagName("p")[s].innerText
		        	allValues.push(subValue.toUpperCase())
		        }

		        let showItem = false;
		        allValues.forEach((value) => {
		            if (value.indexOf(filter) > -1) {
		                showItem = true;
		            }
		        });

		        if (showItem) {
		            target_div[i].style.display = "flex";
		        } else {
		            target_div[i].style.display = "none";
		        }
		    }
	    } else {
		    for (let i = 0; i < target_div.length; i++) {
		        let txtValues = [];
		        for (let j = 0; j < target_div[i].children.length; j++) {
	                if (target_div[i].children[j].className.includes('status')) {
	                    // handle status
	                    let txtValue = target_div[i].children[j].getElementsByTagName("p")[0].textContent || target_div[i].children[j].getElementsByTagName("p")[0].innerText
	                    txtValues.push(txtValue.toUpperCase());
	                } else {
		            	let txtValue = target_div[i].children[j].textContent || target_div[i].children[j].innerText;
		            	txtValues.push(txtValue.toUpperCase());
		            }
		        }

		        let showItem = false;
		        txtValues.forEach((value) => {
		            if (value.indexOf(filter) > -1) {
		                showItem = true;
		            }
		        });

		        if (showItem) {
		            target_div[i].style.display = "table";
		        } else {
		            target_div[i].style.display = "none";
		        }
		    }
		}
	};

	const formatDate = (dateString, showDay, showTime=false) => {
	    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	    const dateObj = new Date(dateString);

	    // dateObj.setHours(0, 0, 0, 0);

	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);

	    const currentDayNumber = dateObj.getDay();

	    if (showTime) {
	        let hours = ('0' + dateObj.getHours()).slice(-2);
	        let minutes = ('0' + dateObj.getMinutes()).slice(-2);
	        let seconds = ('0' + dateObj.getSeconds()).slice(-2);

	        return `${day}/${month}/${year} @ ${hours}:${minutes}:${seconds}`;
	    } else {
		    if (showDay) {
		        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year}`;
		    } else {
		        return `${day}/${month}/${year}`;
		    }
	    }
	};

  	const formatOptions = (arr) => {
  		if (arr && arr.length > 0) {
  			return [{ label: '', value: '' }, ...arr.map(item => {
  				return { label: item, value: item };
  			})];
		}
	}

	function getCurrentWeekDays(weekOffset = 0) {
	    const today = new Date();
	    const currentDay = today.getDay();
	    
	    const distanceToMonday = currentDay === 0 ? -6 : 1 - currentDay;
	    const distanceToSunday = currentDay === 0 ? 0 : 7 - currentDay;
	    
	    const monday = new Date(today);
	    monday.setDate(monday.getDate() + distanceToMonday + 7 * weekOffset);

	    const sunday = new Date(today);
	    sunday.setDate(sunday.getDate() + distanceToSunday + 7 * weekOffset);

	    return [monday, sunday];
	}


	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	const changeNavType = (type) => {
		setNavType(type)
	}

	const changeIndNav = async (type) => {
		setIndNav(type)
	}

	const popUpControls = async (control) => {
		switch(control) {
			case 'open':
				switch (navType) {
					case 'Users':
						setUsersPopup(true)
						break;
					case 'Drivers':
						setDriversPopup(true)
						break;
				}
				break;
			case 'summary':
				if (showSummary) {
					setShowSummary(false)
					setWeekOffset(0)
					setCurrentDateSelection(getCurrentWeekDays(0))
					setBoxActive(getDayNumber())
					setFilteredShifts([])
				} else {
					setInternalLoading(true)
					setShowSummary(true)
					setWeekOffset(0)
					setBoxActive(getDayNumber())

			        setCurrentDateSelection(getCurrentWeekDays(0));
					let nDateShifts = await axios.post(`/api/admin/users/shifts/${param._id}`, {weekSet: getCurrentWeekDays(0)})
					setFilteredShifts(nDateShifts.data)	
					setInternalLoading(false)				
				}
				break;
			case 'prev':
				setPopUpPage(popUpPage-1);
				break;
			case 'next':
				switch (navType) {
					case 'Users':
						if (popUpPage == 1) {
							if (!(newUserData.hasOwnProperty('first_name')) || newUserData['first_name'] == '') {
								setValidationError(`Please enter the user's first name.`)
								return false;
							}

							if (!(newUserData.hasOwnProperty('last_name')) || newUserData['last_name'] == '') {
								setValidationError(`Please enter the user's last name.`)
								return false;
							}

							if (!(newUserData.hasOwnProperty('email')) || newUserData['email'] == '') {
								setValidationError(`Please enter the user's email.`)
								return false;
							} else {
								if (!(isValidEmail(newUserData.email))) {
									setValidationError(`Please enter a valid email address.`)
									return false;
								} else {
									let accountEmailExists = allAccounts.filter(acc => acc.email === newUserData.email)

									if (accountEmailExists.length > 0 && !newUserData['edit']) {
										setValidationError(`An account already exists with this email.`)
										return false;
									}
								}
							}

							if (!(newUserData.hasOwnProperty('dob')) || !newUserData['dob'] || newUserData['dob'] == '') {
								setValidationError(`Please enter the user's date of birth.`)
								return false;
							}

							if (!(newUserData.hasOwnProperty('work_anniversary')) || !newUserData['work_anniversary'] || newUserData['work_anniversary'] == '') {
								setValidationError(`Please enter the user's work anniversary.`)
								return false;
							}

							if (!(newUserData.hasOwnProperty('permissions')) || newUserData['permissions'] == '') {
								setValidationError(`Please select at least one permission.`)
								return false;
							} else {
								if (newUserData['permissions'].length == 0) {
									setValidationError(`Please select at least one permission.`)
									return false;
								}
							}
						}
						setValidationError(null)
						setPopUpPage(popUpPage+1)
						break
					case 'Drivers':
						if (popUpPage == 1) {
							if (!(newDriverData.hasOwnProperty('first_name')) || newDriverData['first_name'] == '') {
								setValidationError(`Please enter the drivers first name.`)
								return false;
							}

							if (!(newDriverData.hasOwnProperty('last_name')) || newDriverData['last_name'] == '') {
								setValidationError(`Please enter the drivers last name.`)
								return false;
							}

							if (!(newDriverData.hasOwnProperty('phone_number')) || newDriverData['phone_number'] == '') {
								setValidationError(`Please enter the drivers phone number.`)
								return false;
							}

							if (!(newDriverData.hasOwnProperty('dob')) || !newDriverData['dob'] || newDriverData['dob'] == '' || newDriverData['dob'] === "Invalid Date") {
								setValidationError(`Please enter the drivers date of birth.`)
								return false;
							}
						} else if (popUpPage == 2) {
							if (!(newDriverData.hasOwnProperty('license_number')) || newDriverData['license_number'] == '') {
								setValidationError(`Please enter the drivers license number.`)
								return false;
							}

							if (!(newDriverData.hasOwnProperty('license_expiration')) || !newDriverData['license_expiration'] || newDriverData['license_expiration'] == '' || newDriverData['license_expiration'] === "Invalid Date") {
								setValidationError(`Please enter the drivers license expiration date.`)
								return false;
							}
						}
						setValidationError(null)
						setPopUpPage(popUpPage+1)
						break
				}
				break
			case 'close':
				switch (navType) {
					case 'Users':
						if (Object.keys(newUserData).length > 0) {
							const confirm_close = window.confirm(`Are you sure you would like to discard this new user?`)
							if (confirm_close) {
								setUsersPopup(false);

								// RESET ADD FORM DATA
								setNewUserData({})
								setPopUpPage(1)
								setValidationError(null)
							}
						} else {
							setUsersPopup(false);
							setValidationError(null)
						}
						break;
					case 'Drivers':
						if (Object.keys(newDriverData).length > 0) {
							const confirm_close = window.confirm(`Are you sure you would like to discard this new user?`)
							if (confirm_close) {
								setDriversPopup(false);

								// RESET ADD FORM DATA
								setNewDriverData({})
								setPopUpPage(1)
								setValidationError(null)
							}
						} else {
							setDriversPopup(false);
							setValidationError(null)
						}
						break;
					}
				break;
			case 'submit':
				setIsLoading(true)
				switch (navType) {
					case 'Users':
						await axios.post(`/api/admin/users/${param._id}`, {
							user_data: newUserData
						});
						break;
					case 'Drivers':
						await axios.post(`/api/admin/users/drivers/${param._id}`, {
							driver_data: newDriverData
						});
						break;
				}
				window.location.reload(false);
				break;
		}
	}

	const reOpenDriverPopup = (driverPassed) => {
		setIndDriverPopup(true);
		setIndDriver(driverPassed)
	}

	const indPopUpControls = async (control, ind) => {
		switch(control) {
			case 'open':
				switch (navType) {
					case 'Users':
						setIndUserPopup(true);
						setIndUser(ind)
						break;
					case 'Drivers':
						setIndDriverPopup(true);
						setIndDriver(ind)
						break;
				}
				break
			case 'handleBox':
				setBoxActive(ind)
				break;
			case 'edit':
				switch (navType) {
					case 'Users':
						setIndUserPopup(false);
						setUsersPopup(true)

						setNewUserData({
							first_name: indUser.contacts[0].first_name,
							last_name: indUser.contacts[0].last_name,
							email: indUser.email,
							phone: indUser.phone,
							dob: indUser.dob,
							work_anniversary: indUser.work_anniversary,

							permissions: indUser.permissions ? indUser.permissions.map(item => ({ label: item, value: item })) : [],

							edit: true,
							user_id: indUser._id
						})
						break;
					case 'Drivers':
						setIndDriverPopup(false);
						setDriversPopup(true)

						setNewDriverData({
							first_name: indDriver.first_name,
							last_name: indDriver.last_name,
							phone_number: indDriver.phone_number,
							dob: indDriver.dob,

							license_number: indDriver.license_number,
							license_expiration: indDriver.license_expiration,

							edit: true,
							driver_id: indDriver._id
						})
						break;
				}
				break
			case 'close':
				setIndUserPopup(false);
				setIndDriverPopup(false);
				setIndNav("Details")

				switch (navType) {
					case 'Users':
						setIndUser(null)
						break;
					case 'Drivers':
						setIndDriver(null)
						break;
				}
				break;
			case 'delete':
				switch (navType) {
					case 'Users':
						const confirm_status = window.confirm(`Are you sure you would like to delete this user?`)
						if (confirm_status) {
							await axios.post(`/api/admin/dashboard/delete/${param._id}`, {
								type: "Users",
								ind: ind
							});
							window.location.reload(false)
						}
						break;
					case 'Drivers':
						if (indDriver) {
							const confirm_del_shift = window.confirm(`Are you sure you would like to delete this shift?`)
							if (confirm_del_shift) {
								const newDriverUpdated = await axios.post(`/api/admin/users/drivers/delete_shift/${param._id}`, {
									shift: ind,
									driver: indDriver
								});

								setIndDriverPopup(false);
								setIndDriver(null)
								setIndNav("Shifts")
								refreshDashboard()

								reOpenDriverPopup(newDriverUpdated.data)
							}
						} else {
							const confirm_del_driver = window.confirm(`Are you sure you would like to delete this driver?`)
							if (confirm_del_driver) {
								await axios.post(`/api/admin/dashboard/delete/${param._id}`, {
									type: "Drivers",
									ind: ind
								});
								window.location.reload(false)
							}
						}
						break;
				}
				break;
		}
	}

	const handleNewUserData = (name, selected) => {
		let newUserDataTemp;
		if (name == "permissions") {
			newUserDataTemp = { ...newUserData, [name]: selected }
		} else if (name == "dob" || name == "work_anniversary") {
			newUserDataTemp = { ...newUserData, [name]: selected.value.format() }
		} else {
			newUserDataTemp = { ...newUserData, [name]: selected.target.value }
		}

		setNewUserData(newUserDataTemp)
	};

	const changeStatus = async (control, type=null, ind=null, status=null) => {
		switch (control) {
			case 'toggle':
				setChangingStatus(!changingStatus)
				break;
			case 'set':
				const confirm_status = window.confirm(`Are you sure you would like to change the status to ${status}?`)
				if (confirm_status) {
					await axios.post(`/api/admin/dashboard/status/${param._id}`, {
						type: type,
						ind: ind,
						status: status
					});
					window.location.reload(false)
				}
				break;
		}
	}

	const handleNewDriverData = (name, selected) => {
		let newDriverDataTemp;
		if (name == "dob" || name == "license_expiration") {
			if (selected.value) {
				newDriverDataTemp = { ...newDriverData, [name]: selected.value.format() }
			} else {
				newDriverDataTemp = { ...newDriverData, [name]: selected.value }
			}
			
		} else {
			newDriverDataTemp = { ...newDriverData, [name]: selected.target.value }
		}

		setNewDriverData(newDriverDataTemp)
	}

	const handleDates = async (control) => {
		switch (control) {
			case 'prev':
		        let newOffsetP = weekOffset - 1;
		        setWeekOffset(newOffsetP);
		        setCurrentDateSelection(getCurrentWeekDays(newOffsetP));
		        setInternalLoading(true)

				let prevDateShifts = await axios.post(`/api/admin/users/shifts/${param._id}`, {weekSet: getCurrentWeekDays(newOffsetP)})
				setFilteredShifts(prevDateShifts.data)

		        setInternalLoading(false)
				break;
			case 'next':
        		let newOffsetN = weekOffset + 1;
        		setWeekOffset(newOffsetN);
        		setCurrentDateSelection(getCurrentWeekDays(newOffsetN));
        		setInternalLoading(true)

				let newDateShifts = await axios.post(`/api/admin/users/shifts/${param._id}`, {weekSet: getCurrentWeekDays(newOffsetN)})
				setFilteredShifts(newDateShifts.data)

        		setInternalLoading(false)
				break;
		}
	}

	const calcHours = (start, end) => {
	    const dateOn = new Date(start);
	    const dateOff = new Date(end);

	    const differenceInMs = dateOff - dateOn;
	    const differenceInSeconds = differenceInMs / 1000;

	    const hours = Math.floor(differenceInSeconds / 3600).toString().padStart(2, '0');
	    const minutes = Math.floor((differenceInSeconds % 3600) / 60).toString().padStart(2, '0');
	    const seconds = Math.floor(differenceInSeconds % 60).toString().padStart(2, '0');

	    const result = `${hours}:${minutes}:${seconds}`;
	    return result;
	}

	const customShiftEdit = async (control, ind=null, end=false) => {
		switch (control) {
			case 'open':
				setEditShift(true)
				setShiftToEdit(ind)
				let shiftEditDates = [dayjs(ind.clocked_on), null]
				if (ind.clocked_off && ind.clocked_off !== null) {
					shiftEditDates[1] = dayjs(ind.clocked_off)
				}

				setEditShiftDates(shiftEditDates)
				break;
			case 'close':
				setEditShift(false)
				setEditShiftDates([])
				setShiftToEdit(null)
				break;
			case 'set':
				let shiftEditDatesDup = [...editShiftDates]
				if (!end) {
					shiftEditDatesDup[0] = ind
				} else {
					shiftEditDatesDup[1] = ind
				}

				setEditShiftDates(shiftEditDatesDup)
				break;
			case 'submit':
				const updatedDriverShifts = await axios.post(`/api/admin/users/drivers/update_shift/${param._id}`, {
					shift: shiftToEdit,
					driver: indDriver,
					newShift: editShiftDates
				});

				setEditShift(false)
				setEditShiftDates([])
				setShiftToEdit(null)

				setIndDriverPopup(false);
				setIndDriver(null)
				setIndNav("Shifts")
				refreshDashboard()

				reOpenDriverPopup(updatedDriverShifts.data)
				break;
		}
	}

	const handleCustomShift = async (control, ind=null, end=false) => {
		switch (control) {
			case 'open':
				setShowCustomShift(true)
				setCustomShiftDates([])
				break;
			case 'close':
				setShowCustomShift(false)
				setCustomShiftDates([])
				break;
			case 'set':
				let shiftCustomDatesDup = [...customShiftDates]
				if (!end) {
					shiftCustomDatesDup[0] = ind
				} else {
					shiftCustomDatesDup[1] = ind
				}

				setCustomShiftDates(shiftCustomDatesDup)
				break;
			case 'submit':
				const newDriverShifts = await axios.post(`/api/admin/users/drivers/custom_shift/${param._id}`, {
					driver: indDriver,
					newShift: customShiftDates
				});

				setShowCustomShift(false)
				setCustomShiftDates([])

				setIndDriverPopup(false);
				setIndDriver(null)
				setIndNav("Shifts")
				refreshDashboard()

				reOpenDriverPopup(newDriverShifts.data)
				break;
		}
	}

	function checkClockedOn(obj) {
		if (obj && obj.clocked_on) {
		    const clockedOnTime = new Date(obj.clocked_on);
		    const currentTime = new Date();

		    const differenceInMs = currentTime - clockedOnTime;
		    const differenceInHours = differenceInMs / (1000 * 60 * 60);

		    if (obj.clocked_off === undefined && differenceInHours >= 12) {
		        return true;
		    } else {
		        return false;
		    }
	    } else {
	    	return false
	    }
	}

	return (
		<>
			<Detector
				polling={{ timeout: 7000 }}
				render={({ online }) => (
					<>
						{!online && (
							<div className="offline_container">
								<div className="offline_inner animate__animated animate__slideInDown">
									<i className="fa-solid fa-heart-crack"></i>
									<div className="offline_content">
										<p className="offline_title">You are offline</p>
										<p className="offline_subtitle">Please check your internet connect.</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			/>

			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={collapseFilters ? styles.main_container : styles.main_container_full}>
								<NavigationBar 
									param={param}
									active={"Employees"}
									handleLogout={handleLogout}
									collapseDeg={collapseDeg}
									setCollapseDeg={setCollapseDeg}
									collapseFilters={collapseFilters}
									setCollapseFilters={setCollapseFilters}
								/>
								<UsersPage {...{ param, changeNavType, navType, popUpControls, indPopUpControls, allUsers, usersPopup, indUserPopup, searchControl, driversPopup, allDrivers, indDriverPopup, showSummary, formatDate, checkClockedOn }} />

								{usersPopup && <PopUpUsers {...{ param, popUpPage, popUpControls, validationError, formatOptions, handleNewUserData, newUserData, formatDate }} />}
								{indUserPopup && <PopUpIndUser {...{ param, indPopUpControls, indUser, changeIndNav, indNav, formatDate, changeStatus, changingStatus }} />}

								{driversPopup && <PopUpDrivers {...{ param, popUpPage, popUpControls, validationError, formatOptions, formatDate, handleNewDriverData, newDriverData }} />}
								{(indDriverPopup && !editShift && !showCustomShift) && <PopUpIndDriver {... { param, indPopUpControls, indDriver, changeIndNav, indNav, formatDate, searchControl, customShiftEdit, changeStatus, changingStatus, handleCustomShift }} />}

								{(showSummary && !indDriverPopup) && <PopUpSummary {...{ param, searchControl, indPopUpControls, popUpControls, handleDates, formatDate, currentDateSelection, weekOffset, internalLoading, boxActive, filteredShifts, calcHours, allDrivers }} />}
								{editShift && <PopUpEditShift {...{ param, customShiftEdit, editShiftDates }} />}
								{showCustomShift && <PopUpCustomShift {...{ param, handleCustomShift, customShiftDates }} />}
							</div>
							: <Forbidden />
						}
					</>
				:
					<Loading current={"Employees"} />
			}
		</>
	);
};


export default Users;