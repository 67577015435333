import styles from "./Warehouse.module.css"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Australia/Melbourne')


const PopUpDeliveryRecords = ({ handleDeliveryRecords, selectedRecordDate, indDelivery, validationError }) => {
	const runDayMap = {
	    "Sunday": 0,
	    "Monday": 1,
	    "Tuesday": 2,
	    "Wednesday": 3,
	    "Thursday": 4,
	    "Friday": 5,
	    "Saturday": 6
	};

	const disableOtherDays = (date, runDay) => {
	    const runDayNumber = runDayMap[runDay];
	    return dayjs(date).day() !== runDayNumber;
	};


	return (
		<div className={styles.popup_container}>

			<button className={styles.closePopup} onClick={() => handleDeliveryRecords('close')}><i class="fa-solid fa-x"></i></button>

			<h1 className={styles.medium_font}>Delivery records</h1>
			<p className={styles.smaller_font}>Please enter the specific run details to create.</p>

			<div className={styles.popup_form}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Delivery date</label>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							className={styles.overridebg}
							value={selectedRecordDate ? dayjs(selectedRecordDate) : null}
							onChange={(selected) => handleDeliveryRecords("set", {value: selected})}
							disableFuture
							shouldDisableDate={(date) => disableOtherDays(date, indDelivery.run_day)}
							format="DD/MM/YYYY"
						/>
					</LocalizationProvider>
				</div>
			</div>



			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handleDeliveryRecords('submit')}>Submit</button>	
	       		</div>
       		</div>
		</div>
	)
}

export default PopUpDeliveryRecords;