import styles from "./Dashboard.module.css"


const PopUpCredit = ({ param, handleCredits, validationError, newCredit }) => {
	return (
		<div className={`${styles.popup_container} ${styles.popup_maxwidth}`}>
			<button className={styles.closePopup} onClick={() => handleCredits('close')}><i class="fa-solid fa-x"></i></button>
			{newCredit.edit && <button className={styles.editPopup} onClick={() => handleCredits('delete')}><i className="fa-solid fa-trash-can"></i></button>}

			<h1 className={styles.medium_font}>Account Credit</h1>
			<p className={styles.smaller_font}>Create a new credit for this account using this module.</p>

			<div className={`${styles.popup_form}`}>
				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Amount</label>
					<input
						type="text"
						value={newCredit.amount && newCredit.amount}
						onChange={(input) => handleCredits('set', 'amount', input)}
					/>
				</div>

				<div className={styles.popup_formbox}>
					<label className={styles.smaller_font}>Note</label>
					<input
						type="text"
						value={newCredit.note && newCredit.note}
						onChange={(input) => handleCredits('set', 'note', input)}
					/>
				</div>
			</div>

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
					<button type="button" className={styles.submitBtn} onClick={() => handleCredits('submit')}>Submit</button>
				</div>
			</div>
		</div>
	)
}

export default PopUpCredit;